
import calendly from '../../images/integration/calendly.png'
import gc from '../../images/integration/gc.png'
import messenger from '../../images/integration/messenger.png'
import telegram from '../../images/integration/telegram.png'
import whatsapp from '../../images/integration/whatsapp.png'

export default function IntegrationSection({t}) {
  const integrations = [
    {
      name: 'Messagenger',
      logo: messenger,
      bgColor: 'bg-[#FFE5E0]'
    },
    {
      name: 'Google Calendar',
      logo: gc,
      bgColor: 'bg-[#FFE580]'
    },
    {
      name: 'WordPress',
      logo: whatsapp,
      bgColor: 'bg-[#E5F0FF]'
    },
    {
      name: 'Telegram',
      logo: telegram,
      bgColor: 'bg-[#FFE8E5]'
    },
    {
      name: 'Calendly',
      logo: calendly,
      bgColor: 'bg-[#E5E9FF]'
    },
  ]

  return (
    <section className="text-center bg-brand-color py-20">
      <div className="container mx-auto">
        <h2 className="text-4xl md:text-5xl font-bold mb-16 !leading-[50px] sm:leading-[80px] max-w-5xl text-center mx-auto text-white">
          {t?.integrationSection?.title}
        </h2>

        <div className="flex flex-wrap justify-center -gap-6 mb-8">
          {integrations.map((integration, index) => (
            <div
              key={index}
              className={`${integration.bgColor} w-28 h-28 -ml-4 border-[6px] border-brand-color rounded-full flex items-center justify-center`}
            >
              <img
                src={integration.logo}
                alt={`${integration.name} logo`}
                width={40}
                height={40}
                className="w-14 h-14"
              />
            </div>
          ))}
        </div>

        <p className="text-base text-white">
        {t?.integrationSection?.more}
        </p>
      </div>
    </section>
  )
}
