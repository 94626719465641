import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiUrl } from '../Variables';
import ErrorText from '../components/ErrorText';
import FooterMenu from '../components/landing-page/FooterMenu';
import { useAppContext } from '../context/AppContext';
import Logo from '../images/aceva.png';
import loginThumb from '../images/bg/login-thumb-2.png';
import FooterV2 from '../components/landing-page/FooterV2';

function RegisterScreen({toggleLoggedInState}) {
  const { userInfo } = useAppContext();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [logo, setLogo] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState('');
  const plandId = localStorage.getItem('selectedPlan')

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    }).then(async (res) => {
        console.log('gprof', res)
         // header supports
        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
        setLoading(true)
        await axios.post(`${apiUrl}/user/loginWithGoogle`, {...res.data, plandId}, config).then(async res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          const headers = {
            Authorization: `Bearer ${res.data?.token}`,
          };
          await axios.get(`${apiUrl}/chatbot`, {headers}).then(res => {
            if(res.data?.length > 0) {
              localStorage.setItem('isHaveBot', JSON.stringify(true))
            } else {
              localStorage.setItem('isHaveBot', JSON.stringify(false))
            }
          }).catch(err => {
            console.log('chatbot list fetching fail:', err)
          })
          setLoading(false)
          toggleLoggedInState()
        }).catch(error => {
          setLoading(false)
          console.log(error.response
            ? error.response
            : error.message); // this is the main part. Use the response property from the error object
            setErrorMessage(error.response
              ? error.response.data.message
              : error.message)
              if(error.response) {
              return Swal.fire({
                  icon: 'error',
                  title: 'Oops! Invalid username or password.',
                  text: error.response.data.message,
                })
              }
        })
    }).catch((err) => console.log(err));
    },
    onError: (error) => console.log('Login Failed:', error)
});

  const onSubmit = async (data) => {
    setSuccess(false);

    try {
      setSuccess(false);
      const res = await axios.post(`${apiUrl}/user/register`, {...data, plandId});
      console.log('res.data', res.data);
      // localStorage.setItem('userInfo', JSON.stringify(res.data));
      // localStorage.setItem('isAuthenticated', JSON.stringify(true))
      navigate('/verify-user')
      // toast.success('Congratulations, registration was successful!');
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setErrorMessage(error?.response?.data && "User already exist!")
    }
  }

  const handleInputFocus = () => {
    // Clear the error message when the input field is focused
    setErrorMessage('');
  };

  useEffect(() => {
    if (success) {
      navigate('/create-chatbot-file');
    }
    const getUsers = async () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (!userInfo || !userInfo.token) {
        console.error('User token not found.');
        return;
      }

      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };

      const { data } = await axios.get(`${apiUrl}/user`, { headers });
      console.log('users', data);
    }
    getUsers();
  }, [navigate, success, phoneError, phone, errorMessage]);

  console.log('errorMessage', errorMessage)

  return (
    <>
      <section className="!bg-gray-50 py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7 bg-white shadow-md rounded-lg overflow-hidden">
            {/* wrapper */}
            <div className="register-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-header-wrap">
                <div className="form-title">
                  <h2>Sign Up</h2>
                  <p>Please sign up to start the project.</p>
                </div>
                <div className="single-logo-wrapper">
                  <Link to="/">
                    <div className="single-logo">
                      <img src={Logo} alt="logo" />
                    </div>
                  </Link>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input onFocus={handleInputFocus} className={errors.email?.message && "error-input" || errorMessage && "error-input"} {...register('email', { required: "Email is required!" })} type="email" placeholder='Email address' id='email' />
                <ErrorText error={errors.email?.message} /> 
                {errorMessage && <ErrorText error="User already exist!" />}
              </div>

              <div className="form-group">
                <label htmlFor="Password">
                  <span>Password</span>
                  <span className="tooltip">
                    <AiOutlineExclamationCircle className='excl-icon' />
                    <span className="tooltip-text">Password must be at least 8 characters long and it must contain at least one uppercase letter, one lowercase letter, one number, and one special character.</span>
                  </span>
                </label>
                <div className="form-group-input-icon">
                  <input
                    onFocus={handleInputFocus}
                    className={errors.password?.message && "error-input"}
                    {...register('password', {
                      required: "Password is required!",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long and it must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message: "Password must be at least 8 characters long and it must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                      }
                    })}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    id="password"
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                  <ErrorText error={errors.password?.message} />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="confirmPassword">
                  Confirm Password 
                </label>
                <div className="form-group-input-icon">
                  <input
                    className={errors.confirmPassword?.message && "error-input"}
                    {...register('confirmPassword', {
                      required: "Confirm Password is required!",
                      validate: (value) => value === watch('password') || "Passwords do not match"
                    })}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    id="confirmPassword"
                  />
                  <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                  <ErrorText error={errors.confirmPassword?.message} />
                </div>
              </div>


              <button type='submit' className='form-button full-width mt-4 text-base'>
                Register
              </button>
              <div className="already-have-account">
                <p>Already have an account? <span className='link'><Link to="/login">Login Here</Link></span></p>
              </div>

            </form>
          </div>
          {/* thumb */}
          <div className="register-thumb">
            <img src={loginThumb} alt="register thumb" />
          </div>
          </div>
        </div>
      </section>
      <FooterV2 />
    </>
  )
}

export default RegisterScreen;
