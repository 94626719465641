'use client';

import { ArrowRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import Translations from '../../context/Translations';

export default function PricingCta() {
  const [isVisible, setIsVisible] = useState(true);
  const { language } = useLanguage();
  const t = Translations[language];
  const pricingCtaT = t.pricing.pricingCta;

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <section className="w-full py-12 md:py-24 lg:py-24 bg-gradient-to-br from-bg-brand-color to-bg-brand-color-bold text-white overflow-hidden">
      <div className="container mx-auto">
        <div className="bg-white/5 backdrop-blur-sm rounded-xl transition-all duration-1000 p-10">
          <div className="flex flex-col justify-center text-center items-center gap-6">
            <div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200 pb-3">
                {pricingCtaT.title}
              </h2>
              <p className="max-w-[600px] text-zinc-200 md:text-xl dark:text-zinc-100 mx-auto">
                {pricingCtaT.subTitle}
              </p>
            </div>
            <div className="w-full max-w-[350px] mx-auto">
              <Link to="/login">
                <button 
                  className="w-full text-lg font-semibold text-brand-color bg-white hover:bg-emerald-100 transition-colors duration-300 py-4 rounded-full shadow-lg hover:shadow-xl flex justify-center items-center gap-[5px] group"
                >
                  <span>{pricingCtaT.button}</span>
                  <ArrowRight className="ml-2 h-6 w-6 group-hover:translate-x-1 text-brand-color" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}