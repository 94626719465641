import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../Variables';

const TimeSlot = ({ startHour, endHour, onSelectSlot, selectedDate, token, gcCalendar, botConfigInfo }) => {
  const [selected, setSelected] = useState([]);
  const [filterEvents, setFilteredEvents] = useState([]);
  const [matchingSlots, setMatchingSlots] = useState([]);
  const [alreadyBookedEvents, setAlreadyBookedEvents] = useState([]);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  
  const [hoveredIndex, setHoveredIndex] = useState(null); // State for tracking hovered item
  const [hoverColor, setHoverColor] = useState(''); // State for hover background color
  const [color, setColor] = useState('#fff'); 
  
  const slotStyles = (index) => ({
    color: selected.includes(index) || index === hoveredIndex ? '#fff' : '' || botConfigInfo?.brandColor,
    background: index === hoveredIndex ? botConfigInfo?.brandColor : '' || selected.includes(index) ? botConfigInfo?.brandColor : '',
    border: `1px dotted ${botConfigInfo?.brandColor}`
  });
  
  // Function to handle mouse over event
  const handleMouseOver = (index) => {
    setHoveredIndex(index); // Set hovered item index
  };
  
  // Function to handle mouse out event
  const handleMouseOut = () => {
    setHoveredIndex(null); // Reset hovered item index
  };

  // console.log('hoverColor', hoverColor)
  // console.log('color', color)

  const convertGoogleEventsToBigCalendarFormat = (googleEvents) => {

    return googleEvents.map((event) => {
      try {
        // Parsing date-time strings to Date objects
        const startDate = new Date(event.start.dateTime);
        const endDate = new Date(event.end.dateTime);
  
        // Checking if the parsed dates are valid
        if (isNaN(startDate) || isNaN(endDate)) {
          throw new Error('Invalid date-time format');
        }
  
        // Formatting dates to ISO string format
        const startISOString = startDate.toISOString().split('T')[0];
        const endISOString = endDate.toISOString().split('T')[0];
  
        // Extracting time part and formatting it to match slots format
        const startTimeHour = startDate.getHours().toString().padStart(2, '0');
        const startTimeMinutes = startDate.getMinutes().toString().padStart(2, '0');
        const startTime = `${startTimeHour}:${startTimeMinutes}:00`;
  
        const endTimeHour = endDate.getHours().toString().padStart(2, '0');
        const endTimeMinutes = endDate.getMinutes().toString().padStart(2, '0');
        const endTime = `${endTimeHour}:${endTimeMinutes}:00`;
  
        return {
          startDate: startISOString,
          endDate: endISOString,
          startTime: startTime,
          endTime: endTime,
        };
      } catch (error) {
        console.error('Error converting Google event:', error);
        return null; // Returning null for invalid events
      }
    }).filter(event => event !== null); // Filtering out invalid events
  };
  
  

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      const googleAccessToken = localStorage.getItem('googleAccessToken')
      try {
        const response = await axios.post(`${apiUrl}/gCalender/events`, {googleRefreshToken: token, gcCalendar});
        const convertedEvents = convertGoogleEventsToBigCalendarFormat(
          response.data.events
        );
        console.log('convertedEvents', convertedEvents)
        // Filter events based on selectedDate
        const filtered = convertedEvents.filter(
          (event) => event.startDate === selectedDate || event.endDate === selectedDate
        );
        setFilteredEvents(filtered);
      } catch (error) {
        console.error('Error fetching Google events:', error);
      }
    };

    fetchGoogleEvents();
  }, [selectedDate]);

  const slots = [];

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const startTime = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
      const endTimeHour = hour + Math.floor((minutes + 30) / 60);
      const endTimeMinutes = (minutes + 30) % 60;
      const endTime = `${endTimeHour.toString().padStart(2, '0')}:${endTimeMinutes.toString().padStart(2, '0')}:00`;

      const label = `${hour > 12 ? hour - 12 : hour}:${minutes.toString().padStart(2, '0')}${hour >= 12 ? 'PM' : 'AM'} - ${endTimeHour > 12 ? endTimeHour - 12 : endTimeHour}:${endTimeMinutes.toString().padStart(2, '0')}${endTimeHour >= 12 ? 'PM' : 'AM'}`;

      const slot = {
        label,
        startTime, // Only time part
        endTime, // Only time part
      };

      slots.push(slot);
    }
  }

  useEffect(() => {
    const matching = [];
    if (selectedDate === new Date().toISOString().split('T')[0]) {
      slots.forEach((slot) => {
        // Current date
        const currentDate = new Date();
  
        // Extract hours, minutes, and seconds from the time string
        const [hours, minutes, seconds] = slot.startTime.split(':').map(Number);
  
        // Create a new Date object with the same year, month, and day as the current date, but with the specified time
        const specifiedTime = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          hours,
          minutes,
          seconds
        );
        filterEvents.forEach((event) => {
          if ((event.startTime === slot.startTime && event.endTime === slot.endTime) || (event.startTime <= slot.startTime && event.endTime >= slot.endTime)) {
            matching.push(slot);
          }
        });
        
        if (currentDate > specifiedTime) {
          matching.push(slot);
        }
      });
    } else {
      console.log('fdf')
      slots.forEach((slot) => {
        filterEvents.forEach((event) => {
          console.log(event, slot)
          if ((event.startTime === slot.startTime && event.endTime === slot.endTime) || (event.startTime <= slot.startTime && event.endTime >= slot.endTime)) {
            matching.push(slot);
          }
        });
      });
    }
    setMatchingSlots(matching);
  }, [filterEvents, selectedDate]);

  const handleSlotSelect = (slot, index) => {
    console.log(slot, index)
    if (selected.length === 1 && index === selected[0] + 1) {
      setSelected([...selected, index]);
      onSelectSlot({label: '1:30PM - 2:00PM', startTime: slots[index - 1].startTime, endTime: slot.endTime});
      return
    }
    if (selected.length >= 2) {
      setSelected([index])
      onSelectSlot(slot);
      return
    }
    if (selected.length === 0) {
      setSelected([index]);
      onSelectSlot(slot);
      return
    }
  };

  return (
    <>
      <div className="time-slot-container">
  <div className="time-slot-title">
    <h2>Select a Time Slot</h2>
    <p>Duration: 30 min (Time Zone: Asia/Kuala_Lumpur)</p>
  </div>
  <div className="time-slots">
    {slots.map((slot, index) => {
      let isDisabled = false
      isDisabled = matchingSlots.some(
        (matchingSlot) => { 
            return (matchingSlot.startTime === slot.startTime && matchingSlot.endTime === slot.endTime) || matchingSlot.startTime === slot.startTime || matchingSlot.endTime === slot.endTime
        }
      )
  
      return (
        <>
          <div

          style={slotStyles(index)}

          key={index}
          className={`time-slot ${selected?.includes(index) ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
          onClick={() => !isDisabled && handleSlotSelect(slot, index)}

          onMouseOver={() => handleMouseOver(index)}
          onMouseOut={handleMouseOut}
        >
          <span>{slot.label}</span>
        </div>
        </>
      );
    })}
  </div>
</div>

    </>
  );
};

export default TimeSlot;
