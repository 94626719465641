

import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import ConfigBot from '../../components/ConfigBot';

import UpdateMarketplaceForm from '../marketplace/update/UpdateMarketplaceForm';

function UpdateMarketplaceSteps({config, setConfig}) {
 
  const [uploadLoading, setUploadLoading] = useState(false)
  const [loading, setLoadLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLinkIds, setGetLinkIds] = useState([])
  const [botId, setBotId] = useState('')

  const navigate = useNavigate(); 

  const tempImg = "https://res.cloudinary.com/dsvt1ftd0/image/upload/v1702616802/1_gth7c6.png";
  
  // file upload handler
  const uploadAvatarHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "tpy8hir8")
    formData.append("cloud_name", "dsvt1ftd0")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
      setConfig({ ...config, botAvatar: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "tpy8hir8")
    formData.append("cloud_name", "dsvt1ftd0")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
      setConfig({ ...config, logo: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // config handler
  const botConfigHandler = async () => {
    try {
      const {data} = await axios.post(`${apiUrl}/botConfig/`)
    } catch (error) {
      console.log('Error:', error?.message || error?.response?.data)
    }
  }

  return (
    <>
      <div className="create-chatbot-container remove-after-line">
        
        <div className="create-chatbot-file-wrapper update-grid-gap">
          <div className="create-chatbot-file-body overflow-y-scroll remove-padding">
            <UpdateMarketplaceForm config={config} setConfig={setConfig}/>
            
          </div>
          {/* chatbot selected item preview */}
          <div className="create-chatbot-preview-wrapper">
            <ConfigBot config={config} setConfig={setConfig} />
          </div>
        </div>
      </div> 
    </>
  )
}

export default UpdateMarketplaceSteps