import React, { useEffect, useRef, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from 'react-hot-toast'
import { BiX } from 'react-icons/bi'
import { FaTelegramPlane } from 'react-icons/fa'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { apiUrl } from '../Variables'
import MiniLoading from '../components/MiniLoading'
import IframeBotDropdown from '../components/antd/IframeBotDropdown'
import BotCalendarWidget from '../components/marketplace/BotCalendarWidget'
import chatI from '../images/icons/telegram.png'
import { generateUniqueId } from '../utils/helper'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../style/Dashboard.css'
const MySwal = withReactContent(Swal)


function BotIframe({ QueryParams, setQueryParams }) {
  const { userId, botId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const botOpen = searchParams.get('open')
  const chatRef = useRef(null)
  const textareaRef = useRef(null);
  const [userInfo, setUserInfo] = useState(null);
  const headers = {
    Authorization: `Bearer ${userInfo?.token}`,
  };
  const [botInfo, setBotInfo] = useState(null);
  const [botConfigInfo, setBotConfigInfo] = useState(null);
  const [toggle, setToggle] = useState();
  const [createSubmitSuccess, setCreateSubmitSuccess] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [ip, setIp] = useState(Cookies.get('senderId'))
  const [error, setError] = useState('')
  const [botRunningStatusObj, setBotRunningStatusObj] = useState({ active: false, runningOn: null });
  const [loading, setLoading] = useState(false)
  const [botConfigLoadin, setBotConfigLoadin] = useState(false)
  const [chat, setChat] = useState([])
  const [message, setMessage] = useState('')
  const [message1, setMessage1] = useState('')
  const [chatShow, setChatShow] = useState(botOpen === 'open' ? true : false)
  const [promptLists, setPromptLists] = useState([]);
  const [billingData, setBillingData] = useState({})
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedLanguage, setSLanguage] = useState(null)
  const [showLanguage, setShowLanguage] = useState(true)
  const [leadgen, setLeadgen] = useState()
  const toggleShowLanguage = () => {
    setShowLanguage(!showLanguage)
  }
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => {
    setShowCalendar(false)
    setCurrentDate(new Date())
    setRefresh(!refresh)
  }

  const handleToggle = (e) => {
    setToggle(!toggle);
    setQueryParams('')
  }

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [chat])

  useEffect(() => {
    const featchData = async () => {
      await axios.post(`${apiUrl}/user/token_by_id`, { userId }).then(async res => {
        console.log(res)
        setUserInfo(res.data)
        await axios.get(`${apiUrl}/botConfig/${botId}`, {
          headers: { Authorization: `Bearer ${res.data?.token}` }
        }).then(res2 => {
          console.log(res2)
          setBotConfigInfo(res2.data[0]?.configData)
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
      })
      console.log("Cookies.get('senderId')", Cookies.get('senderId'))
      if (!Cookies.get('senderId')) {
        // Generate a unique identifier
        const uniqueId = generateUniqueId();
        // Set the unique identifier as a cookie
        Cookies.set('senderId', uniqueId, { expires: 365, SameSite: "None", Secure: true, Priority: 'High' }); // Set an expiration date
        setIp(uniqueId)
      }
    }
    featchData()

  }, [])


  useEffect(() => {
    const featchData = async () => {
      setBotConfigLoadin(true)
      await axios.get(`${apiUrl}/chatbot/${botId}`, { headers }).then(res => {
        console.log(res)
        setBotInfo(res.data[0])
      }).catch(err => {
        console.log(err)
      })
      await axios.get(`${apiUrl}/botMenu/${botId}`, { headers }).then(res => {
        console.log(res)
        setPromptLists(res?.data[0]?.menues)
      }).catch(err => {
        console.log(err)
      })
      await axios.get(`${apiUrl}/billing/addonBillingList`, { headers }).then(res => {
        console.log('====================================\naddonBillingList of this chatbot', res)
        setBotConfigLoadin(false)
        setBillingData(res.data)
        //If response has a subscription billing array, that is valid for this month and the remaining chat volume is greater than 0

        /////later have to delete start///////////
        // if (res.data?.subscriptionBilling[0] && (res.data?.subscriptionBilling[0]?.validityHas && parseInt(res.data?.subscriptionBilling[0]?.restChatVolume) > 0)) 
        //   {
        //   console.log("Current Subscription: \n",res.data?.subscriptionBilling[0])
        //   setBotRunningStatusObj({ active: false, runningOn: 'plan' })
        // } 
        // //
        // else if (parseInt(res.data?.addOnBilling.find(item => item.addOn.name === "MessagesAddon")?.addOn.extraMsg) > 0) {
        //   setBotRunningStatusObj({ active: false, runningOn: 'addon', addonId: res.data?.addOnBilling.find(item => item.addOn.name === "MessagesAddon")?._id })
        // } else {
        //   setBotRunningStatusObj({ active: true, runningOn: 'plan' })
        //   console.log('fdfd', { active: true, runningOn: 'plan' })
        // }
        /////later have to delete end///////////

        // Filter add-on billings to include only MessagesAddon
        const messagesAddons = res.data?.addOnBilling
          ?.filter(item => item.addOn?.addOnName === "MessagesAddon" && parseInt(item.addOn.extraMsg) > 0)
          .map(addOn => ({
            ...addOn,
            validityTill: new Date(addOn.validityTill) // Convert validityTill to Date for sorting
          }));
      
        // Combine subscription and filtered add-ons
        const combinedBilling = [
          ...((
            res.data?.subscriptionBilling[0] &&
            res.data?.subscriptionBilling[0]?.validityHas && 
            parseInt(res.data?.subscriptionBilling[0]?.restChatVolume) > 0
          )
            ? [res.data?.subscriptionBilling[0]]
            : []),
          ...(messagesAddons || [])
        ];
      
        // Sort by earliest validityTill
        combinedBilling.sort((a, b) => new Date(a.validityTill) - new Date(b.validityTill));
      
        // Pick the earliest valid billing
        const earliestBilling = combinedBilling[0];
      
        if (earliestBilling) {
          if (earliestBilling.subscription_id) {
            setBotRunningStatusObj({ active: false, runningOn: 'plan', addonId: earliestBilling._id });
          } else if (earliestBilling.addOn) {
            setBotRunningStatusObj({ active: false, runningOn: 'addon', addonId: earliestBilling._id });
          }
        } else {
          // No valid subscription or add-ons
          setBotRunningStatusObj({ active: true, runningOn: 'plan' });
          console.log('No valid subscription or add-ons. Setting bot to active mode:', { active: true, runningOn: 'plan' });
        }


      }).catch(err => {
        setBotConfigLoadin(false)
        console.log('addonBillingList', err)
      })

      await axios.get(`${apiUrl}/leadGenConfig/leadgen/${ip}/${botId}`).then(res => {
        console.log('setLeadgen', res)
        setLeadgen(res.data)
      }).catch((error) => {
        setBotConfigLoadin(false)
        console.log("error", error.response)
      })
    }
    if (userInfo) {
      featchData()
    }
  }, [userInfo, ip])

  useEffect(() => {
    const featchData = async () => {
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo?.token}`,
      };
      await axios.get(`${apiUrl}/chats/${botId}/${ip}?date=${currentDate.toISOString()}`, { headers }).then(res => {
        console.log('chats', res)
        setChat(res.data.chats)
      }).catch((error) => {
        console.log("error", error.response)
      })
    }
    if (userInfo?._id) {
      featchData()
    }
  }, [userInfo, ip, refresh])

  const changeLanguageOfStaticData = async (target_ln) => {
    toggleRefresh()
    const iData = { text: [botConfigInfo.placeholder, botConfigInfo.welcomeMessage], target_ln }
    for (const item of promptLists) {
      iData.text.push(item.title)
      iData.text.push(item.content)
    }
    await axios.post(`${apiUrl}/chats/translateMsg`, iData).then(res => {
      console.log('translateMsg', res)
      const { data } = res
      setBotConfigInfo({ ...botConfigInfo, placeholder: data.translated_txt[0], welcomeMessage: data.translated_txt[1] })
      const changedPrompts = []
      for (let i = 2, j = 0; i < data.translated_txt.length; i += 2, j++) {
        changedPrompts.push({ ...promptLists[j], title: data.translated_txt[i], content: data.translated_txt[i + 1] });
      }
      console.log(changedPrompts)
      setPromptLists([...changedPrompts])
      toggleShowLanguage()
    }).catch((error) => {
      console.log("error", error.response || error)
    })
  }

  const negativeKeywords = ['no', 'not', 'don’t', 'dont', 'never', 'unhappy', 'bad', 'problem', 'can’t', 'won’t', 'cant'];
  // Helper function to check for absence of negative sentiment
  const hasNoNegativeWords = (text) => !negativeKeywords.some(keyword => text.toLowerCase().includes(keyword));

  // submit links handler
  const submitLinkHandler = async (e, msg) => {
    e?.preventDefault();
    setShowCalendar(false)
    const appointmentPattern = /a?p+p[o0]?i?n*t*me?n*t?/i;
    if ((message.toLowerCase().includes('calendar') || appointmentPattern.test(message.toLowerCase())) && hasNoNegativeWords(message) && userInfo.utilityData.grt && botConfigInfo?.gcCalendar) {
      setChat([...chat || [], { time: Date.now(), user: message || msg, bot: { text: "Of course! Feel free to select your preferred date and time from the calendar below, and we’ll get your appointment scheduled." } }])
      setShowCalendar(!showCalendar)
      setMessage('')
      return
    }
    setLoading(true)
    setChat([...chat || [], { time: Date.now(), user: message || msg, bot: { text: '' } }])
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    const data = { userId, chatbotId: botId, senderId: ip || 't001', message: message || msg, language: selectedLanguage }
    setMessage('')
    setMessage1('')
    const startTime = new Date();
    let endTime
    // await axios.post(`${apiUrl}/chats?date=${currentDate.toISOString()}`, data, { headers }).then(async res => {
    await axios.post(`${apiUrl}/chats?date=${currentDate.toISOString()}`, data, { headers }).then(async res => {
      console.log(res)
      setChat([...chat || [], res.data.chats[res.data.chats?.length - 1]])

      setLoading(false)
      endTime = new Date();
      const responseTime = endTime - startTime;
      await axios.post(`${apiUrl}/botResponseTime?runningOn=${botRunningStatusObj?.runningOn}&addonId=${botRunningStatusObj?.addonId}`, { chatbotId: botId, responseTime }, { headers }).then(res1 => {
        console.log(res)
      }).catch(error => {
        console.log(error)
        toast.error(`Failed: ${error?.message || error?.response?.data}`);
      })
      await axios.get(`${apiUrl}/leadGenConfig/leadgen/${ip}/${botId}`).then(res3 => {
        setLeadgen(res3.data)
      }).catch((error) => {
        setBotConfigLoadin(false)
        console.log("error", error.response)
      })
    }).catch(error => {
      console.log(error)
      toast.error(`Failed: ${error?.message || error?.response?.data}`);
      setLoading(false)
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitLinkHandler(e);
    }
  };

  // send message adjust the height
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      if (!message) {
        // If the message is empty, reset height to default
        textarea.style.height = '45px';
      } else {
        // Otherwise, adjust height based on content
        textarea.style.height = '45px'; // Reset to default height
        textarea.style.height = `${Math.min(textarea.scrollHeight, 140)}px`; // Adjust based on content
      }
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height whenever the message changes
  }, [message]);

  return (
    <>
      <div className="iframe-container" style={{ position: 'fixed' }}>
        {
          chatShow ? <div className="chatbot-iframe-widget active-iframe" style={{ position: 'fixed' }}>

            {/* chat header */}
            <div className="chatbot-iframe-widget-header" style={{ background: botConfigInfo?.brandColor }}>

              {/* agent presence */}
              <div className="chatbot-iframe-precence">

                {/* agent left side */}
                <div className="chatbot-iframe-left">
                  {/* agent avatar */}
                  <div className="chatbot-recipient-avatar">
                    {
                      botConfigLoadin ?
                        <img className="loading-img" src="https://flickerscape.com/wp-content/plugins/gutenverse/assets/img/img-placeholder.jpg" alt="logo" />
                        :
                        <img className="bot-logo-img" src={botConfigInfo?.logo} alt="logo" />
                    }

                    {/* {
                        <img src={!botConfigInfo ? "https://flickerscape.com/wp-content/plugins/gutenverse/assets/img/img-placeholder.jpg" : botConfigInfo?.logo} alt="logo" />
                    } */}
                    {/* https://play-lh.googleusercontent.com/ZG6-bDrdxvKV9lwg_aQ0H90VBHdYApCBaOn4VL33fSnKYKigGsgK5xe7E_rCYra1fDag */}
                    <div className="online-status"></div>
                  </div>

                  {/* agent details */}
                  <div className="chatbot-widget-recipient-details">
                    {
                      // botConfigLoadin ? <p>loading...</p> : 
                      billingData?.addOnBilling?.map(it => it.addOn.name).includes("RemoveACEVABrandingAddon") ? <p>Chat with</p> : <p>Powered by
                        <Link to="https://www.aceva.com.my/" target="_blank" className='company-name'> ACEVA GPT </Link>
                      </p>
                    }
                    <h3>
                      {botInfo?.name}
                    </h3>
                  </div>
                </div>

                {/* agent right side options */}
                <div className="chatbot-iframe-right">

                  <div className='chatbot-widget-header-right-options'>

                    {/* dropdown menu */}
                    <div className="chatbot-widget-minimize refresh-icon three-dot-icon">
                      <IframeBotDropdown toggleShowLanguage={toggleShowLanguage} toggleRefresh={toggleRefresh} />
                    </div>

                    {/* cross icon for mobile */}
                    {
                      botOpen !== 'open' && <div className="chatbot-widget-minimize refresh-icon" onClick={() => setChatShow(false)}>
                        <BiX />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* chat conversation group body style={{position:'fixed'}} */}
            <div className="bot-message-wrapper-iframe">

              {
                // lanugage musk
                showLanguage && <div className="language-mask">
                  <div className="language-mask-wrap">
                    <p>Select a language :</p>
                    {
                      botConfigInfo?.language ? <>
                        {botConfigInfo?.language[0]?.value && <li onClick={() => {
                          setSLanguage(botConfigInfo?.language[0])
                          changeLanguageOfStaticData(botConfigInfo?.language[0].value)
                        }}>{botConfigInfo?.language[0].label}</li>}
                        {botConfigInfo?.language[1]?.value && <li onClick={() => {
                          setSLanguage(botConfigInfo?.language[1])
                          changeLanguageOfStaticData(botConfigInfo?.language[1].value)
                        }}>{botConfigInfo?.language[1].label}</li>}
                        {botConfigInfo?.language[2]?.value && <li onClick={() => {
                          setSLanguage(botConfigInfo?.language[2])
                          changeLanguageOfStaticData(botConfigInfo?.language[2].value)
                        }}>{botConfigInfo?.language[2].label}</li>}
                      </> : <MiniLoading />
                    }
                  </div>
                </div>
              }

              {/* message body */}
              <div className="chatbot-iframe-body">
                <ul className='overscroll-off'>
                  {
                    !botRunningStatusObj?.active && <li className='bubble-iframe-agent'>
                      <img className='agent-logo' src={botConfigInfo?.logo} alt="bot" />
                      <div className="welcome-message-wrap">
                        <p>{botConfigInfo?.welcomeMessage}</p>
                      </div>
                    </li>
                  }
                  {
                    chat?.map((item, index) =>
                      <>
                        {/* bot */}
                        <li className="message-bubble-visitor date-bubble-item chatbot-config-bubble-user" style={{ background: botConfigInfo?.brandColor }}>
                          <p className='bubble-iframe-p bubble-iframe-p-user'>{item.user}</p>
                          {/* <div className="messageTimestamp">{moment(item?.time).fromNow('lll')}</div> */}
                          <div className="messageTimestamp"></div>
                        </li>

                        {/* response */}
                        {item?.bot?.text && <li className='logo-and-text-wrap'>
                          <img className='agent-logo' src={botConfigInfo?.logo} alt="bot" />
                          <div className="bubble-iframe-agent-wrap">
                            {/* <p className='bubble-iframe-p'>{item.bot.text}</p> */}
                            <p className='bubble-iframe-p' dangerouslySetInnerHTML={{ __html: item?.bot?.text?.replace(/\n/g, '<br />') }}></p>
                            {/* <div className="messageTimestamp message-time-left">{moment(item?.time).fromNow('lll')}</div> */}
                          </div>
                        </li>}
                        {
                          (item?.bot?.payload === 'text-field' && chat?.length - 1 === index) && <div style={{ display: 'flex' }}>
                            <input
                              className="aceva-gpt-input"
                              value={message1}
                              onChange={e => setMessage1(e.target.value)}
                              type="text"
                              placeholder='your input here...'
                              style={{ resize: 'none', width: '300px', marginLeft: '20px' }}
                            />
                            <button
                              type='button'
                              onClick={() => submitLinkHandler(null, message1)}
                              className='chatbot-iframe-send-message-btn'
                              style={{ background: botConfigInfo?.brandColor, marginLeft: '10px' }}
                            >
                              <FaTelegramPlane className='chatbot-iframe-ft-btn' />
                            </button>
                          </div>
                        }
                        {
                          (item?.bot?.payload === 'phone-field' && chat?.length - 1 === index) && <div style={{ display: 'flex' }}>
                            <PhoneInput
                              // className="aceva-gpt-input" 
                              country={'my'}
                              value={message1}
                              onChange={phone => setMessage1(phone)}
                              style={{
                                resize: 'none',
                                width: '300px',
                                marginLeft: '20px',
                                height: '45px', // Increase height
                                fontSize: '16px' // Optional: make text larger
                              }}
                            />
                            <button
                              type='button'
                              onClick={() => submitLinkHandler(null, message1)}
                              className='chatbot-iframe-send-message-btn'
                              style={{ background: botConfigInfo?.brandColor, marginLeft: '10px' }}
                            >
                              <FaTelegramPlane className='chatbot-iframe-ft-btn' />
                            </button>
                          </div>
                        }
                      </>)
                  }
                  {
                    loading &&
                    <li className='typing-container'>
                      <img className='agent-logo' src={botConfigInfo?.logo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <span className="tying">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>
                  }
                  {
                    botRunningStatusObj?.active && <li>
                      <img className='agent-logo' src={botConfigInfo?.logo} alt="bot" />
                      <div>
                        <div className="bubble-iframe-agent-wrap">
                          <p className='bubble-iframe-p'>I am sorry we are offline at the moment. We will get back to you soon.</p>
                        </div>
                        <div className="bubble-iframe-agent-wrap" style={{ marginTop: '10px' }}>
                          <p className='bubble-iframe-p'>If you have any question, please email us at <strong>{userInfo.email}</strong></p>
                        </div>
                      </div>
                    </li>
                  }
                  {/* Calendly Widget */}
                  {showCalendar && (
                    <BotCalendarWidget botConfigInfo={botConfigInfo} setShowCalendar={setShowCalendar} setChat={setChat} chat={chat} token={userInfo?.utilityData?.grt} gcCalendar={botConfigInfo?.gcCalendar} leadgen={leadgen} />
                  )}
                  {chat?.length > 0 && <li className='ref-li' ref={chatRef}></li>}
                </ul>


              </div>
            </div>

            {/* chat footer */}
            <div className="chatbot-iframe-footer-container">
              {/* footer menu options */}
              {
                !showLanguage && (
                  <div className='chatbot-iframe-suggest-buttons'>
                    {(userInfo?.utilityData?.grt && botConfigInfo?.gcCalendar) ? [<li className='botdemo-menu-option-item' onClick={() => {
                      setShowCalendar(!showCalendar)
                    }} style={{ background: botConfigInfo?.brandColor }}>
                      {botConfigInfo?.calendarPrompt}
                    </li>, ...promptLists?.map(i => <li className='botdemo-menu-option-item' onClick={() => {
                      if (!botRunningStatusObj?.active) {
                        if (/^(ftp|http|https):\/\/[^ "]+$/.test(i.content)) {
                          window.open(i.content, '_blank');
                        } else {
                          submitLinkHandler(null, i.content)
                        }
                      }
                    }} style={{ background: botConfigInfo?.brandColor }}>
                      {i.title}
                    </li>)] : promptLists?.map(i => <li className='botdemo-menu-option-item' onClick={() => {
                      if (!botRunningStatusObj?.active) {
                        if (/^(ftp|http|https):\/\/[^ "]+$/.test(i.content)) {
                          window.open(i.content, '_blank');
                        } else {
                          submitLinkHandler(null, i.content)
                        }
                      }
                    }} style={{ background: botConfigInfo?.brandColor }}>
                      {i.title}
                    </li>)
                    }
                  </div>
                )
              }
              {/* footer input */}
              <div className="chatbot-footer bot-iframe-footer">
                <div className="chatbot-iframe-footer-wrap">
                  <form onSubmit={submitLinkHandler}>
                    <textarea
                      className="aceva-gpt-input"
                      ref={textareaRef}
                      disabled={botRunningStatusObj?.active || showLanguage}
                      value={message}
                      required
                      onChange={e => setMessage(e.target.value)}
                      type="text"
                      onKeyDown={handleKeyDown}
                      placeholder={botConfigInfo?.placeholder}
                      style={{ resize: 'none', height: '45px' }}
                    />
                    <button
                      disabled={botRunningStatusObj?.active || showLanguage}
                      type='submit'
                      className='chatbot-iframe-send-message-btn'
                      style={{ background: botConfigInfo?.brandColor }}
                    >
                      <FaTelegramPlane className='chatbot-iframe-ft-btn' />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div> : (botConfigInfo && !chatShow) ? <div style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'right', alignItems: 'end', height: '100vh' }}><img className='toggle-bot-icon' onClick={() => setChatShow(true)} src={botConfigInfo?.botAvatar || chatI} alt="agent avatar" /></div> : null

        }
      </div>
    </>
  )
}

export default BotIframe