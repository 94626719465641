import { Collapse } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoChevronDownSharp, IoChevronUp } from "react-icons/io5";
import { apiUrl } from '../../../Variables';

const { Panel } = Collapse;

const UpdateMarketplaceForm = ({config, setConfig}) => {
  const [whatsappEnabled, setWhatsappEnabled] = useState(true);
  const [messengerEnabled, setMessengerEnabled] = useState(false);
  const [googleCalendarEnabled, setGoogleCalendarEnabled] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const gcResponse = await axios.post(`${apiUrl}/gCalender/getCalendars`, {googleRefreshToken: userInfo?.utilityData?.grt});
        // console.log(gcResponse)
        setCalendars(gcResponse.data.calendars)
      } catch (error) {
        console.error('Error fetching Google events:', error);
      }
    };
    fetchGroup();
  }, []);

  const customExpandIcon = ({ isActive }) => (
    isActive ? <IoChevronUp className='custom-accordion-icon' /> : <IoChevronDownSharp className='custom-accordion-icon' />
  );

  console.log('config', config);

  return (
    <Collapse accordion expandIcon={customExpandIcon} defaultActiveKey={['1', '2', '3']}>
      {
        config?.phoneNumber && <Panel className='custom-accordion-title' header="WhatsApp" key="1">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>Whatsapp App Name</p>
          <p style={{fontWeight: '600'}}>{config?.waAppName || '--'}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>Phone Number</p>
          <p style={{fontWeight: '600'}}>{config?.phoneNumber || '--'}</p>
          {/* <Switch checked={whatsappEnabled} onChange={() => setWhatsappEnabled(!whatsappEnabled)} /> */}
        </div>
      </Panel>
      }
      {
        config?.fbPageToken && <Panel className='custom-accordion-title' header="Messanger" key="1">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>Connected With Facebook Page.</p>
          {/* <p style={{fontWeight: '600'}}>{config?.phoneNumber || '--'}</p> */}
        </div>
      </Panel>
      }
      {
        config?.gcCalendar && <Panel className='custom-accordion-title' header="Google Calendar" key="2">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p>Name</p>
            <p style={{fontWeight: '600'}}>{calendars.find(item => item.id === config?.gcCalendar)?.summary || '--'}</p>
          </div>
          <div style={{ marginTop:'15px' }}>
          <div className="form-group">
              <label htmlFor="">Prompt title  (i.e. Appointment)</label>
              <input 
                onChange={(e) => {
                  config.calendarPrompt = e.target.value
                  setConfig({...config})
                }} 
                value={config?.calendarPrompt}
                type="text" 
                placeholder='Prompt title' 
              />
            </div>
          </div>
        </Panel>
      }

      {/* <Panel className='custom-accordion-title' header="Messenger" key="2">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <p>Page Account</p>
            <p style={{fontWeight: '600'}}>Wipdata Chatbot</p>
          </div>
          <div>
            <p>Messenger Page ID</p>
            <p style={{fontWeight: '600'}}>245fegw43</p>
          </div>
          <Switch checked={messengerEnabled} onChange={() => setMessengerEnabled(!messengerEnabled)} />
        </div>
      </Panel>
      <Panel className='custom-accordion-title' header="Google Calendar" key="3">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p>Event</p>
          <p style={{fontWeight: '600'}}>Booking Appointment 1</p>
          <Switch checked={googleCalendarEnabled} onChange={() => setGoogleCalendarEnabled(!googleCalendarEnabled)} />
        </div>
      </Panel> */}
    </Collapse>
  );
};

export default UpdateMarketplaceForm;
