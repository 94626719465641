import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiUrl } from '../../Variables';
import Layout from '../../components/Layout';
import MarketplaceBreadcrumb from '../../components/marketplace/MarketplaceBreadcrumb';
import MessengerConnect from '../../components/marketplace/MessengerConnect';
import marketplace from '../../images/icons/messenger.png';
import { BiTrash } from 'react-icons/bi';
import toast from 'react-hot-toast';

function MessengerDetailScreen() {
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [chatbots, setChatbots] = useState(null);
  const [chatbotFetchLoading, setChatbotFetchLoading] = useState(false)

  useEffect(() => {
    const fetchChatbots = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setChatbotFetchLoading(true);

        const res = await axios.get(`${apiUrl}/chatbot`, { headers });
        setChatbots(res.data);

        console.log(res);
        
        setChatbotFetchLoading(false);
      } catch (error) {
        setChatbotFetchLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchChatbots();
  }, [refresh]);

  const handleDeleteMessanger = async (selectedBot) => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setLoading(true)
    await axios.get(`${apiUrl}/botConfig/${selectedBot}`, {headers}).then(async res => {
      const btc = res.data[0]
      await axios.put(`${apiUrl}/botConfig/${btc?._id}`, {_id: btc?._id, configData: {...btc.configData, fbPageId: null, fbPageToken: null, fbVarifyToken: null, fbCallbackUrl: null}, chatbotId: btc.chatbotId}, { headers }).then(async res => {
          setLoading(false)
          setRefresh(!refresh)
          toast.success('Successfully deleted.')
      }).catch((error) => {
        setLoading(false)
        console.log(error)
      })
    }).catch((error) => {
      setLoading(false)
      console.log('scrap list fetching fail:', error)
    })
 }

  return (
    <>
      <Helmet>
        <title>Messenger Integration | ACEVA Dashboard</title>
      </Helmet>

      <Layout>
        <div className="marketplace-container">
        <MarketplaceBreadcrumb title="Messenger Integration" />
          <div className="marketplace-title marketplace-details-title">
            <h2>Messenger Integration</h2>
          </div>

          {/* marketplace items */}
          <div className="marketplace-item marketplace-details-item">
            <div className="marketplace-item-thumb">
              <img src={marketplace} alt="marketplace icon" />
            </div>
            <p>
              This add-on enables you to integrate our chatbot with Messenger! Please check out our step-by-step guide on how to integrate with Messenger.
            </p>
            <MessengerConnect />
          </div>
             <div className="addon-billing-table-wrap marketplace-details-item connected-gc-chatbots">
                <h3>Connected Bots</h3>
                <table>
                  {/* <Loading /> */}
                  <thead>
                    <tr>
                      <th>Messenger</th>
                      <th>Facebook Page ID</th>
                      <th>Token</th>
                      <th></th>
                    </tr>
                  </thead>
                    <tbody>
                      {
                        chatbots?.map((item, index) =>  (
                          // Check if both gcCalendar and botName exist in configData
                          item?.configData?.fbPageToken && item?.configData?.botName && (
                            <tr key={index}>
                              <td>{item.configData.botName}</td>
                              <td>{item?.configData?.fbPageId}</td>
                              <td>{item?.configData?.fbPageToken}</td>
                              <td><BiTrash style={{cursor:'pointer'}} onClick={() => handleDeleteMessanger(item._id)} color='red'/></td>
                            </tr>
                          )
                        ))
                      }
                    </tbody>
  
                </table>
              </div>
        </div>
      </Layout>
    </>
  );
}

export default MessengerDetailScreen;
