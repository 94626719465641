import { ChevronUp, Pencil, Trash2, X } from 'lucide-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/error-img.png';

function CreatePromptScreen({toggleLoggedInState}) {
  const [prompt, setPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [editPrompt, setEditPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [link, setlink] = useState({
    linkLabel: '',
    linkLink: '',
  });
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [promptObj, setPromptObj] = useState({});
  const [refresh, setRefresh] = useState(false);
  
  const [filterShow, setFilterShow] = useState(false)
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [promptToggle, setPromptToggle] = useState(false);
  const navigate = useNavigate();

  const [promptLists, setPromptLists] = useState(
    (JSON.parse(localStorage.getItem('menuList')) || []).map((item) => ({
      ...item,
      toggle: false,
    }))
  );

  console.log('promptLists', promptLists)
  
  const handlePromptToggle = (index) => {
    const updatedPrompts = [...promptLists];
    updatedPrompts[index].toggle = !updatedPrompts[index].toggle;
    setPromptLists(updatedPrompts);
  };
  
  const handleLinkInputChange = (e) => {
    const { name, value } = e.target;
    setlink({
      ...link,
      [name]: value,
    });
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
    if (promptLists.length === 5) {
      toast.error('The max number of menu must be 5!');
      return 0
    }
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(link.linkLink)) {
      toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
      return 0
    }
    setLoading(true)
    if (link.linkLabel && link.linkLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: link.linkLabel,
          content: link.linkLink
        }
      ])
      localStorage.setItem('menuList', JSON.stringify([
        ...promptLists,
        { 
          title: link.linkLabel,
          content: link.linkLink
        }
      ]))
      setLoading(false)
      setlink({ linkLabel: '', linkLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrompt({
      ...prompt,
      [name]: value,
    });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditPrompt({
      ...editPrompt,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (promptLists.length === 5) {
      toast.error('The max number of menu must be 5!');
      return 0
    }
    setLoading(true)
    if (prompt.promptLabel && prompt.promptLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: prompt.promptLabel,
          content: prompt.promptLink
        }
      ])
      localStorage.setItem('menuList', JSON.stringify([
        ...promptLists,
        { 
          title: prompt.promptLabel,
          content: prompt.promptLink
        }
      ]))
      setLoading(false)
      setPrompt({ promptLabel: '', promptLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };

  const handleEditClick = () => {
    if (editPrompt.promptLink.startsWith('http')) {
      if (!/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink)) {
        toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
        return 0
      }
    }
    if (editPrompt.promptLabel && editPrompt.promptLink) {
      promptLists[editIndex] = { 
        title: editPrompt.promptLabel,
        content: editPrompt.promptLink
      }
      setPromptLists([...promptLists])
      localStorage.setItem('menuList', JSON.stringify([...promptLists]))
      setEditPrompt({ promptLabel: '', promptLink: '' });
      setFilterShow(!filterShow)
    }
  };

  console.log('filterShow', filterShow)

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'));
    if (sourceIndex !== targetIndex) {
      const newItems = [...promptLists];
      const [movedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(targetIndex, 0, movedItem);
      setPromptLists(newItems);
    }
  };

  return (
    <div className=" bg-gray-50 p-0 sm:p-2">
      {/* Edit Modal */}
      {filterShow && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-left">
                {/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Update Link' : 'Update Prompt'}
              </h2>
              <X 
                className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => {
                  setFilterShow(false);
                  setEditPrompt({ promptLabel: '', promptLink: '' });
                }}
              />
            </div>

            <div className="space-y-4">
              <div className="text-left">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Link Button Title' : 'Prompt Title'}
                </label>
                <input
                  name="promptLabel"
                  type="text"
                  value={editPrompt.promptLabel}
                  onChange={handleEditInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                  placeholder="Enter title"
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'External Link' : 'Query'}
                </label>
                <input
                  name="promptLink"
                  type="text"
                  value={editPrompt.promptLink}
                  onChange={handleEditInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                  placeholder={/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Enter URL' : 'Enter query'}
                />
              </div>

              <div className="flex gap-3 mt-6">
                <button
                  onClick={handleEditClick}
                  className="flex-1 bg-brand-color text-white px-4 py-2 rounded-md hover:bg-brand-color-bold transition-colors"
                >
                  Update
                </button>
                <button
                  onClick={() => {
                    setFilterShow(false);
                    setEditPrompt({ promptLabel: '', promptLink: '' });
                  }}
                  className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-8">
        {/* Create Prompt Section */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <h2 className="text-lg font-semibold mb-3 text-left">Create Prompt</h2>
          
          <form onSubmit={handleSubmit} className="space-y-4 text-left">
            <div className="text-left">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Prompt Title
              </label>
              <input
                name="promptLabel"
                type="text"
                value={prompt.promptLabel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md outline-0 focus:outline-0 focus:ring-brand-color"
                placeholder="e.g., About Aceva GPT"
              />
            </div>

            <div className="text-left">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Query
              </label>
              <input
                name="promptLink"
                type="text"
                value={prompt.promptLink}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-brand-color"
                placeholder="e.g., Hi, what is Aceva GPT?"
              />
            </div>

            <button
              type="submit"
              className="w-fit text-left bg-brand-color text-white px-4 py-2 rounded-lg hover:bg-brand-color-bold transition-colors"
            >
              {loading ? 'Loading...' : 'Add Prompt'}
            </button>
          </form>

          {/* Create Link Button Section */}
          <div className="mt-4 pt-4 border-t">
            <h2 className="text-lg font-semibold mb-3 text-left">Create Link Button</h2>
            
            <form onSubmit={handleLinkSubmit} className="space-y-4 text-left">
              <div className="text-left">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Button Title
                </label>
                <input
                  name="linkLabel"
                  type="text"
                  value={link.linkLabel}
                  onChange={handleLinkInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-brand-color"
                  placeholder="e.g., Book Appointment"
                />
              </div>

              <div className="text-left">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  External Link
                </label>
                <input
                  name="linkLink"
                  type="text"
                  value={link.linkLink}
                  onChange={handleLinkInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-brand-color"
                  placeholder="https://calendly.com/wipdata/30min"
                />
              </div>

              <button
                type="submit"
                className="w-fit bg-brand-color text-white px-4 py-2 rounded-lg hover:bg-brand-color-bold transition-colors"
              >
                {loading ? 'Loading...' : 'Add Button'}
              </button>
            </form>
          </div>
        </div>

        {/* Prompts List Section */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-1 text-left">All Prompts</h2>
          <p className="text-gray-600 mb-6 text-left">Your created prompts.</p>

          <div className="space-y-4">
            {promptLists.length > 0 ? (
              promptLists.map((prompt, index) => (
                <div
                  key={index}
                  className="border border-gray-200 rounded-lg overflow-hidden"
                  draggable
                  onDragStart={e => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={e => handleDrop(e, index)}
                >
                  <div className="flex items-center justify-between p-4 bg-gray-50">
                    <div 
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() => handlePromptToggle(index)}
                    >
                      <ChevronUp className={`w-5 h-5 transition-transform ${prompt.toggle ? 'rotate-180' : ''}`} />
                      <span className="font-medium">{prompt.title}</span>
                    </div>
                    
                    <div className="flex items-center gap-3">
                      <Pencil
                        className="w-5 h-5 text-brand-color cursor-pointer hover:text-brand-color-bold"
                        onClick={() => {
                          setFilterShow(true);
                          setEditPrompt({promptLabel: prompt.title, promptLink: prompt.content});
                          setEditIndex(index);
                        }}
                      />
                      <Trash2
                        className="w-5 h-5 text-red-600 cursor-pointer hover:text-red-700"
                        onClick={() => {
                          const updatedPrompts = promptLists.filter((_, i) => i !== index);
                          setPromptLists(updatedPrompts);
                          localStorage.setItem('menuList', JSON.stringify(updatedPrompts));
                        }}
                      />
                    </div>
                  </div>

                  {prompt.toggle && (
                    <div className="p-4 space-y-4 border-t border-gray-200">
                      <div className="text-left">
                        <h3 className="text-sm font-medium text-gray-700">Status</h3>
                        <span className="inline-block mt-1 px-2 py-1 text-xs font-medium rounded-full bg-brand-color text-white">
                          {/^(ftp|http|https):\/\/[^ "]+$/.test(prompt.content) ? 'Link' : 'Prompt'}
                        </span>
                      </div>
                      <div className="text-left">
                        <h3 className="text-sm font-medium text-gray-700">Prompt Title</h3>
                        <p className="mt-1 text-gray-600">{prompt.title}</p>
                      </div>
                      <div className="text-left">
                        <h3 className="text-sm font-medium text-gray-700">Query</h3>
                        <p className="mt-1 text-gray-600">{prompt.content}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center py-4 sm:py-12">
                <img src={logo} alt="No prompts" className="w-24 sm:w-32 h-24 sm:h-32 mx-auto mb-4" />
                <p className="text-gray-500">Nothing here beep beep...<br />Create your prompt now!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePromptScreen;
