import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import FooterV2 from '../components/landing-page/FooterV2';
import HeaderMenu from '../components/landing-page/HeaderMenu';
import PricingCompareSection from '../components/landing-page/PriceCompareSection';
import PricingCTA from '../components/landing-page/PricingCTA';
import PricingSection from '../components/landing-page/PricingSection';
import Addons from '../components/pricing/Addons';
import { useLanguage } from '../context/LanguageContext';
import translations from '../context/Translations';
import CTASection from '../components/landing-page/CTASection';
function PricingScreen() {
  const { language } = useLanguage();
  const t = translations[language];

  const [isYearly, setIsYearly] = useState(false);
  const [activeTab, setActiveTab] = useState('monthly'); // Custom tab state

  return (
    <>
      <Helmet>
        <title>Pricing | Aceva GPT</title>
      </Helmet>
      <HeaderMenu t={t} />

      {/* <PricingPlanV2 /> */}
      {/* pricing section */}
      <PricingSection t={t} />
      <PricingCompareSection t={t} />
      
        <Addons />

        <PricingCTA />

        {/* <CTASection t={t} /> */}

      <FooterV2 t={t} />
    </>
  );
}

export default PricingScreen;
