import React from 'react';
import ConfigBot from '../ConfigBot';

function UpdateLanguageSteps({config, setConfig}) {

  const language = [
    {value: 'en-GB', label: 'English (UK)'},
    {value: 'hi', label: 'Hindi'}, 
    {value: 'id', label: 'Indonesian'},
    {value: 'ms', label: 'Malay'},
    {value: 'zh-CN', label: 'Chinese (PRC)'}
  ];

  // Ensure config.language is initialized as an array
  if (!config.language || !Array.isArray(config.language)) {
    config.language = [];
  }

  const handleLanguageChange = (index, value) => {
    const updatedLanguages = [...config.language];
    updatedLanguages[index] = value ? {value, label: language.find(l => l.value === value).label} : null;
    setConfig({...config, language: updatedLanguages});
  };

  // Filter out selected languages
  const getAvailableLanguages = (index) => {
    return language.filter(lang => 
      !config.language.some((selected, i) => i !== index && selected && selected.value === lang.value)
    );
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between p-4 md:p-8 lg:p-12 gap-6 lg:gap-12">
      <div className="w-full lg:w-1/2">
        <div className="max-w-full lg:max-w-[500px]">
          <div className="create-title mb-6">
            <h2 className="text-2xl font-semibold">Language</h2>
          </div>
          <form>
            <div className="space-y-4">
              <div className="form-group">
                <label htmlFor="primary-language" className="block mb-2 text-sm font-medium font-poppins">
                  Select the <strong className="font-semibold">Primary language</strong> for your bot.
                </label>
                <div className="w-full">
                  <select
                    id="primary-language"
                    className="w-full p-2 border border-gray-200 rounded-lg focus:border-brand-color focus:ring-2 focus:ring-blue-200 transition-all outline-none shadow-sm"
                    value={config.language[0]?.value || ''}
                    onChange={(e) => handleLanguageChange(0, e.target.value)}
                  >
                    <option value="">None</option>
                    {getAvailableLanguages(0).map(lang => (
                      <option key={lang.value} value={lang.value}>{lang.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              {config.language[0] && (
                <div className="form-group">
                  <label htmlFor="secondary-language" className="block mb-2 text-sm font-medium font-poppins">
                    Select the <strong className="font-semibold">Secondary language</strong> for your bot.
                  </label>
                  <div className="w-full">
                    <select
                      id="secondary-language"
                      className="w-full p-2 border border-gray-200 rounded-lg focus:border-brand-color focus:ring-2 focus:ring-blue-200 transition-all outline-none shadow-sm"
                      value={config.language[1]?.value || ''}
                      onChange={(e) => handleLanguageChange(1, e.target.value)}
                    >
                      <option value="">None</option>
                      {getAvailableLanguages(1).map(lang => (
                        <option key={lang.value} value={lang.value}>{lang.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {config.language[1] && (
                <div className="form-group">
                  <label htmlFor="tertiary-language" className="block mb-2 text-sm font-medium font-poppins">
                    Select the <strong className="font-semibold">Tertiary language</strong> for your bot.
                  </label>
                  <div className="w-full">
                    <select
                      id="tertiary-language"
                      className="w-full p-2 border border-gray-200 rounded-lg focus:border-brand-color focus:ring-2 focus:ring-blue-200 transition-all outline-none shadow-sm"
                      value={config.language[2]?.value || ''}
                      onChange={(e) => handleLanguageChange(2, e.target.value)}
                    >
                      <option value="">None</option>
                      {getAvailableLanguages(2).map(lang => (
                        <option key={lang.value} value={lang.value}>{lang.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <ConfigBot mask={true} config={config} setConfig={setConfig} />
      </div>
    </div>
  );
}

export default UpdateLanguageSteps;
