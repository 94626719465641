import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BiMessage } from "react-icons/bi";
import { FaTelegramPlane } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { MdOutlineSell } from "react-icons/md";
import { apiUrl } from '../Variables';
import Layout from '../components/Layout';
import { formatDateToHumanReadable } from '../utils/helper';
import PricingSectionForBilling from '../components/landing-page/PricingSectionForBilling';
import AddonsForBilling from '../components/pricing/AddonsForBilling';


function BillingScreen({ toggleLoggedInState }) {

  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [planLists, setPlanLists] = useState([]);
  const [loading, setLoading] = useState(false)
  const [featchLoading, setFetchLoading] = useState(false)
  const [pid, setId] = useState('')
  const [billingData, setBillingData] = useState({})

  useEffect(() => {
    const fetchApis = async () => {
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      setFetchLoading(true)
      await axios.get(`${apiUrl}/plan`, { headers }).then(res => {
        setPlanLists(res.data)
      }).catch(err => {
        console.log(err)
        // setFetchLoading(false)
      })
      await axios.get(`${apiUrl}/billing/addonBillingList`, { headers }).then(res => {
        setBillingData(res.data)
        setFetchLoading(false)
      }).catch(err => {
        console.log('addonBillingList', err.response)
        setFetchLoading(false)
      })
    }
    fetchApis()
  }, [])

  // This function calls stripe backend function that creates checkout session, using subscriiption id received from PricingTier component in PricingSectionForBilling.js
  const payNow = async (pid, isYearly) => {
    setLoading(true)
    //selection subscription ID is set to pid variable
    setId(pid)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    //POST request sent to backend for creating checkout session for stripe
    //Need to send stripePriceId as well
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/create-checkout-session`,
        method: 'post',
        data: {
          items: [
            {
              id: pid,
              isYearly,
              quantity: 1
            }
          ],
          token: userInfo.token
        },
        headers
      });
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log("***********************************************Error in creating Checkout Session! In payNow function***********************************************\n",error);
    }
  };
  // This function calls stripe backend function that creates checkout session for addon, using addon name id received from AddOn component in AddonsForBilling.js
  const addonPayNow = async (addonName, addOnStripeId, price, validity, extraMsg) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    //POST request sent to backend for creating checkout session for stripe
    //Need to send stripeAddonId as well
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/addonCheckout`,
        method: 'post',
        data: {
          items: [
            {
              // id: addonName.replace(/ /g, "-"),
              addOnName: addonName,
              addOnStripeId: addOnStripeId,
              addOnPrice: price,
              addOnQuantity: 1,
              validity,
              extraMsg
            }
          ],
          token: userInfo.token
        },
        headers
      });
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };
  // This function removes subscription pricing cards that are the same or below user's current subscription
  const remainingPlanList = (currentSub, planLists) => {
    switch (currentSub) {
      case "Freemium":
        return planLists.filter(plan => plan.name === "Basic" || plan.name === "Pro");
      case "admin_plan":
        return planLists.filter(plan => plan.name === "Basic" || plan.name === "Pro");
      case "Basic":
        return planLists.filter(plan => plan.name === "Pro");
      default:
        return [];
    }
  };

  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>

        <div className="billing-wrapper">

          {/* title */}
          <div className="form-title">
            <h3>Billing & Payment</h3>
            <p>You are logged in as <strong className='text-brand-color'>{userInfo.email}</strong></p>
            <div className="underline"></div>
          </div>

          <div className="addon-billing-table-wrap">
            <h3>Subscription Plan Billing Details</h3>
            <div className="billing-kpi-wrapper">
              {
                featchLoading ? <p style={{ fontSize: '14px', margin: '10px 0px' }}>Fetching billing details...</p> : billingData?.subscriptionBilling?.length > 0 ? billingData?.subscriptionBilling?.map((billing, index) => (
                  <React.Fragment key={index}>
                    <div className="billing-kpi-item">
                      <FaTelegramPlane />
                      <p>Subscription Plan</p>
                      <p>{billing?.planName}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <MdOutlineSell />
                      <p>Purchased Date</p>
                      <p>{formatDateToHumanReadable(billing?.createdAt)}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <GoVerified />
                      <p>Valid Till</p>
                      <p>{formatDateToHumanReadable(billing?.validityTill)}</p>
                    </div>
                    <div className="billing-kpi-item">
                      <BiMessage />
                      <p>AI Responses</p>
                      <p>{billing?.restChatVolume}</p>
                    </div>
                  </React.Fragment>
                )) : 'No valid billing information found.'
              }

            </div>
            {
            }
          </div>
          <div className="addon-billing-table-wrap">
            <h3>Addon Billing Details</h3>
            {
              featchLoading ? <p style={{ fontSize: '14px', margin: '10px 0px' }}>Fetching billing details...</p> : billingData?.addOnBilling?.length === 0 ? 'No Addon information found.' : <table>
                <thead>
                  <tr>
                    <th>Addon</th>
                    <th>Purchased Date</th>
                    <th>Valid Till</th>
                    <th>Extra Message Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    billingData?.addOnBilling?.length === 0 ? (
                      ''
                    ) : (
                      billingData?.addOnBilling?.map(item => <tr>
                        <td>{item?.addOn?.addOnName}</td>
                        <td>{formatDateToHumanReadable(item.createdAt)}</td>
                        <td>{formatDateToHumanReadable(item.validityTill)}</td>
                        <td>{ (item?.addOn?.addOnName !== "WhatsappAddon" && item?.addOn?.addOnName !== "MessagesAddon") ? 'N/A' : item.restChatVolume || 0}</td>
                      </tr>)
                    )
                  }
                </tbody>
              </table>
            }
          </div>

          <div className="form-title dashboard-pricing-plan-title">
            <h3>Add Plan</h3>
            <p>We offer a plans to fit your needs. Whether you're just getting started or are a large enterprise, we have a plan for you. </p>
          </div>
          {
          featchLoading ? (
            <p style={{ fontSize: '14px', margin: '10px 0px' }}>Fetching billing details...</p>
            ) : (
            
            <PricingSectionForBilling
            payNow={payNow}
            planLists={remainingPlanList(billingData?.subscriptionBilling?.[0]?.planName, planLists)}
            pid={pid}
            loading={loading}
            />
            )
          }

        </div>
        {/* addon */}
        <AddonsForBilling addonPayNow={addonPayNow} />
        <>
          { }
        </>
      </Layout>
    </>
  )
}

export default BillingScreen