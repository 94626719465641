import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import uploadIcon from '../../images/icons/upload-icon.png';

function ApearanceLogo({uploadFileHandler, config, setConfig}) {

  const navigate = useNavigate();
  // Retrieve userInfo from localStorage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleFileUpload = async (event) => {
    setUploadLoading(true);
    const file = event.target.files[0];
    if (file) {
      // List of accepted image file types
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']; // Add more if needed
      if (acceptedImageTypes.includes(file.type)) {
        uploadFileHandler(event)
      } else {
        toast.error('Please upload only image files (JPEG, PNG, GIF, SVG).');
        setUploadLoading(false);
      }
    }
  };

  const tempImg = ""; 
  

  return (
    <>
      <div className="chatbot-upload-image-wrap apearance-logo-wrapper">
          <div className="form-group">
            <label htmlFor="">Logo</label>
          </div>
          <label>
            <div className="chatbot-upload-content-wrap chatbot-upload-file update-upload-file">
              {/* <FiUpload className='user-update-icon' /> */}
              <img className='img-icon' src={uploadIcon} alt="upload icon" />
              <h3>Upload your logo here</h3>
              <p>Supported formats:  JPG, PNG, SVG, GIF</p>
              <input type="file" accept="image/jpeg, image/png, image/gif, image/svg+xml" onChange={handleFileUpload} id='image-file' name="profilePic" custom="true" hidden />

              {/* <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden /> */}
            </div>
          </label>
        </div>

        {
          config?.logo && (
            <div className="user-profile-header-thumb create-group-logo uploaded-logo-wrap">
        
              <img src={config?.logo && config?.logo || tempImg} alt="profile photo" />
              {/* <div className="user-update-profile create-user-profile-update-icon-wrapper">
                <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                <div className="user-update-profile">
                  <MdCloudUpload className='user-update-icon' />
                </div>
              </div> */}
            </div>
          )
        }
    </>
  )
}

export default ApearanceLogo