import { ArrowRight } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Step1 from '../../videos/1.mp4'
import Step2 from '../../videos/2.mp4'
import Step3 from '../../videos/3.mp4'

export default function ChatbotCreateSteps({t}) {
  const [activeStep, setActiveStep] = useState(0)

  const steps = [
    { step: "Step 1", title: "INSERT", description: "the link", image: Step1 },
    { step: "Step 2", title: "DESIGN", description: "your chatbot", image: Step2 },
    { step: "Step 3", title: "PUBLISH", description: "your chatbot", image: Step3 },
  ]

  return (
    <section className="bg-gradient-to-br from-indigo-100 via-purple-50 to-teal-100 py-24 px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold leading-tight md:text-5xl lg:text-5xl mx-auto text-center mb-2 sm:mb-6">
          {t?.stepSection?.title}
        </h2>
        <p className="text-xl text-gray-600 mb-10 text-center leading-9">
        {t?.stepSection?.subTitle?.light} <br />
        <span className="font-bold">{t?.stepSection?.subTitle?.bold}</span> 
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div className="relative cursor-pointer">
            <div className="absolute left-0 top-0 h-full w-1 bg-gray-300 rounded-full" />
            
            {t?.stepSection?.steps?.map((step, index) => (
              <div key={step.title} className="relative pl-7 pb-5 last:pb-0">
                <div 
                  className={`absolute left-0 w-7 h-7 rounded-full border-4 border-white cursor-pointer transition-all duration-300 ${activeStep === index ? 'bg-brand-color-bold' : 'bg-gray-300'}`}
                  style={{ transform: 'translateX(-50%)', top: '0.5rem' }}
                  onClick={() => setActiveStep(index)}
                  />
                <div 
                  className={`bg-white rounded-lg p-6 shadow-md transition-all duration-300 ${activeStep === index ? 'border-2 border-brand-color' : 'border-2 border-transparent'}`}
                  onClick={() => setActiveStep(index)}
                  >
                  <h3 className={`text-sm font-medium  mb-4 bg-brand-color rounded-full px-4 py-2 w-fit ${activeStep === index ? 'bg-brand-color text-white' : 'bg-gray-100 text-gray-500'}`}>Step {index + 1}</h3>
                  <h3 className="text-3xl font-bold mb-2 text-gray-800">{step.title}</h3>
                  <p className="text-lg text-gray-600">{step.subTitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center">
            <video 
              src={steps[activeStep].image}
              className="w-full h-auto rounded-xl shadow-lg transition-all duration-300"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
        </div>
        
        <div className="mt-16 text-center">
          <Link
            to="/register"
            className="inline-flex items-center bg-white text-brand-color font-medium text-lg py-3 px-8 rounded-full transition duration-300 ease-in-out shadow-xs hover:shadow-lg hover:text-brand-color-bold group"
          >
            {t?.stepSection?.button}
            <ArrowRight className="ml-2 w-5 h-5 text-brand-color transition-transform duration-300 group-hover:translate-x-1" />
          </Link>
        </div>
      </div>
    </section>
  )
}