import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { MdCloudUpload } from 'react-icons/md';
import { apiUrl } from '../../Variables';
import ChangeEmail from './ChangeEmail';
import ChangePhoneNumber from './ChangePhoneNumber';
import { toast } from 'react-hot-toast';

function MyAccountTab({userDetails, toggleRefresh}) {

  const [uploadLoading, setUploadLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [text, setText] = useState(null); // State to hold extracted text
  const [userState, setUserState] = useState(
    {
      email: "",
      username: "",
      first_name: "",
      last_name: "",
      phone: "",
      profile_image: "",
      role: "",
      subscription_id: ""
    }
  )

  useEffect(() => {
    if(userDetails) {
      setUserState({...userDetails})
    }
  },[userDetails])

  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'pewhglsn');
    formData.append('cloud_name', 'dzo2vmjdy');

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      setUploadLoading(true);
      const { data } = await axios.post(
        'https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload',
        formData,
        config
      );
      setFile(data?.url);
      setUploadLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserHandler = async (e) => {
    e.preventDefault(); 

    try {
      // Retrieve userInfo from localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (!userInfo || !userInfo.token) {
        console.error('User token not found.');
        return;
      }

      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      setUploadLoading(true)
      const {data} = await axios.put(`${apiUrl}/user/${userInfo?._id}`, userState, {headers})
      data && localStorage.setItem('userInfo', JSON.stringify({...userInfo, ...data}))
      toast.success('Profile updated successfully!')
      console.log('data:', data)
      toggleRefresh()
      setUploadLoading(false)
    } catch (error) {
      setUploadLoading(false)
      console.log('update error:', error)
    }
  }

  console.log('userState', userState)
  console.log('userdetails', userDetails)

  return (
    <>
      <form onSubmit={updateUserHandler}>
        <div className="settings-left-wrapper">

          {/* username */}
          {/* <div className="form-group">
            <label htmlFor="name">Username</label>
            <input disabled type="text" id='name' 
              onChange={(e) => {
                userState.username = e.target.value
                setUserState({...userState})
              }} 
            value={userDetails?.username} placeholder={userDetails?.username || 'Username'} />
          </div> */}

          <div className="form-row">
            {/* first name */}
            <div className="form-group">
              <label htmlFor="name">First Name</label>
              <input type="text" id='name'
              onChange={(e) => {
                userState.first_name = e.target.value
                setUserState({...userState})
              }}
              value={userState?.first_name} placeholder='First Name' />
            </div>

            {/* name */}
            <div className="form-group">
              <label htmlFor="name">Last Name</label>
              <input type="text"
              onChange={(e) => {
                userState.last_name = e.target.value
                setUserState({...userState})
              }}
              id='name' value={userState?.last_name} placeholder='Last Name' />
            </div>
          </div>

          <div className="form-row">
            {/* Email address */}
            <div className="form-group">
              <label htmlFor="OnlineStatus">Email address</label>
              <input type="email"
              disabled
              onChange={(e) => {
                userState.email = e.target.value
                setUserState({...userState})
              }}
              value={userState?.email} placeholder='contact@example.com' id='OnlineStatus' />
              {/* <div className="change-email-phone">
                <ChangeEmail />
              </div> */}
            </div>

            {/* Phone number */}
            <div className="form-group">
              <label htmlFor="PhoneNumber">Phone number</label>
              <input type="number"
              // disabled
              onChange={(e) => {
                userState.phone = e.target.value
                setUserState({...userState})
              }}
              value={userState?.phone} placeholder='018xxxxxxxxx' id='PhoneNumber' />
              {/* <div className="change-email-phone">
                <ChangePhoneNumber />
                </div> */}
            </div>
          </div>

          {/* Date of birth */}
          <div className="form-group">
            <label htmlFor="OnlineStatus">Join date: {moment(userState?.createdAt).format('ll')}</label>
            {/* <input type="text" value={moment(userDetails?.createdAt).format('lll')} /> */}
          </div>

          <button type='submit' className="form-button max-content">{uploadLoading ? 'Updating...' : 'Save changes'}</button>
        </div> 
      </form>
    </>
  )
}

export default MyAccountTab