import imgone from '../images/accordion/1.png';
import imgtwo from '../images/accordion/2.png';
import imgthree from '../images/accordion/3.png';
import imgfour from '../images/accordion/4.png';
import imgfive from '../images/accordion/5.png';

const Translations = {
  english: {
    menu: {
      howIsItWorks: 'How it works',
      demo: 'Demo',
      pricing: 'Pricing',
      signIn: 'Sign In',
      button: 'Create Chatbot',
      contactUs: 'Contact Us'
    },
    videoTitle: "How to create your own chatbot",
    landingPageVideoTitle: "See our chatbot in action!",
    singleTitle: "Why choose Aceva for your Business?",
    titleDetailSection: {
      title: ["Proven Insights: How AI Can", "Enhance Your Business’s", "Customer Service"],
      subTitle: 'Explore how AI is reshaping customer service, with insights derived from multiple research studies. Learn why businesses are adopting AI',
      button: 'Explore more'
    },
    performanceSection: {
      startBuilding: 'Start building a high performing',
      title: ' website and accelerate your business growth.',
      stats: {
        performance: { title: 'Impressive Performance', description: 'Another way to grow fast' },
        retention: { title: 'Customer Retention', description: 'On your website' },
        growth: { title: 'Extra Growth Revenue', description: 'From your sales' },
      }
    },
    heroSectionV3: {
      topTitle: 'AI Chatbot Services',
      title: ['Smart AI Chatbots for Customer Service, Lead Generation, and Business Growth'],
      subTitle: 'Aceva is your AI chatbot provider, driving customer engagement and business growth. Our customizable solutions scale and automate customer interactions, enhancing efficiency across both B2C and B2B markets. Let us optimize your operations for sustainable and long-term success.',
      buttons: {
        getStarted: 'Get Started Today',
      }
    },
    integrationSection: {
      title: "Simplify your work: Connect Aceva to your favorite platforms",
      more: "And more coming soon!"
    },
    accordionSection: {
      subTitle: "Robust Features",
      title: "How can an AI chatbot help?",
      accordions: [
        {
          title: "24/7 Customer Support",
          content:
            "Aceva never sleeps, ensuring your customers can get the support they need at any time, reducing response times and improving customer satisfaction.",
          image: imgone, // Unique image for this item
        },
        {
          title: "Automate Frequently Asked Questions",
          content:
            "Save time by automating common queries like store hours, product details, or service availability. Let Aceva handle the routine questions while your team focuses on complex issues.",
          image: imgtwo, // Unique image for this item
        },
        {
          title: "Lead Generation and Qualification",
          content:
            "Aceva is capable of capturing and qualifying leads automatically by asking key questions, helping your sales team focus on high-value prospects and increasing conversion rates.",
          image: imgthree, // Unique image for this item
        },
        {
          title: "Instant Feedback and Problem Resolution",
          content:
            "Aceva helps resolve customer issues instantly by providing quick responses, ensuring customers feel heard and satisfied without long wait times.",
          image: imgfour, // Unique image for this item
        },
        {
          title: "Effortless Communication and Scheduling via Integrations",
          content:
            "Aceva integrates with your messaging platforms and scheduling tools, enabling smooth communication and seamless appointment booking directly within the chatbot.",
          image: imgfive, // Unique image for this item
        },
      ]
    },
    colSection: {
      title: 'Transform Your Website With AI-Powered Chat',
      subTitle: 'Enhance customer interactions and boost conversions with our cutting-edge chatbot solution.',
      button: 'Get Started Free',
      steps: ['24/7 Intelligent Customer Support', 'Lightning-Fast Response Times', 'Increased Conversion Rates']
    },
    heroSection: {
      title: {
        custom: 'Custom',
        chatGPT: 'ChatGPT',
        forYour: 'For Your',
        business: 'Business',
      },
      subTitle: "Answer visitor questions instantly with a chatbot trained on your site's content. Just connect your data, add it as a widget, and chat through integrations or API.",
      button: 'Create your chatbot now',
      noCardRequired: 'No credit card required!'
    },
    marqueeSection: {
      trustedBusinesses: 'Trusted by +25,000 businesses',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Years innovating chatbots',
      aiSpecialists: 'AI specialists',
      chatbotsDeployed: 'Chatbots deployed',
    },
    leftSideFlexSection: {
      badge: 'Lead generation',
      title: 'Add an Intelligent Sales Agent to Your Platform',
      subTitle: "See our chatbot in action",
      button: "Create your chatbot"
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'POWERFUL INTEGRATIONS',
        title: 'Integrate with Your Favorite Apps',
        description: 'Enhance your workflow by connecting our platform with the tools you use every day. Seamless integration, powerful results.',
      },
      appIntegrations: {
        gmailDescription: 'Seamlessly integrate your email workflow with our platform.',
        slackDescription: 'Connect your team communication directly to our services.',
        shopifyDescription: 'Streamline your e-commerce operations with our Shopify integration.',
        intercomDescription: 'Enhance customer support with our Intercom integration.',
        twitterDescription: 'Manage your social media presence directly from our platform.',
        sketchDescription: 'Bring your designs to life with our Sketch integration.',
        directIntegration: 'Direct Integration',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'WHAT THEY\'RE SAYING',
        title: 'Hear From Our Happy Clients',
        description: 'Discover what our clients love about ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Apple Business Manager',
          review: 'ACEVA GPT has transformed the way we handle customer service. It’s like having a virtual team working around the clock!',
        },
        tristanEka: {
          position: 'Sales Manager at TechPlus',
          review: 'The accuracy and speed of ACEVA GPT are remarkable. It has helped us streamline our sales processes significantly.',
        },
        johanMular: {
          position: 'Head of Operations at InnovateNow',
          review: 'ACEVA GPT’s chatbot has not only improved our response times but also enhanced customer satisfaction across the board.',
        },
        lindaBrooks: {
          position: 'Marketing Director at SkyCorp',
          review: 'We’ve seen a significant increase in conversions after integrating ACEVA GPT into our website. It’s a game-changer!',
        },
      },
    },
    stepSection: {
      title: 'Everything you need for your AI chatbot',
      subTitle: {
        light: 'Creating the chatbot is now as easy as clicking a button. With',
        bold: '3 simple-steps'
      },
      button: 'Begin Your AI Journey',
      steps: [
        {
          title: 'INSERT',
          subTitle: 'the link'
        },
        {
          title: 'DESIGN',
          subTitle: 'your chatbot'
        },
        {
          title: 'PUBLISH',
          subTitle: 'your chatbot'
        },
      ]
    },
    videoSection: {
      title:'Still think that its complicated?',
      subTitle: 'Watch the tutorial below to gain better understanding of Aceva.'
    },
    chatbotSection: {
      title: 'Try Aceva out!',
      subTitle: 'The chatbot below is one of the replicas that may be featured on your page. Go on, ask away!'
    },
    ctaSection: {
      title: 'Elevate your business with AI-powered automation',
      subTitle: 'Create your own AI chatbot without any coding, and watch it learn and improve from your content in real-time. Start delivering faster, more personalized customer service today.',
      button: 'Create your chatbot now'
    },
    faqSection: {
      title: 'Frequently asked questions',
      description: 'Get answers to common questions about ACEVA.',
      faqs: [
        {
          question: "What is Aceva?",
          answer:
            "Aceva is an AI-powered chatbot solution designed to enhance customer service and drive business growth. It leverages OpenAI's advanced GPT technology, including versions like ChatGPT 4.0 for more robust business needs. For businesses looking for a freemium model, Aceva also incorporates AI models like Mistral. With its scalable solutions, Aceva helps businesses streamline customer support and lead generation while offering flexible pricing based on the AI model chosen.",
        },
        {
          question: "Can I test Aceva for free?",
          answer: 'Yes! Try our freemium model by <a href="/register" target="_blank" rel="noopener noreferrer">creating your account</a>.',
        },
        
        {
          question: "Is it hard to install Aceva on a website?",
          answer:
            "Not at all. Installing Aceva is a simple process that does not require coding or advanced technical skill. To connect, you simply need to copy and paste a code snippet after creating your own Aceva chatbot into your website’s source code.",
        },
        {
          question: "How to create my AI chatbot?",
          answer:
            "After creating your account, you will simply need to provide your website URL, knowledge base link, or other text content to automatically build your custom AI chatbot for customer service. Your chatbot can be retrained and fine-tuned with additional data.",
        },
        {
          question: "Can I customize my AI chatbot?",
          answer: "Yes, it can be done in our  <a href='/pricing' target='_blank' rel='noopener noreferrer'>Basic and Pro plans</a>.",
        },
        {
          question: "How do AI chatbots improve customer interactions and service?",
          answer:
            "AI chatbots use advanced conversational AI to provide instant, real-time responses across websites, apps, and messaging platforms. They enhance customer service by managing FAQs, troubleshooting, and delivering personalized answers, all while handling multiple queries simultaneously. With their ability to reduce wait times and operate 24/7, AI chatbots ensure a seamless customer experience. For complex issues, they can efficiently transfer interactions to human agents, improving satisfaction while reducing operational costs for businesses.",
        },
        {
          question: "How do AI chatbots benefit customer support teams?",
          answer:
            "AI-powered bots can automate repetitive tasks like answering frequently asked questions and scheduling appointments, enabling faster issue resolution. They provide round-the-clock support, freeing up human agents to focus on complex inquiries, boosting efficiency and customer satisfaction.",
        },
        {
          question: "How do AI chatbots qualify and convert leads?",
          answer:
            "Chatbots can engage visitors with targeted questions, identify high-intent prospects, and hand off qualified leads to the sales team. They are able to facilitate actions like scheduling meetings or providing personalized product recommendations, streamlining the sales process and improving conversion rates.",
        },
        {
          question: "How do chatbots contribute to sales and lead generation?",
          answer:
            "Chatbots engage visitors with personalized interactions, qualify leads by asking targeted questions, capture user contact details, and guide users through the buying process. They can also transfer leads to sales teams or schedule appointments, ensuring no sales opportunities are missed.",
        },
        {
          question: "How do AI-powered bots assist with marketing efforts?",
          answer:
            "Chatbots boost marketing by educating visitors, collecting leads, promoting any specific offerings, and increasing conversions through interactive dialogues. They proactively guide users on landing pages, helping businesses achieve better engagement and results.",
        },
        {
          question: "What makes AI chatbots valuable for businesses?",
          answer:
            "AI chatbots streamline operations by automating repetitive tasks, improving customer experience, and increasing sales opportunities. Their 24/7 availability and capability to scale make them a reliable solution for growth.",
        },
        {
          question: "How secure is data handled by AI chatbots?",
          answer:
            "AI chatbots prioritize security by following industry standards like data encryption and GDPR compliance. Businesses using Aceva can trust that sensitive customer information is protected through robust security protocols. View our <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>terms & conditions</a> and also our <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>privacy policy</a> for more details.",
        },
      ]      
    },
    textSection: {
      description: "Aceva is an AI-powered chatbot solution designed for businesses of all sizes. Simplify customer interactions, automate repetitive queries, and drive business growth with just a single AI solution."
    },
    footer: {
      bio: "Empowering businesses with next-generation AI solutions for seamless customer interactions and data management.",
      product: {
        title: "Product",
        menus: ["Pricing", "Blog", "Contact Us"]
      },
      legal: {
        title: "Legal",
        menus: ["Terms & Conditions", "Privacy Policy"]
      },
      lastColumn: {
        getInTouch: "Get In Touch",
        connectWithUs: "Connect With Us"
      },
    },
    // pricing
    pricing: {
      badge: "Pricing",
      title: 'Affordable pricing that scales with your needs',
      subTitle: 'Get started for free. We offer solutions for businesses of all sizes',
      compareFeatureTitle: "Features",
      pricingCta: {
        title: "Need a Tailored Chatbot Solution?",
        subTitle: "Let us help you design a custom AI chatbot perfectly suited to your business needs.",
        button: "Custom your chatbots"
      },
      addons: {
        title: "Add-ons",
        subTitle: "Enhance your experience with our powerful add-ons",
        items: {
          moreWhatsapp: {
            title: "More WhatsApp AI messages",
            subTitle: "Increase your WhatsApp AI responses and messages",
            price: 5.00,
            unit: "per 1000 messages / month",
            credits: "1000 credits"
          },
          moreAiResponse: {
            title: "More AI responses",
            subTitle: "Increase your AI chatbot responses",
            price: 5.00,
            unit: "per 1000 responses / month",
            credits: "1000 credits"
          },
          removeBranding: {
            title: "Remove Aceva's branding",
            subTitle: "Remove our branding from your chatbot interface",
            price: 5.00
          },
          additionalChatbot: {
            title: "Additional Chatbot",
            subTitle: "Scale your business with an additional AI chatbot",
            price: 5.00,
            unit: "One-time fee per chatbot"
          },
          buildForYou: {
            title: "Build it for you",
            subTitle: "A chatbots trained and tailored for you",
            description: "We develop the chatbot for you",
            price: 15.00
          }
        }
      },
      comparePlans: {
        title: "Compare Plans",
        disclaimer: "All prices are in USD. Additional local tax may be collected depending on your region.",
        monthly: "Monthly",
        yearly: "Yearly",
        featureNames: [
          "GPT-4.0 Responses",
          "WhatsApp Conversation Messages", 
          "Webpage Training Pages",
          "Max File Upload",
          "Max File Size",
          "AI Chatbots",
          "Lead Generation",
          "Push Notification", 
          "Dashboard",
          "Client Chatlogs",
          "Data Exporting",
          "WhatsApp",
          "Facebook Messenger",
          "Telegram", 
          "Google Calendar",
          "Calendly"
        ],
        freemium: {
          name: "Freemium",
          price: "FREE",
          currency: "USD",
          features: [
            "500 Mistral",
            "0",
            "25",
            "3",
            "5 MB",
            "1",
            false,
            true,
            true,
            true,
            false,
            false,
            true,
            false,
            false,
            false
          ]
        },
        basic: {
          name: "Basic", 
          price: 15,
          currency: "USD",
          features: [
            "4,000 mini",
            "2,000",
            "200",
            "20",
            "10 MB",
            "1",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            true
          ]
        },
        pro: {
          name: "Pro",
          price: 49,
          currency: "USD",
          features: [
            "10,000 mini",
            "4,000",
            "500",
            "30",
            "50 MB",
            "3",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        },
        custom: {
          name: "Pro+",
          price: "Contact Us",
          currency: "USD",
          features: [
            "Custom",
            "Custom",
            "Custom",
            "Custom",
            "Custom",
            "Custom",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        }
      },
      pricingPlans: [
        {
          title: "Freemium",
          description: "Experience it for 30 days and see the difference!",
          price: {
            isYearly: "0",
            isMonthly: "0"
          },
          features: [
            "500 Mistral Responses/mo",
            "1 AI Chatbot",
            "Comprehensive Webpage Training – up to 25 pages",
            "Dashboard access for Insights & Analysis",
            "Real-time updates and customer interactions tracking"
          ],
          isEssential: false
        },
        {
          title: "Basic",
          description: "Ideal for businesses looking to incorporate an AI Chatbot.",
          price: {
            isYearly: "180",
            isMonthly: "15"
          },
          features: [
            "4,000 GPT Responses/mo",
            "1 AI Chatbot", 
            "Comprehensive Webpage Training – up to 200 pages",
          ],
          advancedFeatures: [
            "Custom AI Chatbot profile",
            "Access to GPT-4o Mini for faster, smarter solutions",
            "Lead generations",
            "Integration with Messenger and Telegram",
            "Appointment scheduling with Calendly"
          ],
          featuresTitle: "All from Freemium Plan, plus:",
          isEssential: true
        },
        {
          title: "Pro",
          description: "Designed for businesses that need advanced features to scale quickly.",
          price: {
            isYearly: "588",
            isMonthly: "49"
          },
          features: [
            "10,000 GPT Responses/mo",
            "3 AI Chatbots",
            "Comprehensive Webpage Training – up to 500 pages",
          ],
          advancedFeatures: [
            "Removed watermark from AI Chatbot profile",
            "Data Exporting",
            "Expanded scheduling - now includes Google Calendar"
          ],
          featuresTitle: "All from Basic Plan, plus:",
          isEssential: false
        },
        {
          title: "Pro+",
          description: "Scalable solutions tailored to your needs.",
          price: "Contact us",
          features: [
            "Custom GPT Responses/mo",
            "Custom AI Chatbots",
            "Custom Webpage Training",
          ],
          featuresTitle: "All from Pro Plan, plus:",
          advancedFeatures: [
            "Custom dashboard access for Insights & Analysis",
            "Custom AI Chatbot profile personalization",
            "Custom integrations",
            "Access to all dashboard and insight functionalities"
          ],
          buttonText: "Contact us",
          isEssential: false,
          isCustom: true
        }
      ]
    },
    // contact us
    contactUs: {
      title: "Contact Us",
      subTitle: "Feel free to reach out to us at your convenience, and we will respond to you as promptly as we can.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Terms and Conditions",
      subTitle: "By continuing to use our platform, you agree to these terms and conditions.",
      description: {
        billingRefunds: {
          title: "Billing & Refunds",
          accessCharge: "We charge you for access to the product in monthly and yearly plans",
          noRefunds: "Due to the nature of our product, we currently do not offer refunds, either partial or in full.",
          cancelSubscription: "You can easily cancel your subscription at any time you like. We will no longer charge you anything once you cancel your subscription.",
          changePricing: "We may change our pricing, pricing policies, features, and access restrictions at any time.",
        },
        emails: {
          title: "Emails",
          content: "We may utilize your email address to reach out to you regarding your account, provide you with product updates, and inform you about various marketing initiatives. You have the option to opt out of receiving these emails whenever you wish.",
        },
        conditions: {
          title: "Conditions",
          content1: "We reserve the right to change/amend the policy at any time.",
          content2: "By continuing to use our platform, you agree to these terms and conditions.",
        },
        privacyPolicy: "Privacy Policy",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Privacy Policy",
      subTitle: "By continuing to use our platform, you agree to these privacy Policy",
      description: {
        introduction: {
          title: "Introduction",
          content: "Please review this Privacy Policy ('Policy') before using our Service, including the Website, Widget, and API (as defined below), referred to collectively as the 'Service.' This Policy governs the information and data we collect, how we use it, and how we share it. Your access to and use of the Service are conditioned on your agreement with the Terms of Service ('Terms of Service'), which includes the terms of this Policy. ACEVA GPT ('Company') operates the Service. We use your data to improve the Service. By using the Service, you agree to the collection and use of information as described in this Policy. Unless otherwise defined in this Policy, the terms used here have the same meanings as in our Terms of Service."
        },
        definitions: {
          title: "Definitions",
          content: {
            API: "Refers to ACEVA GPT's application programming interface integrated with the User's software.",
            Cookies: "Small files stored on your Device.",
            Device: "Means a computer or a mobile device.",
            DataController: "Refers to ACEVA GPT as the entity responsible for your data.",
            DataProcessors: "Those who process data on behalf of ACEVA GPT.",
            DataSubject: "Any living individual who is the subject of Personal Data.",
            PersonalData: "Data about an identifiable living individual.",
            Service: "The Website, Widget, and/or API. Usage Data includes data collected automatically during your use of the Service.",
            User: "The individual using our Service.",
          }
        },
        dataController: {
          title: "Data Controller",
          content: "The controller of your Personal Data is ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Information Collection and Use",
          content: "We collect various information to improve our Service."
        },
        typesOfDataCollected: {
          title: "Types of Data Collected",
          personalData: {
            title: "Personal Data",
            content: ["Email address", "First and last name", "Cookies and Usage Data"]
          },
          usageData: {
            title: "Usage Data",
            content: "Includes browser information, IP address, and other technical data."
          }
        },
        useOfData: {
          title: "Use of Data",
          content: "ACEVA GPT uses collected Personal Data for various purposes, including providing the Service, customer support, and improving it."
        },
        retentionOfData: {
          title: "Retention of Data",
          content: "We retain your Personal Data as necessary to meet legal obligations and for the purposes described in this Policy."
        },
        transferOfData: {
          title: "Transfer of Data",
          content: "Your data may be transferred to other jurisdictions but will be protected as described in this Policy."
        },
        disclosureOfData: {
          title: "Disclosure of Data",
          content: "We may share Personal Data in specific circumstances as described in this Policy."
        },
        securityOfData: {
          title: "Security of Data",
          content: "The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We use appropriate administrative, technical, and physical safeguards to protect your Personal Data."
        },
        yourDataProtectionRights: {
          title: "Your Data Protection Rights",
          content: "In Malaysia, individuals have data protection rights under the Personal Data Protection Act 2010 (PDPA), including the rights to access, correct, withdraw consent, request data portability, object, and seek compensation for violations."
        },
        serviceProviders: {
          title: "Service Providers",
          content: "Third-party companies help us provide the Service, but they are obligated not to disclose or use the information for any other purpose."
        },
        analytics: {
          title: "Analytics",
          content: "We use third-party services like Google Analytics for analysis."
        },
        payments: {
          title: "Payments",
          content: "Third-party payment processors handle payments securely."
        },
        linksToOtherSites: {
          title: "Links to Other Sites",
          content: "We are not responsible for the privacy practices of other websites."
        },
        childrensPrivacy: {
          title: "Children's Privacy",
          content: "Our Service is not for children under 16."
        },
        limitedUseRequirements: {
          title: "Limited Use Requirements",
          content: "ACEVA's use and transfer to any other app of information received from Google APIs will adhere to the",
          content2: "including the Limited Use requirements.",
          label: "Google API Services User Data Policy",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Limited Data Sharing",
            content: "ACEVA ensures that any data shared with third-party tools, including AI models, is limited to what is necessary for the specific functionality or service being provided. We do not share personally identifiable information with third parties without explicit user consent, unless required by law or in accordance with our privacy policy."
          },
          {
            key: "optOutOptions",
            title: "Opt-Out Options",
            content: "ACEVA provides users with the option to opt-out of certain data sharing practices within the app. Users can access and adjust their privacy settings to control the sharing of their data with third-party tools. We offer clear instructions and user-friendly interfaces to facilitate this process."
          },
          {
            key: "dataProtectionMeasures",
            title: "Data Protection Measures",
            content: "ACEVA implements stringent security measures to safeguard user data against unauthorized access, disclosure, or misuse. We adhere to industry best practices and regularly review and update our security protocols to ensure compliance with data protection standards."
          },
          {
            key: "informedConsentExplanation",
            title: "Informed Consent Explanation",
            content: "Before any data sharing occurs with third-party tools, users are presented with a clear and comprehensive explanation of why their data is being shared and how it will be used. This explanation is provided in plain language, avoiding technical jargon, to ensure users fully understand the purpose and implications of data sharing."
          },
          {
            key: "optInConsentMechanism",
            title: "Opt-In Consent Mechanism",
            content: "ACEVA's app implements an opt-in consent mechanism for data sharing with third-party tools. Users are presented with a checkbox or toggle switch explicitly asking for their consent to share data with the specified third-party tool or AI model. This opt-in mechanism ensures that data sharing only occurs with the explicit approval of the user."
          },
          {
            key: "clearConsentConfirmation",
            title: "Clear Consent Confirmation",
            content: "Once users provide their consent through the opt-in mechanism, ACEVA's app displays a clear confirmation message confirming that the user has explicitly agreed to share their data with the specified third-party tool or AI model. This confirmation message serves as a final acknowledgment of the user's consent before data sharing commences."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Changes to This Privacy Policy",
          content: "We may update the Policy, so please check it periodically."
        },
        contactUs: {
          title: "Contact Us",
          content: "Questions? Contact us at",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Terms and Conditions",
          link: "/terms-and-conditions"
        }
      }
    },
  },
  malay: {
    // Extending with full translations for Malay...
    menu: {
      howIsItWorks: 'Cara berfungsi',
      demo: 'Demo',
      pricing: 'Harga',
      signIn: 'Log Masuk',
      button: 'Cipta Chatbot',
      contactUs: 'Hubungi Kami'
    },
    videoTitle: "Cara mencipta chatbot anda sendiri",
    landingPageVideoTitle: "Lihat chatbot kami beraksi!",
    singleTitle: "Mengapa Memilih Aceva untuk Perniagaan Anda?",
    titleDetailSection: {
      title: 'Kajian Terbukti: Bagaimana AI Boleh Tingkatkan Khidmat Pelanggan Perniagaan Anda',
      subTitle: 'Terokai bagaimana AI mengubah perkhidmatan pelanggan, dengan pandangan daripada pelbagai kajian penyelidikan. Ketahui mengapa perniagaan mengadaptasi AI',
      button: 'Terokai lagi'
    },    
    performanceSection: {
      startBuilding: 'Mulakan membina prestasi tinggi',
      title: 'Laman web & kembangkan perniagaan anda dengan cepat.',
      stats: {
        performance: { title: 'Prestasi Mengesankan', description: 'Cara lain untuk berkembang dengan cepat' },
        retention: { title: 'Pengekalan Pelanggan', description: 'Di laman web anda' },
        growth: { title: 'Pendapatan Pertumbuhan Ekstra', description: 'Daripada jualan anda' }
      }
    },
    heroSectionV3: {
      topTitle: "Perkhidmatan Chatbot AI",
      title: ["Chatbot AI Pintar untuk Perkhidmatan Pelanggan, Penjanaan Prospek, dan Kejayaan Perniagaan"],
      subTitle: "Aceva ialah solusi AI chatbot anda, menggerakkan penglibatan pelanggan dan kejayaan perniagaan. AI chatbot kami boleh diubah suai untuk interaksi pelanggan diautomasi, meningkatkan kecekapan merentasi pasaran B2C dan B2B. Biar kami mengoptimumkan operasi anda untuk kejayaan berkekalan.",
      buttons: {
        getStarted: "Mulakan hari ini",
      }
    },
    integrationSection: {
      title: "Permudahkan kerja anda: Sambungkan Aceva ke platform kegemaran anda",
      more: "Dan lebih banyak lagi akan datang!"
    },    
    accordionSection: {
      subTitle: "Ciri-Ciri Mantap",
      title: "Bagaimanakah AI Chatbot boleh membantu?",
      accordions: [
        {
          title: "Sokongan Pelanggan 24/7",
          content:
            "Aceva sentiasa peka. Ia memastikan pelanggan anda mendapat sokongan pada bila-bila masa, mengurangkan masa respons, dan meningkatkan kepuasan pelanggan.",
          image: imgone, // Imej unik untuk item ini
        },
        {
          title: "Automasi Soalan Lazim",
          content:
            "Jimatkan masa dengan automasi soalan umum seperti waktu operasi, butiran produk, atau ketersediaan perkhidmatan. Aceva boleh uruskan soalan rutin dan dapatlah pasukan anda fokus kepada isu yang kompleks.",
          image: imgtwo, // Imej unik untuk item ini
        },
        {
          title: "Penjanaan dan Kelayakan Prospek",
          content:
            "Aceva mampu mengenal pasti prospek secara automatik melalui pertanyaan soalan penting, mengubah fokus pasukan jualan anda kepada prospek bernilai tinggi, dan meningkatkan kadar konversi.",
          image: imgthree, // Imej unik untuk item ini
        },
        {
          title: "Maklum Balas dan Penyelesaian Masalah Lekas",
          content:
            "Aceva membantu menyelesaikan masalah pelanggan dengan segera dengan pemberian respons yang cepat, kepastian pelanggan didengar tanpa menunggu lama.",
          image: imgfour, // Imej unik untuk item ini
        },
        {
          title: "Komunikasi dan Penjadualan Mudah melalui Integrasi",
          content:
            "Aceva berintegrasi dengan platform pemesejan dan penjadualan anda. Ini melancarkan komunikasi dan memudahkan urusan tempahan melalui chatbot.",
          image: imgfive, // Imej unik untuk item ini
        }
      ]
    },    
    colSection: {
      title: 'Transformasi Laman Web Anda Dengan Perbualan Berteknologi AI',
      subTitle: 'Tingkatkan interaksi pelanggan dan penukaran dengan penyelesaian chatbot termaju kami.',
      button: 'Mulakan Secara Percuma',
      steps: [
      'Sokongan Pelanggan Pintar 24/7',
      'Masa Tindak Balas Pantas',
      'Kadar Penukaran Yang Meningkat'
      ]
      },
    heroSection: {
      title: {
        custom: 'Khusus',
        chatGPT: 'ChatGPT',
        forYour: 'Untuk Perniagaan',
        business: 'anda',
      },
      subTitle: "Jawab soalan pelawat serta-merta dengan chatbot yang dilatih berdasarkan kandungan laman web anda. Sambungkan data anda, tambahkan sebagai widget, dan berbual melalui integrasi atau API.",
      button: 'Buat Chatbot anda sekarang',
      noCardRequired: 'Tiada kad kredit diperlukan!'
    },
    marqueeSection: {
      trustedBusinesses: 'Dipercayai oleh lebih 25,000 perniagaan',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Tahun berinovasi chatbot',
      aiSpecialists: 'Pakar AI',
      chatbotsDeployed: 'Chatbot yang dikerahkan',
      clientSatisfaction: 'Kepuasan pelanggan',
    },
    leftSideFlexSection: {
      badge: 'Penjanaan prospek',
      title: 'Tambahkan Ejen Jualan Pintar kepada Platform Anda',
      subTitle: "Lihat chatbot kami beraksi",
      button: "Cipta chatbot anda"
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'INTEGRASI YANG KUAT',
        title: 'Integrasikan dengan Aplikasi Kegemaran Anda',
        description: 'Tingkatkan aliran kerja anda dengan menghubungkan platform kami dengan alatan yang anda gunakan setiap hari. Integrasi lancar, hasil yang hebat.',
      },
      appIntegrations: {
        gmailDescription: 'Integrasi aliran kerja emel anda dengan lancar menggunakan platform kami.',
        slackDescription: 'Hubungkan komunikasi pasukan anda terus ke perkhidmatan kami.',
        shopifyDescription: 'Permudahkan operasi e-dagang anda dengan integrasi Shopify kami.',
        intercomDescription: 'Tingkatkan sokongan pelanggan dengan integrasi Intercom kami.',
        twitterDescription: 'Urus kehadiran media sosial anda secara langsung dari platform kami.',
        sketchDescription: 'Bawa reka bentuk anda menjadi nyata dengan integrasi Sketch kami.',
        directIntegration: 'Integrasi Langsung',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'APA YANG MEREKA KATAKAN',
        title: 'Dengar Dari Pelanggan Kami yang Gembira',
        description: 'Ketahui apa yang pelanggan kami suka tentang ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Pengurus Perniagaan Apple',
          review: 'ACEVA GPT telah mengubah cara kami mengurus perkhidmatan pelanggan. Ia seperti mempunyai pasukan maya yang berfungsi sepanjang masa!',
        },
        tristanEka: {
          position: 'Pengurus Jualan di TechPlus',
          review: 'Ketepatan dan kelajuan ACEVA GPT sangat luar biasa. Ia telah membantu kami mempercepatkan proses jualan dengan ketara.',
        },
        johanMular: {
          position: 'Ketua Operasi di InnovateNow',
          review: 'Chatbot ACEVA GPT bukan sahaja meningkatkan masa tindak balas kami tetapi juga meningkatkan kepuasan pelanggan secara menyeluruh.',
        },
        lindaBrooks: {
          position: 'Pengarah Pemasaran di SkyCorp',
          review: 'Kami telah melihat peningkatan ketara dalam penukaran selepas mengintegrasikan ACEVA GPT ke dalam laman web kami. Ia adalah pengubah permainan!',
        },
      },
    },
    stepSection: {
      title: 'Semua yang anda perlukan untuk chatbot AI anda',
      subTitle: {
        light: 'Mencipta chatbot kini semudah menekan butang. Dengan',
        bold: '3 langkah mudah'
      },
      button: 'Mulakan Perjalanan AI Anda',
      steps: [
        {
          title: 'MASUKKAN',
          subTitle: 'pautan itu'
        },
        {
          title: 'REKA',
          subTitle: 'chatbot anda'
        },
        {
          title: 'TERBITKAN',
          subTitle: 'chatbot anda'
        },
      ]
    },    
    videoSection: {
      title: 'Masih fikir ia rumit?',
      subTitle: 'Tonton tutorial di bawah untuk pemahaman yang lebih baik mengenai Aceva.'
    },
    chatbotSection: {
      title: 'Cuba Aceva sekarang!',
      subTitle: 'Chatbot di bawah adalah salah satu replika yang mungkin ditampilkan di halaman anda. Sila, bertanya apa saja!'
    },
    ctaSection: {
      title: 'Tingkatkan perniagaan anda dengan automasi AI',
      subTitle: 'Cipta AI Chatbot sendiri tanpa sebarang pengekodan, dan melihat ia belajar dan mempertingkatkan bisnes anda dalam masa nyata. Mula menyampaikan perkhidmatan pelanggan yang lebih pantas dan dipersonalisasi hari ini.',
      button: 'Cipta Chatbot anda sekarang'
    },
    faqSection: {
      title: 'Soalan Lazim',
      description: 'Dapatkan jawapan kepada soalan lazim tentang ACEVA.',
      faqs: [
          {
              question: "Apakah Aceva?",
              answer: "Aceva adalah penyelesaian chatbot berkuasa AI yang direka untuk meningkatkan perkhidmatan pelanggan dan memacu pertumbuhan perniagaan. Ia menggunakan teknologi GPT canggih daripada OpenAI, termasuk ChatGPT 4.0 untuk keperluan perniagaan yang lebih kukuh. Untuk perniagaan yang mencari model freemium, Aceva juga mengintegrasikan model AI seperti Mistral. Dengan penyelesaian yang boleh diskalakan, Aceva membantu perniagaan memudahkan sokongan pelanggan dan penjanaan prospek sambil menawarkan harga fleksibel berdasarkan model AI yang dipilih."
          },
          {
              question: "Bolehkah saya mencuba Aceva secara percuma?",
              answer: 'Ya! Cuba model freemium kami dengan <a href="/register" target="_blank" rel="noopener noreferrer">membuka akaun anda</a>.'
          },
          {
              question: "Adakah sukar untuk memasang Aceva di laman web?",
              answer: "Tidak sama sekali. Memasang Aceva adalah proses mudah yang tidak memerlukan kemahiran pengekodan atau kemahiran teknikal yang tinggi. Anda hanya perlu menyalin dan menampal kod snippet ke dalam kod sumber laman web anda selepas menghasilkan chatbot Aceva anda."
          },
          {
              question: "Bagaimana cara membuat chatbot AI saya?",
              answer: "Selepas membuat akaun anda, anda hanya perlu memberikan URL laman web, pautan pangkalan pengetahuan, atau kandungan teks lain untuk secara automatik membina chatbot AI khusus untuk perkhidmatan pelanggan."
          },
          {
              question: "Bolehkah saya menyesuaikan chatbot AI saya?",
              answer: "Ya, anda boleh melakukannya dalam <a href='/pricing' target='_blank' rel='noopener noreferrer'>Pakej Basic dan Pro</a> kami."
          },
          {
            "question": "Bagaimana chatbot AI meningkatkan interaksi dan perkhidmatan pelanggan?",
            "answer": "Chatbot AI menggunakan teknologi AI perbualan canggih untuk memberikan respons segera dan masa nyata di laman web, aplikasi, dan platform pemesejan. Mereka meningkatkan perkhidmatan pelanggan dengan menguruskan FAQ, membantu menyelesaikan masalah, dan memberikan jawapan yang diperibadikan, sambil menangani pelbagai pertanyaan secara serentak. Dengan keupayaan untuk mengurangkan masa menunggu dan beroperasi 24/7, chatbot AI memastikan pengalaman pelanggan yang lancar. Untuk isu yang kompleks, mereka boleh dengan cekap memindahkan saluran interaksi kepada ejen manusia, meningkatkan kepuasan sambil mengurangkan kos operasi untuk perniagaan."
          },          
          {
              question: "Bagaimana chatbot AI membantu pasukan sokongan pelanggan?",
              answer: "Bot berkuasa AI boleh mengautomasikan tugas-tugas berulang seperti menjawab soalan lazim dan menjadualkan temu janji, membolehkan pasukan manusia memberi tumpuan kepada pertanyaan yang lebih kompleks."
          },
          {
              question: "Bagaimana chatbot AI melayakkan dan menukar prospek?",
              answer: "Chatbot boleh berinteraksi dengan pelawat menggunakan soalan bertumpu, mengenal pasti prospek yang berminat, dan menyerahkan prospek berkualiti kepada pasukan jualan."
          },
          {
              question: "Bagaimana chatbot membantu pemasaran?",
              answer: "Chatbot meningkatkan pemasaran dengan mendidik pelawat, mengumpul prospek, mempromosikan tawaran tertentu, dan meningkatkan kadar penukaran."
          },
          {
              question: "Apakah yang menjadikan chatbot AI berharga untuk perniagaan?",
              answer: "Chatbot AI mengoptimumkan operasi dengan mengautomasikan tugas berulang, meningkatkan pengalaman pelanggan, dan meningkatkan peluang jualan."
          },
          {
            "question": "Sejauh mana data yang dikendalikan oleh chatbot AI adalah selamat?",
            "answer": "Chatbot AI mengutamakan keselamatan dengan mematuhi piawaian industri seperti penyulitan data dan pematuhan GDPR. Perniagaan yang menggunakan Aceva boleh yakin bahawa maklumat pelanggan sensitif dilindungi melalui protokol keselamatan yang kukuh. Lihat <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>terma & syarat</a> dan juga <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>dasar privasi</a> kami untuk maklumat lanjut."
          }          
      ]
  },  
  textSection: {
    description: "Aceva ialah solusi AI Chatbot yang direka untuk perniagaan semua skala. Memudahkan interaksi pelanggan, mengautomasikan soalan berulang dan memacu perkembangan perniagaan dengan hanya satu solusi AI."
  },
    footer: {
      bio: "Memperkasakan perniagaan dengan solusi AI masa depan untuk interaksi pelanggan dan pengurusan data yang lancar.",
      product: {
        title: "Produk",
        menus: ["Harga", "Blog", "Hubungi Kami"]
      },
      legal: {
        title: "Sah",
        menus: ["Terma & Syarat", "Dasar Privasi"]
      },
      lastColumn: {
        getInTouch: "Hubungi Kami",
        connectWithUs: "Ikuti kami di"
      },
    },
    // pricing
    pricing: {
      badge: "Harga",
      title: 'Harga berpatutan yang merangkumi semua keperluan anda',
      subTitle: 'Mulai secara percuma. Kami menawar solusi untuk perniagaan pelbagai saiz',
      compareFeatureTitle: "Ciri",
      pricingCta: {
        title: "Perlukan Solusi Chatbot Tersuai?",
        subTitle: "Biar kami membantu anda mereka bentuk chatbot AI yang sesuai untuk mencapai keperluan perniagaan anda.",
        button: "Ubah suai chatbots anda"
      },
      addons: {
        title: "Penambahan",
        subTitle: "Tingkatkan pengalaman anda dengan penambahan kami",
        items: {
          moreWhatsapp: {
            title: "Lebih mesej WhatsApp AI",
            subTitle: "Tambahkan respons dan mesej AI WhatsApp anda",
            price: 5.00,
            unit: "setiap 1000 mesej / bulan",
            credits: "1000 kredit"
          },
          moreAiResponse: {
            title: "Lebih respons AI",
            subTitle: "Tambahkan respons AI chatbot anda",
            price: 5.00,
            unit: "setiap 1000 mesej / bulan",
            credits: "1000 kredit"
          },
          removeBranding: {
            title: "Keluarkan penjenamaan Aceva",
            subTitle: "Keluarkan penjenamaan kami daripada antara muka chatbot anda",
            price: 5.00
          },
          additionalChatbot: {
            title: "Chatbot Tambahan",
            subTitle: "Kembangkan perniagaan anda dengan chatbot AI tambahan",
            price: 5.00,
            unit: "Bayaran sekali untuk setiap chatbot"
          },
          buildForYou: {
            title: "Bina untuk anda",
            subTitle: "Chatbot yang dilatih dan diubah suaikan untuk anda",
            description: "Kami membinakan chatbot untuk anda",
            price: 15.00
          }
        }
      },
      comparePlans: {
        title: "Bandingkan Pelan",
        disclaimer: "Semua harga adalah dalam USD. Tambahan cukai tempatan mungkin akan dikenakan bergantung pada kawasan anda.",
        monthly: "Bulanan",
        yearly: "Tahunan",
        featureNames: [
          "Respons GPT-4o",
          "WhatsApp Mesej Perbualan",
          "Latihan Laman Web",
          "Muat naik fail maksimum",
          "Saiz fail maksimum",
          "AI Chatbot",
          "Penjanaan Prospek",
          "Push Notifikasi",
          "Dashboard",
          "Chatlog Pelanggan",
          "Pengeksportan Data",
          "WhatsApp",
          "Facebook Messenger (Akan Datang)",
          "Telegram (Akan Datang)",
          "Google Calendar",
          "Calendly"
        ],
        freemium: {
          name: "Freemium",
          price: "PERCUMA",
          currency: "USD",
          features: [
            "500 Mistral",
            "0",
            "25",
            "3",
            "5 MB",
            "1",
            false,
            true,
            true,
            true,
            false,
            false,
            true,
            false,
            false,
            false
          ]
        },
        basic: {
          name: "Basic",
          price: 15,
          currency: "USD",
          features: [
            "4,000 mini",
            "2,000",
            "200",
            "20",
            "10 MB",
            "1",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            true
          ]
        },
        pro: {
          name: "Pro",
          price: 49,
          currency: "USD",
          features: [
            "10,000 mini",
            "4,000",
            "500",
            "30",
            "50 MB",
            "3",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        },
        custom: {
          name: "Pro+",
          price: "Hubungi Kami",
          currency: "USD",
          features: [
            "Tersuai",
            "Tersuai",
            "Tersuai",
            "Tersuai",
            "Tersuai",
            "Tersuai",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        }
      },
      pricingPlans: [
        {
          title: "Freemium",
          description: "Cuba untuk 30 hari dan lihat perbezaan!",
          price: {
            isMonthly: "0",
            isYearly: "0"
          },
          features: [
            "500 Respons Mistral/bulan",
            "AI Chatbot",
            "Latihan Halaman Web Komprehensif – sehingga 25 muka surat",
            "Akses dashboard untuk pandangan & analisis",
            "Kemas kini secara langsung dan jejak interaksi pelanggan"
          ],
          isEssential: false
        },
        {
          title: "Basic",
          description: "Sesuai untuk perniagaan yang ingin menggabungkan AI Chatbot.",
          price: {
            isMonthly: "15",
            isYearly: "180"
          },
          features: [
            "4,000 Respons GPT/bulan",
            "1 AI Chatbot", 
            "Latihan Halaman Web Komprehensif – sehingga 200 muka surat",
          ],
          advancedFeatures: [
            "Profil AI Chatbot Tersuai",
            "Akses kepada GPT-4o Mini untuk solusi yang lebih pantas dan bijak",
            "Penjanaan prospek",
            "Integrasi dengan Messenger dan Telegram",
            "Penjadualan janji temu dengan Calendly"
          ],
          featuresTitle: "Semua dari Pelan Freemium, dan:",
          isEssential: true
        },
        {
          title: "Pro",
          description: "Direka untuk perniagaan yang memerlukan ciri lanjutan untuk perkembangan yang pantas.",
          price: {
            isMonthly: "49",
            isYearly: "588"
          },
          features: [
            "10,000 Respons GPT/bulan",
            "3 AI Chatbots",
            "Latihan Halaman Web Komprehensif – sehingga 500 muka surat",
          ],
          advancedFeatures: [
            "Penjenamaan dikeluarkan dari profil AI Chatbot",
            "Pengeksportan Data",
            "Penjadualan diperluas - kini termasuk Google Calendar"
          ],
          featuresTitle: "Semua dari Pelan Basic, ditambah:",
          isEssential: false
        },
        {
          title: "Pro+",
          description: "Solusi yang dapat diskala serta tersuai kepada keperluan anda.",
          price: "Hubungi kami",
          features: [
            "Respons GPT Tersuai/bulan",
            "AI Chatbots Tersuai",
            "Latihan Halaman Web Tersuai",
          ],
          featuresTitle: "Semua dari Pelan Pro, ditambah:",
          advancedFeatures: [
            "Akses dashboard tersuai untuk Pandangan & Analisis",
            "Profil AI Chatbot tersuai",
            "Integrasi tersuai",
            "Akses kepada semua fungsi dashboard"
          ],
          buttonText: "Hubungi kami",
          isEssential: false,
          isCustom: true
        }
      ]
    },
    // Contact Us
    contactUs: {
      title: "Hubungi Kami",
      subTitle: "Jangan ragu untuk menghubungi kami mengikut keperluan anda, dan kami akan memberi respons secepat yang mungkin.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Terma dan Syarat",
      subTitle: "Dengan terus menggunakan platform kami, anda bersetuju dengan terma dan syarat ini.",
      description: {
        billingRefunds: {
          title: "Penagihan & Pengembalian Dana",
          accessCharge: "Kami mengenakan yuran untuk akses ke produk dalam pelan bulanan dan tahunan.",
          noRefunds: "Memandangkan sifat produk kami, kami pada masa ini tidak menawarkan pengembalian dana, sama ada sebahagian atau sepenuhnya.",
          cancelSubscription: "anda boleh membatalkan langganan anda pada bila-bila masa yang anda suka. Kami tidak akan lagi mengenakan sebarang bayaran selepas anda membatalkan langganan.",
          changePricing: "Kami boleh mengubah Berlangganan, dasar Berlangganan, ciri dan had akses pada bila-bila masa.",
        },
        emails: {
          title: "Email",
          content: "Kami boleh menggunakan alamat email anda untuk menghubungi anda berkenaan akaun anda, memberikan anda kemas kini produk, dan memberitahu anda tentang pelbagai inisiatif pemasaran. anda mempunyai pilihan untuk berhenti menerima email ini pada bila-bila masa yang anda mahu.",
        },
        conditions: {
          title: "Syarat",
          content1: "Kami berhak untuk mengubah / meminda dasar pada bila-bila masa.",
          content2: "Dengan terus menggunakan platform kami, anda bersetuju dengan terma dan syarat ini.",
        },
        privacyPolicy: "Dasar Privasi",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Dasar Privasi",
      subTitle: "Dengan terus menggunakan platform kami, anda bersetuju dengan dasar privasi ini",
      description: {
        introduction: {
          title: "Pengenalan",
          content: "Sila semak Dasar Privasi ini ('Dasar') sebelum menggunakan Perkhidmatan kami, termasuk Laman Web, Widget, dan API (seperti yang didefinisikan di bawah), yang dirujuk secara kolektif sebagai 'Perkhidmatan.' Dasar ini mengawal maklumat dan data yang kami kumpul, bagaimana kami menggunakannya, dan bagaimana kami berkongsi. Akses dan penggunaan anda terhadap Perkhidmatan adalah bersyarat kepada persetujuan anda dengan Terma Perkhidmatan ('Terma Perkhidmatan'), yang termasuk terma Dasar ini. ACEVA GPT ('Syarikat') mengendalikan Perkhidmatan. Kami menggunakan data anda untuk meningkatkan Perkhidmatan. Dengan menggunakan Perkhidmatan, anda bersetuju dengan pengumpulan dan penggunaan maklumat sebagaimana yang diterangkan dalam Dasar ini. Kecuali dinyatakan lain dalam Dasar ini, terma yang digunakan di sini mempunyai makna yang sama seperti dalam Terma Perkhidmatan kami."
        },
        definitions: {
          title: "Definisi",
          content: {
            API: "Merujuk kepada antara muka pengaturcaraan aplikasi ACEVA GPT yang terintegrasi dengan perisian Pengguna.",
            Cookies: "Fail kecil yang disimpan pada Peranti anda.",
            Device: "Bermakna komputer atau peranti mudah alih.",
            DataController: "Merujuk kepada ACEVA GPT sebagai entiti yang bertanggungjawab atas data anda.",
            DataProcessors: "Orang yang memproses data bagi pihak ACEVA GPT.",
            DataSubject: "Mana-mana individu hidup yang merupakan subjek Data Peribadi.",
            PersonalData: "Data mengenai individu hidup yang boleh dikenal pasti.",
            Service: "Laman Web, Widget, dan/atau API. Data Penggunaan termasuk data yang dikumpul secara automatik semasa penggunaan Perkhidmatan anda.",
            User: "Individu yang menggunakan Perkhidmatan kami."
          }
        },
        dataController: {
          title: "Pengawal Data",
          content: "Pengawal Data Peribadi anda adalah ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Pengumpulan dan Penggunaan Maklumat",
          content: "Kami mengumpul pelbagai maklumat untuk meningkatkan Perkhidmatan kami."
        },
        typesOfDataCollected: {
          title: "Jenis Data yang Dikumpul",
          personalData: {
            title: "Data Peribadi",
            content: ["Alamat emel", "Nama pertama dan terakhir", "Cookies dan Data Penggunaan"]
          },
          usageData: {
            title: "Data Penggunaan",
            content: "Termasuk maklumat penyemak imbas, alamat IP, dan data teknikal lain."
          }
        },
        useOfData: {
          title: "Penggunaan Data",
          content: "ACEVA GPT menggunakan Data Peribadi yang dikumpulkan untuk pelbagai tujuan, termasuk menyediakan Perkhidmatan, sokongan pelanggan, dan memperbaikinya."
        },
        retentionOfData: {
          title: "Penyimpanan Data",
          content: "Kami mengekalkan Data Peribadi anda sebagaimana yang diperlukan untuk memenuhi kewajipan undang-undang dan untuk tujuan yang diterangkan dalam Dasar ini."
        },
        transferOfData: {
          title: "Pemindahan Data",
          content: "Data anda mungkin dipindahkan ke yurisdiksi lain tetapi akan dilindungi seperti yang diterangkan dalam Dasar ini."
        },
        disclosureOfData: {
          title: "Pendedahan Data",
          content: "Kami mungkin berkongsi Data Peribadi dalam keadaan tertentu sebagaimana yang diterangkan dalam Dasar ini."
        },
        securityOfData: {
          title: "Keselamatan Data",
          content: "Keselamatan data anda penting bagi kami tetapi ingat bahawa tiada kaedah transmisi melalui Internet atau kaedah penyimpanan elektronik adalah 100% selamat. Kami menggunakan perlindungan pentadbiran, teknikal, dan fizikal yang sesuai untuk melindungi Data Peribadi anda."
        },
        yourDataProtectionRights: {
          title: "Hak Perlindungan Data anda",
          content: "Di Malaysia, individu mempunyai hak perlindungan data di bawah Akta Perlindungan Data Peribadi 2010 (PDPA), termasuk hak untuk mengakses, membetulkan, menarik balik persetujuan, meminta portabiliti data, membantah, dan mencari pampasan untuk pelanggaran."
        },
        serviceProviders: {
          title: "Penyedia Perkhidmatan",
          content: "Syarikat pihak ketiga membantu kami menyediakan Perkhidmatan, tetapi mereka diwajibkan untuk tidak mendedahkan atau menggunakan maklumat untuk sebarang tujuan lain."
        },
        analytics: {
          title: "Analitik",
          content: "Kami menggunakan perkhidmatan pihak ketiga seperti Google Analytics untuk analisis."
        },
        payments: {
          title: "Pembayaran",
          content: "Pemproses pembayaran pihak ketiga menangani pembayaran dengan selamat."
        },
        linksToOtherSites: {
          title: "Pautan ke Laman Web Lain",
          content: "Kami tidak bertanggungjawab atas amalan privasi laman web lain."
        },
        childrensPrivacy: {
          title: "Privasi Kanak-Kanak",
          content: "Perkhidmatan kami tidak untuk kanak-kanak di bawah umur 16 tahun."
        },
        limitedUseRequirements: {
          title: "Syarat Penggunaan Terhad",
          content: "Penggunaan dan pemindahan ACEVA ke aplikasi lain dari maklumat yang diterima dari Google APIs akan mengikut",
          content2: "termasuk keperluan Penggunaan Terhad.",
          label: "Dasar Data Pengguna Perkhidmatan API Google",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Pengongsian Data Terhad",
            content: "ACEVA memastikan bahawa sebarang data yang dikongsi dengan alat pihak ketiga, termasuk model AI, adalah terhad kepada apa yang diperlukan untuk fungsi atau perkhidmatan tertentu yang disediakan. Kami tidak berkongsi maklumat pengenalan peribadi dengan pihak ketiga tanpa kebenaran pengguna yang jelas, kecuali jika dikehendaki oleh undang-undang atau selaras dengan dasar privasi kami."
          },
          {
            key: "optOutOptions",
            title: "Pilihan Opt-Out",
            content: "ACEVA menyediakan pengguna dengan pilihan untuk menolak dari beberapa amalan perkongsian data dalam aplikasi. Pengguna boleh mengakses dan menyesuaikan tetapan privasi mereka untuk mengawal perkongsian data mereka dengan alat pihak ketiga. Kami menyediakan arahan yang jelas dan antara muka yang mesra pengguna untuk memudahkan proses ini."
          },
          {
            key: "dataProtectionMeasures",
            title: "Langkah Perlindungan Data",
            content: "ACEVA melaksanakan langkah keselamatan ketat untuk melindungi data pengguna daripada akses, pendedahan, atau penyalahgunaan yang tidak dibenarkan. Kami mematuhi amalan terbaik industri dan secara berkala mengkaji semula dan mengemas kini protokol keselamatan kami untuk memastikan pematuhan dengan piawaian perlindungan data."
          },
          {
            key: "informedConsentExplanation",
            title: "Penjelasan Persetujuan Terinformasi",
            content: "Sebelum sebarang perkongsian data berlaku dengan alat pihak ketiga, pengguna disajikan dengan penjelasan yang jelas dan menyeluruh mengapa data mereka dikongsi dan bagaimana ia akan digunakan. Penjelasan ini disediakan dalam bahasa yang mudah, mengelakkan jargon teknikal, untuk memastikan pengguna memahami sepenuhnya tujuan dan implikasi perkongsian data."
          },
          {
            key: "optInConsentMechanism",
            title: "Mekanisme Persetujuan Opt-In",
            content: "Aplikasi ACEVA melaksanakan mekanisme persetujuan opt-in untuk perkongsian data dengan alat pihak ketiga. Pengguna disajikan dengan kotak semak atau suis togol yang secara eksplisit meminta persetujuan mereka untuk berkongsi data dengan alat pihak ketiga atau model AI yang ditentukan. Mekanisme opt-in ini memastikan perkongsian data hanya berlaku dengan kelulusan eksplisit pengguna."
          },
          {
            key: "clearConsentConfirmation",
            title: "Pengesahan Persetujuan Jelas",
            content: "Setelah pengguna memberikan persetujuan mereka melalui mekanisme opt-in, aplikasi ACEVA memaparkan mesej pengesahan yang jelas mengesahkan bahawa pengguna telah bersetuju secara eksplisit untuk berkongsi data mereka dengan alat pihak ketiga atau model AI yang ditentukan. Mesej pengesahan ini berfungsi sebagai pengakuan akhir persetujuan pengguna sebelum perkongsian data bermula."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Perubahan kepada Dasar Privasi Ini",
          content: "Kami mungkin mengemas kini Dasar ini, jadi sila semak secara berkala."
        },
        contactUs: {
          title: "Hubungi Kami",
          content: "Pertanyaan? Hubungi kami di",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Terma dan Syarat",
          link: "/terms-and-conditions"
        }
      }  
    },

  },
  indonesia: {
    menu: {
      howIsItWorks: 'Cara Kerja',
      demo: 'Coba Gratis!',
      pricing: 'Berlangganan',
      signIn: 'Masuk',
      button: 'Buat Chatbot',
      contactUs: 'Hubungi Kami'
    },  
    videoTitle: "Cara membuat chatbot Anda sendiri",
    landingPageVideoTitle: "Lihat chatbot kami beraksi!",
    singleTitle: "Mengapa Memilih Aceva untuk Bisnis Anda?", 
    titleDetailSection: {
      title: 'Pengetahuan Terbukti: Bagaimana AI Dapat Meningkatkan Layanan Pelanggan Bisnis Anda',
      subTitle: 'Jelajahi bagaimana AI membentuk ulang layanan pelanggan, dengan wawasan dari berbagai studi penelitian. Pelajari mengapa bisnis mengadopsi AI',
      button: 'Jelajahi lebih lanjut'
    },    
    performanceSection: {
      startBuilding: 'Mulai membangun kinerja tinggi',
      title: 'Situs web & kembangkan bisnis Anda dengan cepat.',
      stats: {
        performance: { title: 'Kinerja yang Mengesankan', description: 'Cara lain untuk berkembang dengan cepat' },
        retention: { title: 'Retensi Pelanggan', description: 'Di situs web Anda' },
        growth: { title: 'Pendapatan Pertumbuhan Ekstra', description: 'Dari penjualan Anda' }
      }
    }, 
    heroSectionV3: {
      topTitle: "Layanan Chatbot AI",
      title: ["Chatbot AI Pintar untuk Layanan Pelanggan, Generasi Prospek, dan Pertumbuhan Bisnis"],
      subTitle: "Aceva adalah penyedia chatbot AI Anda, yang mendorong keterlibatan pelanggan dan pertumbuhan bisnis. Solusi kami yang dapat disesuaikan meningkatkan skala dan mengotomatiskan interaksi pelanggan, meningkatkan efisiensi di pasar B2C dan B2B. Biarkan kami mengoptimalkan operasi Anda untuk keberhasilan yang berkelanjutan dan jangka panjang.",
      buttons: {
        getStarted: "Mulai Sekarang",
      }
    },  
    integrationSection: {
      title: "Memudahkan kerja anda: Sambungkan Aceva ke platform kegemaran anda",
      more: "Lebih Banyak lagi akan datang!"
    },      
    accordionSection: {
      subTitle: "Fitur Unggulan",
      title: "Bagaimana chatbot AI dapat membantu?",
      accordions: [
        {
          title: "Dukungan Pelanggan 24/7",
          content:
            "Aceva tidak perlu tidur. Ia memastikan pelanggan anda mendapat sokongan pada bila-bila masa, mengurangkan masa respons, dan meningkatkan kepuasan pelanggan.",
          image: imgone, // Gambar unik untuk item ini
        },
        {
          title: "Otomatisasi Pertanyaan Umum",
          content:
            "Hemat waktu dengan mengotomatisasi pertanyaan umum seperti jam buka toko, detail produk, atau ketersediaan layanan. Biarkan Aceva menangani pertanyaan rutin sementara tim Anda fokus pada masalah yang lebih kompleks.",
          image: imgtwo, // Gambar unik untuk item ini
        },
        {
          title: "Penangkapan dan Kualifikasi Prospek",
          content:
            "Aceva mampu menangkap dan memenuhi syarat prospek secara otomatis dengan mengajukan pertanyaan penting, membantu tim penjualan Anda fokus pada prospek bernilai tinggi, dan meningkatkan tingkat konversi.",
          image: imgthree, // Gambar unik untuk item ini
        },
        {
          title: "Umpan Balik dan Penyelesaian Masalah Instan",
          content:
            "Aceva membantu menyelesaikan masalah pelanggan secara instan dengan memberikan respons cepat, memastikan pelanggan merasa didengar dan puas tanpa waktu tunggu yang lama.",
          image: imgfour, // Gambar unik untuk item ini
        },
        {
          title: "Komunikasi dan Penjadwalan Tanpa Hambatan melalui Integrasi",
          content:
            "Aceva terintegrasi dengan platform pesan dan alat penjadwalan Anda, memungkinkan komunikasi yang lancar dan pemesanan janji temu langsung melalui chatbot.",
          image: imgfive, // Gambar unik untuk item ini
        }
      ]
    },    
    colSection: {
      title: 'Transformasi Website Anda Dengan Percakapan Berbasis AI',
      subTitle: 'Tingkatkan interaksi pelanggan dan konversi dengan solusi chatbot canggih kami.',
      button: 'Mulai Gratis',
      steps: [
      'Layanan Pelanggan Cerdas 24/7',
      'Waktu Respons Super Cepat',
      'Tingkat Konversi Yang Meningkat'
      ]
    },
    heroSection: {
      title: {
        custom: 'Kustomisasi',
        chatGPT: 'ChatGPT',
        forYour: 'Untuk',
        business: 'Bisnis Anda',
      },
      subTitle: "Jawab pertanyaan pengguna secara instan dengan chatbot canggih dari situs anda. Cukup hubungkan melalui data anda, tambahkan sebagai widget, dan chat melalui bot kami",
      button: 'Coba Chatbot Anda Sekarang',
      noCardRequired: 'Tanpa Kartu Kredit!'
    },
    marqueeSection: {
      trustedBusinesses: 'Dipercaya oleh lebih dari 25,000 bisnis',
      logoAlt: 'Logo'
    },
    stats: {
      yearsInnovatingChatbots: 'Tahun berinovasi chatbot',
      aiSpecialists: 'Spesialis AI',
      chatbotsDeployed: 'Chatbot yang diterapkan',
      clientSatisfaction: 'Kepuasan klien',
    },
    leftSideFlexSection: {
      badge: 'Penjanaan prospek',
      title: 'Tambahkan Ejen Jualan Pintar kepada Platform Anda',
      subTitle: "Lihat chatbot kami beraksi",
      button: "Cipta chatbot anda"
    },
    integrateSection: {
      header: {
        powerfulIntegrations: 'INTEGRASI YANG KUAT',
        title: 'Integrasikan dengan Aplikasi Favorit Anda',
        description: 'Tingkatkan alur kerja Anda dengan menghubungkan platform kami dengan alat yang Anda gunakan setiap hari. Integrasi mulus, hasil yang kuat.',
      },
      appIntegrations: {
        gmailDescription: 'Integrasikan alur kerja email Anda dengan mudah di platform kami.',
        slackDescription: 'Hubungkan komunikasi tim Anda langsung ke layanan kami.',
        shopifyDescription: 'Sederhanakan operasi e-commerce Anda dengan integrasi Shopify kami.',
        intercomDescription: 'Tingkatkan dukungan pelanggan dengan integrasi Intercom kami.',
        twitterDescription: 'Kelola kehadiran media sosial Anda langsung dari platform kami.',
        sketchDescription: 'Wujudkan desain Anda dengan integrasi Sketch kami.',
        directIntegration: 'Integrasi Langsung',
      },
    },
    testimonialSection: {
      header: {
        whatTheyAreSaying: 'APA KATA MEREKA',
        title: 'Dengar dari Klien Kami yang Bahagia',
        description: 'Temukan apa yang disukai klien kami tentang ACEVA GPT.',
      },
      testimonials: {
        dainalMathew: {
          position: 'Manajer Bisnis Apple',
          review: 'ACEVA GPT telah mengubah cara kami menangani layanan pelanggan. Ini seperti memiliki tim virtual yang bekerja 24/7!',
        },
        tristanEka: {
          position: 'Manajer Penjualan di TechPlus',
          review: 'Keakuratan dan kecepatan ACEVA GPT luar biasa. Ini sangat membantu kami dalam mempercepat proses penjualan.',
        },
        johanMular: {
          position: 'Kepala Operasional di InnovateNow',
          review: 'Chatbot ACEVA GPT tidak hanya meningkatkan waktu respons kami, tetapi juga meningkatkan kepuasan pelanggan secara keseluruhan.',
        },
        lindaBrooks: {
          position: 'Direktur Pemasaran di SkyCorp',
          review: 'Kami melihat peningkatan konversi yang signifikan setelah mengintegrasikan ACEVA GPT ke situs web kami. Ini benar-benar mengubah permainan!',
        },
      },
    },
    stepSection: {
      title: 'Semua yang anda butuhkan untuk chatbot AI anda',
      subTitle: {
        light: 'Membuat chatbot sekarang semudah menekan tombol. Dengan',
        bold: '3 langkah sederhana'
      },
      button: 'Mulai Perjalanan AI Anda',
      steps: [
        {
          title: 'SISIPKAN',
          subTitle: 'link tersebut'
        },
        {
          title: 'DESAIN',
          subTitle: 'chatbot anda'
        },
        {
          title: 'GUNAKAN',
          subTitle: 'chatbot anda'
        },
      ]
    },    
    videoSection: {
      title: 'Masih bingung gimana caranya?',
      subTitle: 'Tonton tutorial dibawah ini untuk informasi lebih lanjut tentang Aceva.'
    },
    chatbotSection: {
      title: 'Coba Aceva sekarang!',
      subTitle: 'Chatbot dibawah ini adalah uji coba gratis yang akan ditampilkan di halaman anda. Cobalah bertanya!'
    },
    ctaSection: {
      title: 'Tingkatkan bisnis anda ke level berikutnya',
      subTitle: 'Coba chatbot AI anda tanpa kode dan saksikan AI anda belajar dari konten anda secara real-time.',
      button: 'Coba Chatbot Anda Sekarang'
    },
    faqSection: {
      title: 'Pertanyaan yang Sering Diajukan',
      description: 'Dapatkan jawaban atas pertanyaan umum tentang ACEVA.',
      faqs: [
          {
              question: "Apa itu Aceva?",
              answer: "Aceva adalah solusi chatbot berbasis AI yang dirancang untuk meningkatkan layanan pelanggan dan mendorong pertumbuhan bisnis. Menggunakan teknologi GPT canggih dari OpenAI, termasuk ChatGPT 4.0 untuk kebutuhan bisnis yang lebih kuat. Untuk bisnis yang mencari model freemium, Aceva juga mengintegrasikan model AI seperti Mistral. Dengan solusi yang skalabel, Aceva membantu bisnis menyederhanakan dukungan pelanggan dan menghasilkan prospek sambil menawarkan harga fleksibel berdasarkan model AI yang dipilih."
          },
          {
              question: "Apakah saya bisa mencoba Aceva secara gratis?",
              answer: 'Tentu saja! Coba model freemium kami dengan <a href="/register" target="_blank" rel="noopener noreferrer">membuat akun Anda</a>.'
          },
          {
              question: "Apakah sulit untuk menginstal Aceva di situs web?",
              answer: "Tidak sama sekali. Menginstal Aceva adalah proses sederhana yang tidak memerlukan kemampuan coding atau keterampilan teknis lanjutan. Anda hanya perlu menyalin dan menempelkan cuplikan kode ke dalam sumber kode situs web Anda setelah membuat chatbot Aceva Anda."
          },
          {
              question: "Bagaimana cara membuat chatbot AI saya?",
              answer: "Setelah membuat akun, Anda hanya perlu memberikan URL situs web, tautan basis pengetahuan, atau konten teks lainnya untuk secara otomatis membangun chatbot AI khusus untuk layanan pelanggan. Chatbot Anda dapat dilatih ulang dan disesuaikan dengan data tambahan."
          },
          {
              question: "Bisakah saya menyesuaikan chatbot AI saya?",
              answer: "Ya, Anda dapat melakukannya di <a href='/pricing' target='_blank' rel='noopener noreferrer'>Paket Basic dan Pro</a> kami."
          },
          {
            "question": "Bagaimana chatbot AI meningkatkan interaksi dan layanan pelanggan?",
            "answer": "Chatbot AI menggunakan teknologi AI percakapan canggih untuk memberikan respons instan secara real-time di situs web, aplikasi, dan platform pesan. Mereka meningkatkan layanan pelanggan dengan mengelola FAQ, membantu pemecahan masalah, dan memberikan jawaban yang dipersonalisasi, sambil menangani beberapa pertanyaan secara bersamaan. Dengan kemampuannya mengurangi waktu tunggu dan beroperasi 24/7, chatbot AI memastikan pengalaman pelanggan yang lancar. Untuk masalah yang kompleks, mereka dapat dengan efisien mengalihkan interaksi ke agen manusia, meningkatkan kepuasan sambil mengurangi biaya operasional bagi bisnis."
          },
          {
              question: "Bagaimana chatbot AI membantu tim dukungan pelanggan?",
              answer: "Chatbot berbasis AI dapat mengotomatiskan tugas-tugas berulang seperti menjawab pertanyaan umum dan menjadwalkan janji temu, memungkinkan penyelesaian masalah lebih cepat. Mereka menyediakan dukungan 24/7, sehingga tim manusia dapat fokus pada pertanyaan yang lebih kompleks."
          },
          {
              question: "Bagaimana chatbot AI memenuhi syarat dan mengonversi prospek?",
              answer: "Chatbot dapat berinteraksi dengan pengunjung menggunakan pertanyaan yang ditargetkan, mengidentifikasi prospek dengan niat tinggi, dan menyerahkan prospek berkualitas kepada tim penjualan. Mereka juga dapat menjadwalkan pertemuan atau memberikan rekomendasi produk yang dipersonalisasi."
          },
          {
              question: "Bagaimana chatbot membantu pemasaran?",
              answer: "Chatbot meningkatkan pemasaran dengan mengedukasi pengunjung, mengumpulkan prospek, mempromosikan penawaran tertentu, dan meningkatkan konversi melalui dialog interaktif."
          },
          {
              question: "Apa yang membuat chatbot AI berharga untuk bisnis?",
              answer: "Chatbot AI menyederhanakan operasi dengan mengotomatiskan tugas berulang, meningkatkan pengalaman pelanggan, dan meningkatkan peluang penjualan. Ketersediaannya 24/7 membuatnya menjadi solusi yang andal untuk pertumbuhan."
          },
          {
            question: "Seberapa aman data yang dikelola oleh chatbot AI?",
            answer: "Chatbot AI memprioritaskan keamanan dengan mengikuti standar industri seperti enkripsi data dan kepatuhan GDPR. Bisnis yang menggunakan Aceva dapat mempercayai bahwa informasi pelanggan yang sensitif dilindungi melalui protokol keamanan yang kuat. Lihat <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>syarat & ketentuan</a> kami dan juga <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>kebijakan privasi</a> kami untuk informasi lebih lanjut."
          }
      ]
  },
  textSection: {
    description: "Aceva adalah solusi chatbot berbasis AI yang dirancang untuk bisnis dari semua ukuran. Sederhanakan interaksi pelanggan, otomatisasi pertanyaan berulang, dan dorong pertumbuhan bisnis hanya dengan satu solusi AI."
  },
    footer: {
      menu: {
        pricing: 'Harga',
        contactUs: 'Hubungi Kami',
        termsConditions: 'Syarat & Ketentuan',
        privacyPolicy: 'Kebijakan Privasi',
        privacyPolicy: 'Blog',
      }
    },
    // pricing
    pricing: {
      badge: "Harga",
      title: 'Harga terjangkau yang sesuai dengan kebutuhan Anda',
      subTitle: 'Mulai secara gratis. Kami menawarkan solusi untuk bisnis dari segala ukuran',
      compareFeatureTitle: "Ciri",
      pricingCta: {
        title: "Perlukan Solusi Chatbot Tersuai?",
        subTitle: "Biar kami membantu anda mereka bentuk chatbot AI yang sesuai untuk mencapai keperluan perniagaan anda.",
        button: "Ubah suai chatbots anda"
      },
      addons: {
        title: "Add-on",
        subTitle: "Tingkatkan pengalaman Anda dengan add-on kami yang powerful",
        items: {
          moreWhatsapp: {
            title: "Tambah Pesan AI WhatsApp",
            subTitle: "Tingkatkan respons dan pesan AI WhatsApp Anda",
            price: 5.00,
            unit: "per 1000 pesan / bulan",
            credits: "1000 kredit"
          },
          moreAiResponse: {
            title: "Tambah Respons AI",
            subTitle: "Tingkatkan respons chatbot AI Anda",
            price: 5.00,
            unit: "per 1000 respons / bulan",
            credits: "1000 kredit"
          },
          removeBranding: {
            title: "Hapus branding Aceva",
            subTitle: "Hapus branding kami dari interface chatbot Anda",
            price: 5.00
          },
          additionalChatbot: {
            title: "Chatbot Tambahan",
            subTitle: "Kembangkan bisnis Anda dengan chatbot AI tambahan",
            price: 5.00,
            unit: "Biaya satu kali per chatbot"
          },
          buildForYou: {
            title: "Kami bangunkan untuk Anda",
            subTitle: "Chatbot yang dilatih dan disesuaikan untuk Anda",
            description: "Kami mengembangkan chatbot untuk Anda",
            price: 15.00
          }
        }
      },
      comparePlans: {
        title: "Bandingkan Paket",
        disclaimer: "Semua harga dalam USD. Pajak lokal tambahan dapat dikenakan tergantung wilayah Anda.",
        monthly: "Bulanan",
        yearly: "Tahunan",
        featureNames: [
          "Respons GPT-4.0",
          "Pesan Percakapan WhatsApp",
          "Halaman Pelatihan Web",
          "Maks. Upload File",
          "Ukuran File Maks.",
          "Chatbot AI",
          "Generasi Lead",
          "Notifikasi Push",
          "Dasbor",
          "Log Chat Klien",
          "Ekspor Data",
          "WhatsApp",
          "Facebook Messenger",
          "Telegram",
          "Google Calendar",
          "Calendly"
        ],
        freemium: {
          name: "Freemium",
          price: "GRATIS",
          currency: "USD",
          features: [
            "500 Mistral",
            "0",
            "25",
            "3",
            "5 MB",
            "1",
            false,
            true,
            true,
            true,
            false,
            false,
            true,
            false,
            false,
            false
          ]
        },
        basic: {
          name: "Dasar",
          price: 15,
          currency: "USD",
          features: [
            "4,000 mini",
            "2,000",
            "200",
            "20",
            "10 MB",
            "1",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            true
          ]
        },
        pro: {
          name: "Pro",
          price: 49,
          currency: "USD",
          features: [
            "10,000 mini",
            "4,000",
            "500",
            "30",
            "50 MB",
            "3",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        },
        custom: {
          name: "Pro+",
          price: "Hubungi Kami",
          currency: "USD",
          features: [
            "Kustom",
            "Kustom",
            "Kustom",
            "Kustom",
            "Kustom",
            "Kustom",
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true
          ]
        }
      },
      pricingPlans: [
        {
          title: "Gratis",
          description: "Rasakan selama 30 hari dan lihat perbedaannya!",
          price: {
            isMonthly: "0",
            isYearly: "0"
          },
          features: [
            "500 Respons Mistral/bulan",
            "1 AI Chatbot",
            "Pelatihan Halaman Web Komprehensif – hingga 25 halaman",
            "Akses Dasbor untuk Wawasan & Analisis",
            "Pembaruan waktu nyata dan pelacakan interaksi pelanggan"
          ],
          isEssential: false
        },
        {
          title: "Dasar",
          description: "Ideal untuk bisnis yang ingin menggabungkan AI Chatbot.",
          price: {
            isMonthly: "15",
            isYearly: "180"
          },
          features: [
            "4,000 Respons GPT/bulan",
            "1 AI Chatbot", 
            "Pelatihan Halaman Web Komprehensif – hingga 200 halaman",
          ],
          advancedFeatures: [
            "Profil AI Chatbot Kustom",
            "Akses ke GPT-4o Mini untuk solusi yang lebih cepat, lebih cerdas",
            "Generasi prospek",
            "Integrasi dengan Messenger dan Telegram",
            "Penjadwalan janji temu dengan Calendly"
          ],
          featuresTitle: "Semua dari Paket Gratis, ditambah:",
          isEssential: true
        },
        {
          title: "Pro",
          description: "Dirancang untuk bisnis yang membutuhkan fitur lanjutan untuk berkembang dengan cepat.",
          price: {
            isMonthly: "49",
            isYearly: "588",
          },
          features: [
            "10,000 Respons GPT/bulan",
            "3 AI Chatbots",
            "Pelatihan Halaman Web Komprehensif – hingga 500 halaman",
          ],
          advancedFeatures: [
            "Tanda air dihapus dari profil AI Chatbot",
            "Ekspor Data",
            "Penjadwalan yang diperluas - sekarang termasuk Google Calendar"
          ],
          featuresTitle: "Semua dari Paket Dasar, ditambah:",
          isEssential: false
        },
        {
          title: "Pro+",
          description: "Solusi yang dapat diskalakan disesuaikan dengan kebutuhan Anda.",
          price: "Hubungi kami",
          features: [
            "Respons GPT Kustom/bulan",
            "AI Chatbots Kustom",
            "Pelatihan Halaman Web Kustom",
          ],
          featuresTitle: "Semua dari Paket Pro, ditambah:",
          advancedFeatures: [
            "Akses dasbor kustom untuk Wawasan & Analisis",
            "Personalisasi profil AI Chatbot kustom",
            "Integrasi kustom",
            "Akses ke semua fungsi dasbor dan wawasan"
          ],
          buttonText: "Hubungi kami",
          isEssential: false,
          isCustom: true
        }
      ]
    },
    // Contact Us
    contactUs: {
      title: "Hubungi Kami",
      subTitle: "Jangan ragu untuk menghubungi kami kapan pun anda mau, dan kami akan merespon secepat mungkin.",
    },
    // Terms and Conditions
    termsConditions: {
      title: "Syarat dan Ketentuan",
      subTitle: "Dengan melanjutkan penggunaan platform kami, anda menyetujui syarat dan ketentuan ini.",
      description: {
        billingRefunds: {
          title: "Penagihan & Pengembalian Dana",
          accessCharge: "Kami menarik biaya untuk akses ke produk dengan rencana bulanan dan tahunan",
          noRefunds: "Karena sifat produk kami, kami saat ini tidak menawarkan pengembalian dana, baik sebagian atau penuh.",
          cancelSubscription: "anda dapat dengan mudah membatalkan langganan anda kapan saja. Kami tidak akan lagi menarik biaya apa pun setelah anda membatalkan langganan.",
          changePricing: "Kami dapat mengubah harga, kebijakan harga, fitur, dan pembatasan akses kapan saja.",
        },
        emails: {
          title: "Email",
          content: "Kami dapat menggunakan alamat email anda untuk menghubungi anda tentang akun anda, memberikan pembaruan produk, dan memberitahu anda tentang berbagai inisiatif pemasaran. anda memiliki opsi untuk berhenti menerima email ini kapan pun anda inginkan.",
        },
        conditions: {
          title: "Ketentuan",
          content1: "Kami berhak untuk mengubah/amendemen kebijakan kapan saja.",
          content2: "Dengan melanjutkan penggunaan platform kami, anda menyetujui syarat dan ketentuan ini.",
        },
        privacyPolicy: "Kebijakan Privasi",
      },
    },
    // Privacy Policy
    privacyPolicy: {
      title: "Kebijakan Privasi",
      subTitle: "Dengan melanjutkan penggunaan platform kami, anda menyetujui kebijakan privasi ini",
      description: {
        introduction: {
          title: "Pengantar",
          content: "Mohon tinjau Kebijakan Privasi ini ('Kebijakan') sebelum menggunakan Layanan kami, termasuk Situs Web, Widget, dan API (seperti yang didefinisikan di bawah), yang secara kolektif disebut sebagai 'Layanan.' Kebijakan ini mengatur informasi dan data yang kami kumpulkan, bagaimana kami menggunakannya, dan bagaimana kami membagikannya. Akses anda terhadap dan penggunaan Layanan dikondisikan pada persetujuan anda dengan Syarat Layanan ('Syarat Layanan'), yang mencakup ketentuan dari Kebijakan ini. ACEVA GPT ('Perusahaan') mengoperasikan Layanan. Kami menggunakan data anda untuk meningkatkan Layanan. Dengan menggunakan Layanan, anda setuju dengan pengumpulan dan penggunaan informasi seperti yang dijelaskan dalam Kebijakan ini. Kecuali didefinisikan lain dalam Kebijakan ini, istilah yang digunakan di sini memiliki arti yang sama seperti dalam Syarat Layanan kami."
        },
        definitions: {
          title: "Definisi",
          content: {
            API: "Merujuk pada antarmuka pemrograman aplikasi ACEVA GPT yang terintegrasi dengan perangkat lunak Pengguna.",
            Cookies: "Berkas kecil yang disimpan pada Perangkat anda.",
            Device: "Berarti komputer atau perangkat mobile.",
            DataController: "Merujuk pada ACEVA GPT sebagai entitas yang bertanggung jawab atas data anda.",
            DataProcessors: "Mereka yang memproses data atas nama ACEVA GPT.",
            DataSubject: "Setiap individu hidup yang menjadi subjek Data Pribadi.",
            PersonalData: "Data tentang individu hidup yang dapat diidentifikasi.",
            Service: "Situs Web, Widget, dan/atau API. Data Penggunaan mencakup data yang dikumpulkan secara otomatis selama anda menggunakan Layanan.",
            User: "Individu yang menggunakan Layanan kami."
          }
        },
        dataController: {
          title: "Pengendali Data",
          content: "Pengendali Data Pribadi anda adalah ACEVA GPT."
        },
        informationCollectionUse: {
          title: "Pengumpulan dan Penggunaan Informasi",
          content: "Kami mengumpulkan berbagai informasi untuk meningkatkan Layanan kami."
        },
        typesOfDataCollected: {
          title: "Jenis Data yang Dikumpulkan",
          personalData: {
            title: "Data Pribadi",
            content: ["Alamat email", "Nama depan dan belakang", "Cookies dan Data Penggunaan"]
          },
          usageData: {
            title: "Data Penggunaan",
            content: "Termasuk informasi peramban, alamat IP, dan data teknis lainnya."
          }
        },
        useOfData: {
          title: "Penggunaan Data",
          content: "ACEVA GPT menggunakan Data Pribadi yang dikumpulkan untuk berbagai tujuan, termasuk menyediakan Layanan, dukungan pelanggan, dan meningkatkannya."
        },
        retentionOfData: {
          title: "Penyimpanan Data",
          content: "Kami menyimpan Data Pribadi anda sesuai kebutuhan untuk memenuhi kewajiban hukum dan untuk tujuan yang dijelaskan dalam Kebijakan ini."
        },
        transferOfData: {
          title: "Transfer Data",
          content: "Data anda dapat ditransfer ke yurisdiksi lain namun akan dilindungi seperti yang dijelaskan dalam Kebijakan ini."
        },
        disclosureOfData: {
          title: "Pengungkapan Data",
          content: "Kami dapat membagikan Data Pribadi dalam situasi tertentu seperti yang dijelaskan dalam Kebijakan ini."
        },
        securityOfData: {
          title: "Keamanan Data",
          content: "Keamanan data anda penting bagi kami tetapi ingat bahwa tidak ada metode transmisi melalui Internet atau metode penyimpanan elektronik yang 100% aman. Kami menggunakan perlindungan administratif, teknis, dan fisik yang sesuai untuk melindungi Data Pribadi anda."
        },
        yourDataProtectionRights: {
          title: "Hak Perlindungan Data anda",
          content: "Di Malaysia, individu memiliki hak perlindungan data di bawah Undang-Undang Perlindungan Data Pribadi 2010 (PDPA), termasuk hak untuk mengakses, memperbaiki, menarik persetujuan, meminta portabilitas data, menolak, dan mencari kompensasi untuk pelanggaran."
        },
        serviceProviders: {
          title: "Penyedia Layanan",
          content: "Perusahaan pihak ketiga membantu kami menyediakan Layanan, tetapi mereka diwajibkan untuk tidak mengungkapkan atau menggunakan informasi untuk tujuan lain."
        },
        analytics: {
          title: "Analitik",
          content: "Kami menggunakan layanan pihak ketiga seperti Google Analytics untuk analisis."
        },
        payments: {
          title: "Pembayaran",
          content: "Prosesor pembayaran pihak ketiga menangani pembayaran secara aman."
        },
        linksToOtherSites: {
          title: "Tautan ke Situs Lain",
          content: "Kami tidak bertanggung jawab atas praktik privasi dari situs web lain."
        },
        childrensPrivacy: {
          title: "Privasi Anak",
          content: "Layanan kami tidak ditujukan untuk anak di bawah 16 tahun."
        },
        limitedUseRequirements: {
          title: "Persyaratan Penggunaan Terbatas",
          content: "Penggunaan dan transfer ACEVA ke aplikasi lain dari informasi yang diterima dari Google APIs akan mengikuti",
          content2: "termasuk persyaratan Penggunaan Terbatas.",
          label: "Kebijakan Data Pengguna Layanan API Google",
          link: "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
        },
        limitedUseRequirementsDetails: [
          {
            key: "limitedDataSharing",
            title: "Pembagian Data Terbatas",
            content: "ACEVA memastikan bahwa setiap data yang dibagi dengan alat pihak ketiga, termasuk model AI, terbatas pada apa yang diperlukan untuk fungsi atau layanan spesifik yang disediakan. Kami tidak membagikan informasi identifikasi pribadi dengan pihak ketiga tanpa persetujuan pengguna yang eksplisit, kecuali jika diwajibkan oleh hukum atau sesuai dengan kebijakan privasi kami."
          },
          {
            key: "optOutOptions",
            title: "Opsi Opt-Out",
            content: "ACEVA menyediakan pengguna dengan opsi untuk memilih keluar dari beberapa praktik berbagi data dalam aplikasi. Pengguna dapat mengakses dan menyesuaikan pengaturan privasi mereka untuk mengontrol pembagian data mereka dengan alat pihak ketiga. Kami menyediakan instruksi yang jelas dan antarmuka yang ramah pengguna untuk memfasilitasi proses ini."
          },
          {
            key: "dataProtectionMeasures",
            title: "Langkah Perlindungan Data",
            content: "ACEVA menerapkan langkah keamanan yang ketat untuk melindungi data pengguna dari akses, pengungkapan, atau penyalahgunaan yang tidak sah. Kami mengikuti praktik terbaik industri dan secara berkala meninjau dan memperbarui protokol keamanan kami untuk memastikan kepatuhan terhadap standar perlindungan data."
          },
          {
            key: "informedConsentExplanation",
            title: "Penjelasan Persetujuan yang Terinformasi",
            content: "Sebelum pembagian data terjadi dengan alat pihak ketiga, pengguna disajikan dengan penjelasan yang jelas dan komprehensif mengapa data mereka dibagi dan bagaimana akan digunakan. Penjelasan ini disediakan dalam bahasa yang sederhana, menghindari jargon teknis, untuk memastikan pengguna sepenuhnya memahami tujuan dan implikasi dari pembagian data."
          },
          {
            key: "optInConsentMechanism",
            title: "Mekanisme Persetujuan Opt-In",
            content: "Aplikasi ACEVA menerapkan mekanisme persetujuan opt-in untuk berbagi data dengan alat pihak ketiga. Pengguna disajikan dengan kotak centang atau sakelar toggle yang secara eksplisit meminta persetujuan mereka untuk berbagi data dengan alat pihak ketiga atau model AI yang ditentukan. Mekanisme opt-in ini memastikan bahwa pembagian data hanya terjadi dengan persetujuan eksplisit dari pengguna."
          },
          {
            key: "clearConsentConfirmation",
            title: "Konfirmasi Persetujuan yang Jelas",
            content: "Setelah pengguna memberikan persetujuan mereka melalui mekanisme opt-in, aplikasi ACEVA menampilkan pesan konfirmasi yang jelas yang mengonfirmasi bahwa pengguna telah secara eksplisit setuju untuk berbagi data mereka dengan alat pihak ketiga atau model AI yang ditentukan. Pesan konfirmasi ini berfungsi sebagai pengakuan akhir dari persetujuan pengguna sebelum pembagian data dimulai."
          }
        ],
        changesToPrivacyPolicy: {
          title: "Perubahan pada Kebijakan Privasi Ini",
          content: "Kami dapat memperbarui Kebijakan ini, jadi mohon untuk memeriksanya secara berkala."
        },
        contactUs: {
          title: "Hubungi Kami",
          content: "Pertanyaan? Hubungi kami di",
          email: "info@wipdata.com"
        },
        termsAndConditionsLink: {
          text: "Syarat dan Ketentuan",
          link: "/terms-and-conditions"
        }
      }
    },
  },
};


export default Translations;
