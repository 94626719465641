import React, { useState } from 'react';
import { InlineWidget } from "react-calendly";
import { FaTelegramPlane, FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import textLogo from '../../images/aceva.png';

const MySwal = withReactContent(Swal);

function CalendlyBot({ config, setConfig, promptLists, mask, isFinal }) {
  const [toggle, setToggle] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleShowCalendly = () => {
    setShowCalendly(true);
  };

  return (
    <>
      <section className='chatbot-section calendly-bot-section'>
        <div className="chatbot-config-widget-wrapper">
          <div className="chatbot-widget active config-bot-widget">
            <div className="chatbot-widget-header bot-config-header" style={{ background: config?.brandColor }}>
              <div className="chatbot-widget-agent-precence">
                <div className="chatbot-widget-header-left">
                  <div className="chatbot-recipient-avatar">
                    <img src={config?.logo ? config?.logo : textLogo} alt="agent avatar" />
                    <div className="online-status"></div>
                  </div>
                  <div className="chatbot-widget-recipient-details">
                    <p>Chat with</p>
                    <h3>{config?.botName || 'ACEVA GPT'}</h3>
                  </div>
                </div>
                <div className="chatbot-widget-header-right">
                  <div className='chatbot-widget-header-right-options'>
                    <div className="chatbot-widget-minimize refresh-icon" onClick={handleToggle}>
                      <FaTimes />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={isFinal ? "chat-conversation-wrapper bot-message-wrapper bot-final-msg-body" : "chat-conversation-wrapper bot-message-wrapper"}>
              <div className={mask ? "message-body chatbot-message-body mask-blur" : "message-body chatbot-message-body"}>
                <ul>
                  <li>
                    <img className='agent-logo' src={textLogo} alt="bot" />
                    <div className="typing-wrap message-bubble-agent ">
                      <p className='message-bubble-agent'>Hi, I am your assistant. How may I help you?</p>
                    </div>
                  </li>
                  
                  {/* Calendly Widget */}
                    {showCalendly && (
                      <InlineWidget
                        url="https://calendly.com/borhan365"
                      />
                    )}
                </ul>
              </div>
            </div>
            <div className="chatbot-footer-container">
              <div className="chatbot-footer">
                <div className="chatbot-footer-input-box mini-bot-footer">
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleShowCalendly();
                  }}>
                    <input value={'sdfsdf'} type="text" placeholder={config?.placeholder || 'Start conversation...'} />
                    <button type='submit' className='chatbot-send-message-button chatbot-config-footer-btn' style={{ background: config?.brandColor }}>
                      <FaTelegramPlane />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default CalendlyBot;
