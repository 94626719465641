import React from 'react'
import { Link } from 'react-router-dom'

function MarketplaceBreadcrumb({title}) {
  return (
    <>
      <div className="breadcrumb">
        <li><Link to="/dashboard">Dashboard</Link></li>
        <li>/</li>
        <li><Link to="/marketplace">Marketplace</Link></li>
        <li>/</li>
        <li>{title}</li>
      </div>
    </>
  )
}

export default MarketplaceBreadcrumb