import React, { useEffect, useRef, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import { BsListStars, BsThreeDotsVertical } from 'react-icons/bs'
import { FaRobot, FaTelegramPlane, FaTimes } from 'react-icons/fa'
import { HiMiniRocketLaunch } from 'react-icons/hi2'
import textLogo from '../images/aceva.png'

// product images

import axios from 'axios'
import { toast } from 'react-hot-toast'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { apiUrl } from '../Variables'
import Header from '../components/Header'
import Skeleton from 'react-loading-skeleton'
import PlainHeader from '../components/PlainHeader'
const MySwal = withReactContent(Swal)


function BotDemoScreen({QueryParams, setQueryParams, toggleLoggedInState}) {

  const [searchParams, setSearchParams] = useSearchParams()
  const botId = searchParams.get('botId');
  
  const navigate = useNavigate();
  const chatRef = useRef(null)
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  const [toggle, setToggle] = useState(false); 
  const [createSubmitSuccess, setCreateSubmitSuccess] = useState(false)
  const [ip, setIp] = useState(localStorage.getItem('ip'))
  const [error, setError] = useState('')
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [botLoading, setBotLoading] = useState(false)
  const [chat, setChat] = useState({...JSON.parse(localStorage.getItem('botConfig'))})
  const [botInfo, setBotInfo] = useState({...JSON.parse(localStorage.getItem('botInfo'))})
  const [message, setMessage] = useState('')
  const [promptLists, setPromptLists] = useState(JSON.parse(localStorage.getItem('menuList')) || []);
  const [billingData, setBillingData] = useState({})

  const handleToggle = (e) => {
    setToggle(!toggle);
    setQueryParams('')
  }

   const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    // create chatbot 
    await axios.post(`${apiUrl}/chatbot`, botInfo, { headers }).then(async response => {
      console.log('bot response', response)

      // create config
      await axios.post(`${apiUrl}/botConfig`, {configData: chat, chatbotId: response?.data?._id}, { headers }).then(configResponse => {

        console.log('config response', configResponse)
      }).catch(error => {
        console.log('config error:', error)
      })
      const dataObj = {
        chatbotId: response?.data?._id,
        menues: promptLists 
      };
      //create bot menues
      await axios.post(`${apiUrl}/botMenu`, dataObj, {headers}).then(res => {
        console.log(res)
        setLoading(false)
      }).catch(err => {
        console.log(err)
        setLoading(false)
      })
      localStorage.removeItem("botConfig")
      localStorage.removeItem("botInfo")
      localStorage.removeItem("menuList")
      toast.success('Bot created successfully');
      navigate('/chatbot-list')
    }).catch(error => {
      setLoading(false)
      console.log('chatbot error', error)
      toast.success(error.response.data)
    })

  };

// get all chats
useEffect(() => {

  if (!userInfo || !userInfo.token) {
    console.error('User token not found.');
    return;
  }

  // Add headers to include JWT token
  const headers = {
    Authorization: `Bearer ${userInfo.token}`,
  };
  const featchData = async () => {
    try {
      const {data} = await axios.get(`${apiUrl}/botConfig/${botId}`, {headers})
      setChat(data[0]?.configData)
    } catch (error) {
      console.log('scrap list fetching fail:', error)
      toast.error(`Error: ${error?.message || error?.response?.data}`)
    }
    await axios.get(`${apiUrl}/botMenu/${botId}`, {headers}).then(res => {
      console.log(res)
      setPromptLists(res.data[0].menues)
      setBotLoading(false)
    }).catch(err => {
      setBotLoading(false)
      console.log(err)
    })
    await axios.get(`${apiUrl}/billing/addonBillingList`, {headers}).then(res => {
      console.log('addonBillingList', res)
      setBillingData(res.data)
    }).catch(err => {
      console.log('addonBillingList', err.response)
    })
  }
  if (botId) {
    setBotLoading(true)
    featchData()
  }
  
},[botId])

  return (
    <>
      <PlainHeader toggleLoggedInState={toggleLoggedInState}/>
      <section className='chatbot-section'>
      {
        botLoading ? <div style={{display:'flex', padding:'30px', justifyContent:'center'}}>
            <div>
              <Skeleton width={400} height={500} />
            </div>
            <div style={{marginLeft:'30px', marginTop:'200px'}}>
              <Skeleton width={300} height={50} />
            </div>
          </div> : <div className="chatbot-config-widget-wrapper botchat-demo-wrapper">

          <div className="chatbot-widget active config-bot-widget bot-demo-widget">
              
              {/* chat header */}
              <div className="chatbot-widget-header bot-config-header" style={{background: chat?.brandColor}}>
  
                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">
  
                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    <div className="chatbot-recipient-avatar">
                        <img src={chat?.logo ? chat?.logo : textLogo} alt="agent avatar" />
                        {/* https://play-lh.googleusercontent.com/ZG6-bDrdxvKV9lwg_aQ0H90VBHdYApCBaOn4VL33fSnKYKigGsgK5xe7E_rCYra1fDag */}
                        <div className="online-status"></div>
                    </div>
  
                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details">
                      {
                        billingData?.addOnBilling?.map(it => it.addOn.name).includes("Remove-Powered-by-ACEVA-GPT") ? <p>Chat with</p> :   <p>Powered by 
                        <Link to="https://www.aceva.com.my/" target="_blank" className='company-name'> ACEVA GPT </Link>
                      </p>
                      }
                      <h3>
                        {chat?.botName || 'ACEVA GPT'}
                      </h3>
                    </div>
                  </div>
  
                  {/* agent right side options */}
                  <div className="chatbot-widget-header-right">
  
                    <div className='chatbot-widget-header-right-options'>
  
                      {/* minimize */}
                      <div className="chatbot-widget-minimize refresh-icon" onClick={async () => {      
                        if (language === null) {
                          setLanguage('en')
                        } else {
                          setLanguage(null)
                        }             
                        }}>
                        <BsThreeDotsVertical />
                      </div>
  
                      {/* cross icon for mobile */}
                      <div className="chatbot-widget-minimize refresh-icon desktop-hide" onClick={handleToggle}>
                        <FaTimes/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper bot-message-wrapper bot-demo-message-wrapper">
                {/* message body */}
                <div className="message-body chatbot-message-body">
                  <ul>
                    <li>
                      <img className='agent-logo' src={chat?.logo || textLogo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <p className='message-bubble-agent'>{chat?.welcomeMessage}</p>
                      </div>
                    </li> 
  
                    {/* <li className="message-bubble-visitor date-bubble-item chatbot-config-bubble-user">
                      <span className=''>Thanks</span>
                      <div className="messageTimestamp fade-enter-done">Today: 25 minutes ago</div>
                      <div className="messageTimestamp fade-enter-done"></div>
                    </li>
  
                    <li className="message-bubble-visitor date-bubble-item chatbot-config-bubble-user">
                      <span className=''>I want to know more about your service and products</span>
                      <div className="messageTimestamp fade-enter-done">Today: 30 minutes ago</div>
                      <div className="messageTimestamp fade-enter-done"></div>
                    </li> */}
                      
                    {/* <li>
                      <img className='agent-logo' src={textLogo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <span className="tying">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>  */}
                    
                    <li className='ref-li' ref={chatRef}></li>
                  </ul>
                </div>
              </div>
              {/* chat footer */}
              <div className="chatbot-footer-container">
                {/* footer menu options */}
                <div className='message-auto-suggest-buttons'>
                      {
                        promptLists?.map(i =>  <div className='botdemo-menu-option-item' style={{backgroundColor: chat?.brandColor}}>
                        {i.title}
                      </div>)
                      }
                  </div>
                <div className="chatbot-footer">
                  <div className="chatbot-footer-input-box mini-bot-footer">
                    <form>
                      <input autoFocus value={message} type="text" placeholder={chat?.placeholder} />
                      <button type='submit' className='chatbot-send-message-button chatbot-config-footer-btn' style={{background: chat?.brandColor}}>
                        {loading ? <h6 style={{color:'white', fontSize:'10px'}}>sending...</h6> : <FaTelegramPlane />}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          {/* bot launch */}
          {
            botId ? (
              <Link to="/chatbot-list">
                <button className="bot-launch-btn" style={{background: chat?.brandColor}}>
                  <BsListStars />
                  <span>Back to all chatbots</span>
                </button>
              </Link>
            ) : (
            <div className="bot-launch-wrap">
              <h3>Step into the future of customer support 
  Your seamless experience awaits!</h3>
              <button className="bot-launch-btn" onClick={handleSubmit}>
                <HiMiniRocketLaunch />
                <span>{loading ? 'Publishing...' : 'Publish Chatbot'}</span>
              </button>
            </div>
            )
          }
      </div>}
      </section>
    </>
  )
}

export default BotDemoScreen