import { Bot, ChevronDown, MessageCircle, Trash2, Zap } from 'lucide-react';
import { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import Translations from '../../context/Translations';

const AddOnCard = ({ icon: Icon, title, description, price, unit, credits, color, children, onAdd }) => (
  <div className="bg-white rounded-xl shadow-sm p-6 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-gray-200 relative">
    <div className={`inline-flex items-center justify-center w-12 h-12 rounded-full z-20 mb-6 ${color}`}>
      <Icon className="w-6 h-6 text-white" />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 mb-5 font-medium text-base">{description}</p>
    {children}
    <div className="flex justify-between items-center mt-5 pt-5 border-t border-gray-100">
      <span className="text-2xl font-bold">${price}</span>
      {unit && <span className="text-sm text-gray-600">{unit}</span>}
    </div>
  </div>
);

const CustomSelect = ({ options, value, onChange, credits }) => (
  <div className="relative">
    <select
      value={value}
      onChange={onChange}
      className="appearance-none w-full bg-gray-50 border border-gray-200 rounded-md py-3 px-4 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-brand-color focus:border-transparent transition-all duration-200"
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option} {credits}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <ChevronDown className="w-5 h-5" />
    </div>
  </div>
);

function Addons() {
  const { language } = useLanguage();
  const t = Translations[language];
  const addonsT = t.pricing.addons;

  const [extraWhatsapp, setExtraWhatsapp] = useState("1000");
  const [extraMessages, setExtraMessages] = useState("1000");

  const creditOptions = ["1000", "2000", "3000", "4000", "5000"];

  const standardPricing = {
    "1000": 5,
    "2000": 10,
    "3000": 15,
    "4000": 20,
    "5000": 25,
  };

  return (
    <section className='px-4 py-16 bg-gradient-to-br from-gray-50 to-gray-100'>
      <div className="container mx-auto">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-4 text-gray-800">
          {addonsT.title}
        </h2>
        <p className="text-xl text-center mb-12 text-gray-600">
          {addonsT.subTitle}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <AddOnCard
            icon={MessageCircle}
            title={addonsT.items.moreWhatsapp.title}
            description={addonsT.items.moreWhatsapp.subTitle}
            price={standardPricing[extraWhatsapp].toFixed(2)}
            unit={addonsT.items.moreWhatsapp.unit}
            color="bg-green-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">
              ${addonsT.items.moreWhatsapp.price.toFixed(2)} {addonsT.items.moreWhatsapp.unit}
            </p>
            <CustomSelect
              options={creditOptions}
              value={extraWhatsapp}
              onChange={(e) => setExtraWhatsapp(e.target.value)}
              credits={addonsT.items.moreWhatsapp.credits}
            />
          </AddOnCard>

          <AddOnCard
            icon={Zap}
            title={addonsT.items.moreAiResponse.title}
            description={addonsT.items.moreAiResponse.subTitle}
            price={standardPricing[extraMessages].toFixed(2)}
            unit={addonsT.items.moreAiResponse.unit}
            color="bg-yellow-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">
              ${addonsT.items.moreAiResponse.price.toFixed(2)} {addonsT.items.moreAiResponse.unit}
            </p>
            <CustomSelect
              options={creditOptions}
              value={extraMessages}
              onChange={(e) => setExtraMessages(e.target.value)}
              credits={addonsT.items.moreAiResponse.credits}
            />
          </AddOnCard>

          <AddOnCard
            icon={Trash2}
            title={addonsT.items.removeBranding.title}
            description={addonsT.items.removeBranding.subTitle}
            price={addonsT.items.removeBranding.price.toFixed(2)}
            color="bg-red-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium py-2"></p>
          </AddOnCard>

          <AddOnCard
            icon={Bot}
            title={addonsT.items.additionalChatbot.title}
            description={addonsT.items.additionalChatbot.subTitle}
            price={addonsT.items.additionalChatbot.price.toFixed(2)}
            unit={addonsT.items.additionalChatbot.unit}
            color="bg-blue-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">
              {addonsT.items.additionalChatbot.unit}
            </p>
          </AddOnCard>

          <AddOnCard
            icon={Bot}
            title={addonsT.items.buildForYou.title}
            description={addonsT.items.buildForYou.subTitle}
            price={addonsT.items.buildForYou.price.toFixed(2)}
            color="bg-blue-500"
          >
            <p className="text-sm text-gray-600 mb-4 font-medium">
              {addonsT.items.buildForYou.description}
            </p>
          </AddOnCard>
        </div>
      </div>
    </section>
  );
}

export default Addons;