import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsX } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppContext } from '../context/AppContext';
import ErrorText from './ErrorText';


function CreateUserModel({createUser,setResult, setCreateUser}) {

  const [message, setMessage] = useState(false)
  const {register, handleSubmit, formState: {errors}} = useForm();
  const {userInfo} = useAppContext();
  const [createError, setCreateError] = useState("")
  const [uploading, setUploading] = useState(false)
  const [thumb, setThumb] = useState('')

  const {apiUrl} = useAppContext()

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post(`http://localhost:5000/api/upload`, formData, config)

      setThumb(data)
      console.log(thumb)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const onSubmit = async (data) => {
    setMessage(false)
    setResult(false)
    const modifiedData = await {...data, profile:thumb}
    
    try {
      const res = await axios.post(
        `${apiUrl}/user/register`, 
        modifiedData, 
        {headers: {'Content-Type': 'application/json'}});
      setResult(true)
      console.log(modifiedData)
      setMessage(true)
      setCreateUser(!createUser)

      document.getElementById("form").reset()
      
    } catch(error) {
      setCreateError(error.response
        ? error.response.data.message
        : error.message)
    }
    
  }

  useEffect(() => {
    createError && toast(createError, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  },[message, createUser, createError])

  return (
    <>
      <ToastContainer />
      <div className={createUser ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form" id='form'>

            <div className="form-title">
              <h2>Create user</h2>
              <p>Please fill the form to create account.</p>
              <BsX onClick={() => setCreateUser(!createUser)} className='time-icon' />
            </div>

            {/* name */}
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input className={errors.name?.message && "error-input"} {...register('name', {required: "Name is required!"})} type="text" id='name' placeholder='Full name' />
              <ErrorText error={errors.name?.message} />
            </div>

            {/* email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className={errors.email?.message && "error-input"} {...register('email', {required: "Email is required!"})} type="email" placeholder='Email address' id='email' />
              <ErrorText error={errors.email?.message} />
            </div>

            <div className="form-row">

              {/* Phone number */}
              <div className="form-group">
                <label htmlFor="phone">Phone number</label>
                <input className={errors.phone?.message && "error-input"} {...register('phone', {required: "Phone number is required!"})} type="number" placeholder='Phone number' id='Phone number' />
                <ErrorText error={errors.phone?.message} />
              </div>

              {/* Password */}
              <div className="form-group">
                <label htmlFor="Password">Password</label>
                <input className={errors.password?.message && "error-input"} {...register('password', {required: "Password is required!"})} type="password" placeholder='Password' id='Password' />
              <ErrorText error={errors.password?.message} />
              </div>
            </div>

            {/* role and department */}
            <div className="form-row">
              {/* Role */}
              <div className="form-group">
                <label htmlFor="role">Role</label>
                <select 
                  // onChange={(e) => {
                  //   registerState.role = e.target.value
                  //   setRegisterState({...registerState})
                  // }}
                  {...register('role', {required: "role is required!"})}
                  name="role"
                  
                  id="role">
                  <option value="admin">Admin</option>
                  <option value="editor">Editor</option>
                  <option value="user">User</option>
                </select>
              </div>

              {/* Department */}
              <div className="form-group">
                <label htmlFor="department">Department</label>
                <select
                {...register('department', {required: "Department is required!"})}
                
                id="department">
                  <option value="sales">Sales</option>
                  <option value="human-resource">Human Resource</option>
                  <option value="account">Account</option>
                  <option value="finance">Finance</option>
                  <option value="audit">Audit</option>
                </select>
              </div>
            </div>

            {/* show error */}
            {/* <div className="form-group">
            <label>Upload profile picture</label>
            <label>
              <div className="user-profile-header-thumb create-user-profile">
                  <img className='create-user-profile-img' src={thumb ? `${rootUrl}${thumb}` : logo} alt="profile photo" />
                  <div className="user-update-profile create-user-profile-update-icon-wrapper">
                    <input type="file" id='image-file' {...register('profile', {required: "Profile photo is required!"})} name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                    <MdCloudUpload className='user-update-icon' />
                  </div>
                </div>
              </label>
            </div> */}

            <button type='submit' className='form-button'>
              Create User
            </button>
          </form>
      </div>
    </>
  )
}

export default CreateUserModel