import { Check, X } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from '../../context/LanguageContext';
import Translations from '../../context/Translations';

export default function PricingCompareSection() {
  const { language } = useLanguage();
  const t = Translations[language];
  const comparePlansT = t.pricing.comparePlans;

  const [isYearly, setIsYearly] = useState(false);

  // Dynamically construct plans array using translations
  const plans = [
    { name: comparePlansT.freemium.name, color: "bg-blue-200", textColor: "text-blue-800", monthlyPrice: comparePlansT.freemium.price, yearlyPrice: comparePlansT.freemium.price },
    { name: comparePlansT.basic.name, color: "bg-green-200", textColor: "text-green-800", monthlyPrice: `$${comparePlansT.basic.price}`, yearlyPrice: `$${comparePlansT.basic.price * 11}` },
    { name: comparePlansT.pro.name, color: "bg-yellow-200", textColor: "text-yellow-800", monthlyPrice: `$${comparePlansT.pro.price}`, yearlyPrice: `$${comparePlansT.pro.price * 11}` },
    { name: comparePlansT.custom.name, color: "bg-brand-color-light", textColor: "text-red-800", monthlyPrice: <Link
      to="/contact-us"
      className="bg-brand-color text-white font-medium px-4 py-2 !rounded hover:bg-opacity-90 transition-colors hover:text-white block text-base text-center" style={{border: '1px solid transparent'}}
    >
      {t.menu.contactUs}
    </Link>, yearlyPrice: <Link
      to="/contact-us"
      className="bg-brand-color text-white font-medium px-4 py-2 !rounded hover:bg-opacity-90 transition-colors hover:text-white block text-base text-center" style={{border: '1px solid transparent'}}
    >
      {t.menu.contactUs}
    </Link> },
  ];

  // Map features using translations
  const features = comparePlansT.featureNames.map((name, index) => ({
    name,
    available: [
      comparePlansT.freemium.features[index],
      comparePlansT.basic.features[index],
      comparePlansT.pro.features[index],
      comparePlansT.custom.features[index]
    ].map(value => {
      if (typeof value === 'boolean') {
        return value ? <Check className="mx-auto text-green-600" /> : <X className="mx-auto" />;
      }
      return value;
    })
  }));

  return (
    <section className="py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-6 text-center">
          {comparePlansT.title}
        </h2>

        {/* Toggle */}
        <div className="flex justify-center mb-8">
          <div className="bg-brand-color p-1 rounded-full flex items-center">
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                !isYearly ? "bg-white text-brand-color-bold font-medium" : "text-gray-100"
              }`}
              onClick={() => setIsYearly(false)}
            >
              {comparePlansT.monthly}
            </button>
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                isYearly ? "bg-white text-brand-color-bold font-medium" : "text-gray-100"
              }`}
              onClick={() => setIsYearly(true)}
            >
              {comparePlansT.yearly}
            </button>
          </div>
        </div>

        {/* Comparison Table */}
        <div className="bg-white text-gray-800 rounded-xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-4 px-6 text-left text-xl font-medium">{t?.pricing?.compareFeatureTitle}</th>
                  {plans.map((plan, index) => (
                    <th key={index} className="py-4 px-6">
                      <div
                        className={`${plan.color} ${plan.textColor} rounded-xl p-6 min-w-[200px]`}
                      >
                        <h3 className="text-2xl font-bold mb-4 text-gray-500">{plan.name}</h3>
                        <p className="text-2xl font-semibold text-black">
                          {typeof plan[isYearly ? 'yearlyPrice' : 'monthlyPrice'] === 'number' ? (
                            <>
                              ${plan[isYearly ? 'yearlyPrice' : 'monthlyPrice']}
                              <span className="text-base font-normal">/{isYearly ? 'year' : 'month'}</span>
                            </>
                          ) : (
                            plan[isYearly ? 'yearlyPrice' : 'monthlyPrice']
                          )}
                        </p>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t-gray-200">
                {features.map((feature) => (
                  <tr key={feature.name} className="hover:bg-gray-50 border-0">
                    <td className="py-4 px-6 font-medium">{feature.name}</td>
                    {plans.map((_, planIndex) => (
                      <td key={planIndex} className="py-4 px-6 text-center">
                        {feature.available[planIndex]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <p className="text-sm text-gray-500 mt-8 text-center">
          {comparePlansT.disclaimer}
        </p>
      </div>
    </section>
  );
}
