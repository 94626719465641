import { Input } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Select from 'react-select'
import { apiUrl } from '../../Variables'
import Layout from '../../components/Layout'
import CalendlyIntegration from '../../components/marketplace/integration/CalendlyIntegration'
import GoogleCalendarIntegrate from '../../components/marketplace/integration/GoogleCalendarIntegration'
import MessengerIntegration from '../../components/marketplace/integration/MessengerIntegration'
import WhatsappIntegration from '../../components/marketplace/integration/WhatsappIntegration'

const { Search } = Input;

function MarketplaceScreen({toggleLoggedInState}) {

  const [chatbots, setChatbots] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedBot, setSelectedBot] = useState({ value: null, label: 'All' });

  
  // fetch group data []
  useEffect(() => {
    const fetchGroup = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setLoading(true);

        const res = await axios.get(`${apiUrl}/chatbot`, { headers });
        setChatbots(res.data);

        console.log(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchGroup();
  }, []);
  
  const botOptions = (chatbots || []).map((item) => ({
    value: {
      id: item?._id,
      configData: item?.configData,
    },
    label: item.name,
  }));

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: '210px',
      height: '132px',
      overflowY: 'scroll',
      border: '1px solid rgb(147, 147, 147)',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'aliceblue',
      border: '1px solid rgb(147, 147, 147)',
      fontWeight: '700',
      boxShadow: 'none',
      height: '48px',
      width: '210px',
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...provided,
        backgroundColor:
          data?.value === selectedBot?.value ? 'aliceblue' : 'white',

        color: data?.value === selectedBot?.value ? 'black' : 'black',
      };
    },
    // indicatorSeparator: (provided) => ({
    //   ...provided,
    //   display: 'none',
    // }),
  };



  return (
    <>
      <Helmet>
        <title>Marketplace | ACEVA Dashboard</title>
      </Helmet> 

      <Layout toggleLoggedInState={toggleLoggedInState}>
        <div className="marketplace-container">
          {/* AllChatbot Lists */}
          
          <div className="marketplace-title">
            <h2>Marketplace</h2>
            <div className="form-group">
              <input type="text" placeholder='Search...' />
            </div>
            
          </div>
         {/* AllChatbot Lists */}
         <div className="all-bot-list">
            {
              selectedBot?.label === 'All' ? (
              <div className="list-bot-logo no-bot">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
              ) : selectedBot?.value.configData?.logo ? (
                <div className="list-bot-logo">
                  <img src={selectedBot?.value.configData?.logo} alt="bot icon" />
                </div>
                ) : (
                <div className="list-bot-logo no-bot">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                </div>
                )
            }
            <Select
              onChange={(selected) => {
                setSelectedBot(selected);
              }}
              value={selectedBot}
              options={[{ value: null, label: 'All' }, ...botOptions]}
              placeholder="Select a bot..."
              className='bot-dropdown'
            />
          </div>
          {/* marketplace items */}
          <div className="marketplace-wrapper mt-8">

            {/* messenger */}
            <MessengerIntegration />

            {/* whatsapp */}
            <WhatsappIntegration />

            {/* Calendly */}
            {/* <CalendlyIntegration /> */}

            {/* Google calender */}
            <GoogleCalendarIntegrate />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default MarketplaceScreen