import {
  Bot,
  Home,
  Menu
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import axios from 'axios';
import moment from 'moment';
import toast from 'react-hot-toast';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../Variables';
import { useAppContext } from '../context/AppContext';
import messengerIcon from '../images/icons/messenger.png';
import globeIcon from '../images/icons/web.png';
import whatsappIcon from '../images/icons/whatsapp.png';
import Select from 'react-select';
import HeaderMenu from "../components/landing-page/HeaderMenu";

const NewChatlogScreen = () => {
  // State variables
  const [activeUser, setActiveUser] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const messagesEndRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [showChat, setShowChat] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [createGroupToggle, setCreateGroupToggle] = useState(false);

  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [CSVLoading, setCSVLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
    totalChats: 0,
  });
  const [chatBot, setChatBot] = useState(null);
  const [chatDetailsId, setChatDetailsId] = useState(null);
  const [chatUniqueId, setChatUniqueId] = useState(null);
  const [sChatbotId, setSChatbotId] = useState('');
  const [channel, setChannel] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [singleChats, setSingleChat] = useState([]);
  const [filterDate, setFilterDate] = useState({
    startDate: "",
    endDate: ""
  });
  const [searchInput, setSearchInput] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);
  const [selectedBot, setSelectedBot] = useState({ value: { id: null }, label: 'All' });
  const [chatbots, setChatbots] = useState([]);
  const [resetData, setResetData] = useState(false);

  const location = useLocation();
  const { sidebarToggle } = useAppContext();


  //Handle window resize auto-execute
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  // Handle mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 1024) {
        setShowLeftSidebar(true);
        setShowRightSidebar(true);
      } else {
        setShowRightSidebar(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  // Handle chatbot selection and chatlog
  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo?.token) {
      toast.error('Please login first');
      return;
    }
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    if (sChatbotId) {
      axios.get(`${apiUrl}/botConfig/${sChatbotId}`, { headers })
        .then(res => {
          setChatBot(res.data[0]?.configData || {});
        })
        .catch(err => {
          console.error(err);
          toast.error('Failed to fetch bot config');
        });

      setChatLoading(true);
      axios.get(`${apiUrl}/chats/getChatLogById/${chatUniqueId}`, {headers})
        .then(res => {
          setSingleChat(res.data.chats);
        })
        .catch(err => {
          console.error(err);
          toast.error('Failed to fetch chat messages');
        })
        .finally(() => {
          setChatLoading(false);
        });
        console.log('======================================\nSelected chatBot\n', chatBot)

    }
  }, [sChatbotId, chatUniqueId]);
  // Effects
  useEffect(() => {
    fetchChatbots();
  }, []);
  useEffect(() => {
    fetchChats(pagination.currentPage);
  }, [selectedBot, pagination.currentPage, channel, resetData]);
  // Scroll to bottom of messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    console.log('=================================================================================\nSelected Chatlog Data: \n', singleChats, "\n=================================================================================")
  }, [singleChats]);

  // Get auth headers
  const getAuthHeaders = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return {
      Authorization: `Bearer ${userInfo?.token}`
    };
  };
  // Handle window resize
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  // Fetch chats
  const fetchChats = async (page = 1) => {
    try {
      setLoading(true);
      const headers = getAuthHeaders();
      const response = await axios.get(`${apiUrl}/chats`, {
        params: {
          bot_id: selectedBot?.value?.id,
          channel,
          page,
          limit: pagination?.pageSize,
          startDate: filterDate.startDate,
          endDate: filterDate.endDate
        },
        headers
      });

      const { data, pagination: paginationData } = response.data;
      setChats(Array.isArray(data) ? data : []);
      setFilteredChats(Array.isArray(data) ? data : []);
      setPagination(paginationData);
    } catch (error) {
      console.error("Error fetching chats:", error);
      toast.error("Failed to fetch chats");
    } finally {
      setLoading(false);
    }
  };
  // Handle date filter
  const handleDateFilter = async () => {
    if (!filterDate.startDate || !filterDate.endDate) {
      toast.error('Please select both start and end dates');
      return;
    }

    try {
      setLoading(true);
      const headers = getAuthHeaders();
      const response = await axios.get(`${apiUrl}/chats/filterChats`, {
        params: {
          startDate: filterDate.startDate,
          endDate: filterDate.endDate,
          bot_id: selectedBot?.value?.id,
          channel,
          page: 1,
          limit: pagination?.pageSize,
        },
        headers
      });

      const { data, pagination: paginationData } = response.data;
      setFilteredChats(data);
      setPagination(paginationData);
      setFilterShow(false);
    } catch (error) {
      console.error("Error filtering chats:", error);
      toast.error("Failed to filter chats");
    } finally {
      setLoading(false);
    }
  };
  // Reset filters
  const resetFilters = () => {
    setFilterDate({
      startDate: "",
      endDate: ""
    });
    setFilterShow(false);
    setResetData(!resetData);
  };
  // Fetch chatbots
  const fetchChatbots = async () => {
    try {
      const headers = getAuthHeaders();
      const response = await axios.get(`${apiUrl}/chatbot`, { headers });
      setChatbots(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching chatbots:", error);
      toast.error("Failed to fetch chatbots");
    }
  };
  // Search chats
  const handleSearchInputChange = async (e) => {
    e.preventDefault();
    if (!searchInput.trim()) {
      toast.error('Please enter a search term');
      return;
    }

    try {
      setLoading(true);
      const headers = getAuthHeaders();
      const response = await axios.post(
        `${apiUrl}/chats/search`,
        { keyword: searchInput.toLowerCase() },
        { headers }
      );
      setFilteredChats(response.data);
    } catch (error) {
      console.error("Error searching chats:", error);
      toast.error("Search failed");
    } finally {
      setLoading(false);
    }
  };
  // Delete chat
  const handleDelete = async (chatData) => {
    if (!window.confirm('Are you sure you want to delete this chat?')) {
      return;
    }

    try {
      setLoading(true);
      const headers = getAuthHeaders();
      await axios.delete(
        `${apiUrl}/chats/${chatData.chatbotId}/${chatData.senderId}/${chatData._id}`,
        { headers }
      );
      toast.success('Successfully deleted');
      fetchChats(pagination.currentPage);
    } catch (error) {
      console.error("Error deleting chat:", error);
      toast.error("Failed to delete chat");
    } finally {
      setLoading(false);
    }
  };
  // Export chats
  const handleExport = async () => {
    if (!selectedItems.length) {
      toast.error("Please select chats to export");
      return;
    }

    try {
      setCSVLoading(true);
      const response = await fetch(
        `${apiUrl}/chats/exportChatlogCSV/${selectedItems.toString()}?startdate=${filterDate.startDate || new Date(new Date().setDate(new Date().getDate() - 7))?.toISOString().split('T')[0]
        }&enddate=${filterDate.endDate || new Date()?.toISOString().split('T')[0]}`
      );

      if (!response.ok) throw new Error('Export failed');

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Chatlog_summary.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Export successful");
    } catch (error) {
      console.error("Error exporting chats:", error);
      toast.error("Export failed");
    } finally {
      setCSVLoading(false);
    }
  };
  // Handle item selection
  const handleItemSelection = (item) => {
    if (filterShow) {
      setFilterShow(false);
    }
    if (selectedItems.includes(item._id)) {
      setSelectedItems(selectedItems.filter(i => i !== item._id));
    } else {
      setSelectedItems([...selectedItems, item._id]);
    }
  };
  // Handle channel filter
  const filterChatByChannel = (selectedChannel) => {
    setChannel(selectedChannel === 'all' ? null : selectedChannel);
  };
  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination?.totalPages) {
      setPagination(prev => ({ ...prev, currentPage: newPage }));
    }
  };
  // Get sender details
  const getSenderIdHandler = (id, senderId, chatbotId) => {
    setChatDetailsId(senderId);
    setSChatbotId(chatbotId);
    setChatUniqueId(id);
    if (isMobile) {
      setShowLeftSidebar(false);
    }
  };
  return (
    <>
      <div className="flex h-screen bg-slate-100">
        {/* Left Sidebar */}
        <div className={`fixed inset-y-0 left-0 z-30 w-80 transform transition-transform duration-300 ease-in-out ${showLeftSidebar ? 'translate-x-0' : '-translate-x-full'
          } md:relative md:translate-x-0 bg-white border-r border-slate-200 flex flex-col`}>
          {/* Sidebar Header */}
          <div className="p-4 border-b border-slate-200 h-16">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-slate-800">Chatlogs</h2>
              {isMobile && (
                <button onClick={() => setShowLeftSidebar(false)} className="md:hidden">
                  <BsX className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>

          {/* AllChatbot Lists */}
          <div className="mt-2 flex items-center gap-2 px-4 w-full">
            {
              selectedBot?.label === 'All' ? (
                <div className="list-bot-logo no-bot">
                  <Bot className="w-10 h-10 !text-white p-[6px]" />
                </div>
              ) : selectedBot?.value.configData?.logo ? (
                <div className="list-bot-logo">
                  <img src={selectedBot?.value.configData?.logo} alt="bot icon" />
                </div>
              ) : (
                <div className="list-bot-logo no-bot">
                  <Bot className="w-10 h-10 !text-white p-[6px]" />
                </div>
              )
            }
            <Select
              className='bot-dropdown !w-full'
              onChange={selected => setSelectedBot(selected)}
              value={selectedBot}
              options={[{ value: null, label: 'All' }, ...chatbots?.map(item => ({
                value: { id: item._id, configData: item.configData },
                label: item.name
              }))]}
              placeholder="Select a bot..."
            />
          </div>

          {/* Channel Filters */}
          <div className="p-2 px-4 mt-2 border-b border-slate-200 border-t">
            <div className="flex items-center gap-4">
              <button
                onClick={() => filterChatByChannel('all')}
                className={`px-3 py-1 rounded-full text-sm ${!channel ? 'bg-brand-color text-white' : 'bg-slate-100 text-slate-600'
                  }`}
              >
                All
              </button>
              <button
                onClick={() => filterChatByChannel('web')}
                className={`p-1 rounded-full ${channel === 'web' ? 'bg-brand-color-light' : 'bg-slate-100'
                  }`}
              >
                <img src={globeIcon} alt="Web" className="w-5 h-5" />
              </button>
              <button
                onClick={() => filterChatByChannel('whatsapp')}
                className={`p-1 rounded-full ${channel === 'whatsapp' ? 'bg-brand-color-light' : 'bg-slate-100'
                  }`}
              >
                <img src={whatsappIcon} alt="WhatsApp" className="w-5 h-5" />
              </button>
              {/* <button
              onClick={() => filterChatByChannel('messenger')}
              className={`p-1 rounded-full ${
                channel === 'messenger' ? 'bg-brand-color-light' : 'bg-slate-100'
              }`}
            >
              <img src={messengerIcon} alt="Messenger" className="w-5 h-5" />
            </button> */}
            </div>
          </div>

          {/* Search and Filters */}
          <div className="p-4 border-b border-slate-200">
            {filterShow && (
              <div className="mb-4 p-4 bg-slate-50 rounded-lg">
                <div className="space-y-3">
                  <div>
                    <label className="block text-xs text-slate-600 mb-1">Start Date</label>
                    <input
                      type="date"
                      value={filterDate.startDate}
                      onChange={(e) => setFilterDate(prev => ({ ...prev, startDate: e.target.value }))}
                      className="w-full px-3 py-1.5 border border-slate-200 rounded focus:outline-none focus:ring-1 focus:ring-brand-color text-sm text-slate-700"
                    />
                  </div>
                  <div>
                    <label className="block text-xs text-slate-600 mb-1">End Date</label>
                    <input
                      type="date"
                      value={filterDate.endDate}
                      onChange={(e) => setFilterDate(prev => ({ ...prev, endDate: e.target.value }))}
                      className="w-full px-3 py-1.5 border border-slate-200 rounded focus:outline-none focus:ring-1 focus:ring-brand-color text-sm text-slate-700"
                    />
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={handleDateFilter}
                      className="flex-1 px-3 py-1.5 bg-brand-color text-white rounded hover:bg-brand-color-dark"
                    >
                      Apply Filter
                    </button>
                    <button
                      onClick={resetFilters}
                      className="px-3 py-1.5 text-slate-600 bg-slate-200 rounded hover:bg-slate-300"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            )}

            <form onSubmit={handleSearchInputChange} className="relative mb-4">
              <input
                type="text"
                placeholder="Search chats..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full px-4 py-1.5 border border-slate-200 rounded-lg focus:outline-0 focus:ring-1 focus:ring-brand-color"
              />
              {searchInput && (
                <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setSearchInput('');
                      setFilteredChats([]);
                    }}
                    className="text-slate-400 hover:text-slate-600"
                  >
                    <BsX className="w-5 h-5" />
                  </button>
                  <button
                    type="submit"
                    className="px-2 py-1 text-sm bg-brand-color text-white rounded hover:bg-brand-color-dark"
                  >
                    {loading ? 'Searching...' : 'Search'}
                  </button>
                </div>
              )}
            </form>

            <div className="flex justify-between items-center gap-2">
              <button
                onClick={() => {
                  const allChats = searchInput || (filterDate.startDate && filterDate.endDate)
                    ? filteredChats
                    : chats;
                  if (selectedItems.length === allChats.length) {
                    setSelectedItems([]);
                  } else {
                    setSelectedItems(allChats.map(item => item._id));
                  }
                }}
                className="flex items-center gap-2 text-sm text-slate-600 hover:text-slate-900"
              >
                {selectedItems?.length === chats?.length ? (
                  <MdCheckBox className="w-5 h-5" />
                ) : (
                  <MdCheckBoxOutlineBlank className="w-5 h-5" />
                )}
                Select All
              </button>

              <button
                onClick={() => setFilterShow(!filterShow)}
                className="flex items-center gap-2 text-sm text-slate-600 hover:text-slate-900"
              >
                <FiFilter className="w-5 h-5" />
                Filter
              </button>

              {selectedItems.length > 0 && (
                <button
                  onClick={handleExport}
                  disabled={CSVLoading}
                  className="px-3 py-1 text-sm bg-brand-color text-white rounded hover:bg-brand-color-dark"
                >
                  {CSVLoading ? 'Exporting...' : 'Export Selected'}
                </button>
              )}
            </div>


            {filterDate.startDate && (
              <p className="text-sm text-slate-500 mt-4 border-t border-slate-200 pt-2 ">
                Results from: <span className='font-medium text-brand-color'> {moment(filterDate.startDate).format('Do MMMM, YYYY')}</span> to <span className='font-medium text-brand-color'>{moment(filterDate.endDate).format('Do MMMM, YYYY')}</span>
              </p>
            )}
          </div>


          {/* Chat List */}
          <div className="flex-1 overflow-y-auto">
            {loading ? (
              <div className="p-4 space-y-4">
                {Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="animate-pulse">
                    <div className="h-16 bg-slate-100 rounded-lg"></div>
                  </div>
                ))}
              </div>
            ) : (searchInput || filteredChats)?.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-slate-500">
                <BsExclamationCircle className="w-8 h-8 mb-2" />
                <p>No chats found</p>
              </div>
            ) : (
              <div className="divide-y divide-slate-200">
                {(searchInput || (filterDate.startDate && filterDate.endDate) || filteredChats?.length > 0
                  ? filteredChats
                  : chats
                )?.map((item, index) => (
                  <div
                    key={index}
                    className={`p-4 cursor-pointer hover:bg-slate-50 ${item?._id === chatUniqueId ? 'bg-green-50' : ''
                      }`}
                    onClick={() => getSenderIdHandler(item?._id, item?.senderId, item?.chatbotId)}
                  >
                    <div className="flex items-center gap-3">
                      <div onClick={(e) => {
                        e.stopPropagation();
                        handleItemSelection(item);
                      }}>
                        {selectedItems.includes(item._id) ? (
                          <MdCheckBox className="w-5 h-5 text-brand-color" />
                        ) : (
                          <MdCheckBoxOutlineBlank className="w-5 h-5 text-slate-400" />
                        )}
                      </div>
                      <div className="flex-1">
                        <h3 className="font-medium text-slate-900 text-base">{item?.senderId}</h3>
                        <p className="text-xs text-slate-500">
                          {moment(item?.updatedAt).fromNow()}
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item);
                        }}
                        className="p-1 text-slate-400 hover:text-red-500"
                      >
                        <AiOutlineDelete className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Pagination */}
          <div className="p-4 border-t border-slate-200">
            <div className="flex items-center justify-between gap-2">
              <button
                onClick={() => handlePageChange(pagination?.currentPage - 1)}
                disabled={pagination?.currentPage === 1}
                className={`px-3 py-1.5 text-xs rounded ${pagination?.currentPage === 1
                  ? 'bg-slate-100 text-slate-400'
                  : 'bg-white text-brand-color border border-brand-color hover:bg-brand-color hover:text-white'
                  }`}
              >
                Previous
              </button>
              <span className="text-xs text-slate-600">
                Page {pagination?.currentPage} of {pagination?.totalPages}
              </span>
              <button
                onClick={() => handlePageChange(pagination?.currentPage + 1)}
                disabled={pagination?.currentPage === pagination?.totalPages}
                className={`px-3 py-1.5 text-xs rounded ${pagination?.currentPage === pagination?.totalPages
                  ? 'bg-slate-100 text-slate-400'
                  : 'bg-white text-brand-color border border-brand-color hover:bg-brand-color hover:text-white'
                  }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>

        {/* Main Chat Area */}
        <div className="flex-1 flex flex-col">
          {/* Chat Header */}
          <header className="bg-white border-b border-slate-200 p-4 px-6 h-16">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-4">
                {isMobile && (
                  <button onClick={() => setShowLeftSidebar(true)} className="md:hidden">
                    <Menu className="w-6 h-6" />
                  </button>
                )}
                <div>
                  <h2 className="text-lg font-semibold text-slate-800">
                    {(chatBot?.botName ? chatBot?.botName : chatBot ? 'Bot has deleted, only chatlogs available!' : '') || 'Select a chat'}
                  </h2>
                  {/* <p className="text-sm text-green-500">
                  {chatBot?.name || 'No bot selected'}
                </p> */}
                </div>
              </div>
              <Link to="/dashboard" className="flex items-center gap-2 text-slate-600 hover:text-slate-900">
                <Home className="w-5 h-5" />
                <span className="hidden md:inline">Home</span>
              </Link>
            </div>
          </header>

          {/* Chat Messages */}
          <div className="flex-1 overflow-y-auto p-4 md:p-6 bg-slate-50">
            {chatLoading ? (
              <div className="space-y-4 max-w-2xl mx-auto">
                <Skeleton count={5} height={60} />
              </div>
            ) : (
              <div className="space-y-4">
                {singleChats?.map((chat, index) => (
                  <div key={index} className="space-y-4">
                    {/* User Message */}
                    <div className="flex justify-end">
                      <div className="max-w-[80%] bg-brand-color text-white rounded-lg px-4 py-2">
                        <p className="text-white">{chat?.user}</p>
                        <p className="text-xs text-white opacity-70 mt-1">
                          {moment(chat?.time).format('LT')}
                        </p>
                      </div>
                    </div>

                    {/* Bot Message */}
                    <div className="flex justify-start">
                      <div className="max-w-[80%] bg-white text-slate-900 rounded-lg px-4 py-2 shadow-sm">
                        <p className="text-slate-900">{chat?.bot?.text}</p>
                        <p className="text-xs text-slate-400 mt-1">
                          {moment(chat?.time).format('LT')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewChatlogScreen;
