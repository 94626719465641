import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';
import Select from 'react-select';

const options = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last7days', label: 'Last 7 Days' },
  { value: 'last30days', label: 'Last 30 Days' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'customDate', label: 'Custom Date' },
  // Add more options as needed
];

function SelectRange({filterDate, setFilterDate}) {
  const [filterShow, setFilterShow] = useState(false);
  const [tempFilterData, setTempFilterData] = useState({});

  const customDateHandler = () => {
    setFilterShow(true); // Show filter popup when user selects Custom Date
  }

  return (
    <>
      {/* filter model */}
      {filterShow && (
        <div className="date-filter-model-wrapper active">
          <div className="date-filter-model">
            <BsX
              className="cancel-icon chat-model-cancel-icon"
              onClick={() => setFilterShow(!filterShow)}
            />
            {/* Your custom date filter inputs */}
            <label htmlFor="">Start date</label>
            <input
              type="date"
              value={tempFilterData.startDate}
              onChange={(e) =>
                // setFilterDate({
                //   ...filterDate,
                //   startDate: e.target.value,
                // })
                setTempFilterData({...tempFilterData, startDate: e.target.value})
              }
            />
            <label htmlFor="">End date</label>
            <input
              type="date"
              value={tempFilterData.endDate}
              onChange={(e) =>
                // setFilterDate({
                //   ...filterDate,
                //   endDate: e.target.value,
                // })
                setTempFilterData({...tempFilterData, endDate: e.target.value})
              }
            />
            {/* Filter button */}
            <button
              type="button"
              onClick={async () => {
                // Perform filtering based on custom date range
                setFilterShow(false); // Close filter popup after filtering
                setFilterDate({
                  ...filterDate,
                  ...tempFilterData
                })
              }}
              className="form-button"
              style={{ marginTop: '10px' }}
            >
              <span>Filter</span>
            </button>
          </div>
        </div>
      )}

      {/* React Select component for selecting date range */}
      <Select
        className="bot-date-filter-dropdown"
        onChange={(selected) => {
          if(selected.value === 'customDate') {
            customDateHandler();
            setTempFilterData({...tempFilterData, otherDate: selected.value})
          } else {
            setFilterDate({
              ...filterDate,
              startDate: null,
              otherDate: selected.value
            })
          }
        }}
        value={options.find(item => filterDate.otherDate === item.value)}
        options={options}
      />
    </>
  );
}

export default SelectRange;
