import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarDetailSidebar from '../../screens/dashboard/CalendarDetailSidebar';
import { apiUrl } from '../../Variables';

const localizer = momentLocalizer(moment);

const convertGoogleEventsToBigCalendarFormat = googleEvents => {
  return googleEvents.map(event => ({
    id: event.id,
    title: event.summary,
    start: new Date(event.start.dateTime),
    end: new Date(event.end.dateTime),
    allDay: false,
    description: event.description,
    location: event.location,
    link: event.htmlLink,
    // You can include additional properties if needed
  }));
};

const BotCalendar = () => {
  const googleAccessToken = localStorage.getItem('googleAccessToken')
  const [googleEvents, setGoogleEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      try {
        const response = await axios.get(`${apiUrl}/gCalender/events`, {googleRefreshToken: userInfo.utilityData.grt});
        const convertedEvents = convertGoogleEventsToBigCalendarFormat(response.data.events);
        setGoogleEvents(convertedEvents);
      } catch (error) {
        console.error('Error fetching Google events:', error);
      }
    };

    fetchGoogleEvents();
  }, []);

  const CustomEvent = ({ event }) => (
    <div className='meeting-active-block'>
      <p className='meeting-active-title'>{event.title}</p>
      <div className="meeting-time-wrap">
        <p>Start: {moment(event.start).format('h:mm a')}</p>
        <p>End: {moment(event.end).format('h:mm a')}</p>
      </div>
    </div>
  );

  const handleSelectEvent = event => {
    console.log('Selected event:', event);
    setSelectedEvent(event);
  };

  const handleDrawerClose = () => {
    setSelectedEvent(null);
  };

  const dayPropGetter = date => {
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday (0) and Saturday (6)
    if (isWeekend) {
      return {
        className: 'office-off-day'
      };
    } else {
      return {
        className: 'office-day'
      };
    }
  };

  return (
    <div style={{ height: '400px' }}>
      <CalendarDetailSidebar event={selectedEvent} onClose={handleDrawerClose} />
      <Calendar
        localizer={localizer}
        events={googleEvents}
        startAccessor="start"
        endAccessor="end"
        components={{ event: CustomEvent }}
        onSelectEvent={handleSelectEvent} // Handle event click
        dayPropGetter={dayPropGetter} // Customize day styles
        style={{ margin: '30px 0px' }}
      />
    </div>
  );
};

export default BotCalendar;
