import { Switch, TimePicker, Select as AntdSelect } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { CircleAlert, CircleHelp, Clock } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Select from 'react-select'
import Layout from '../../components/Layout'
import LeadGenModal from '../../components/lead-generation/LeadGenModal'
import { apiUrl } from '../../Variables'
import PhoneInput from 'react-phone-input-2'

/**
 * Lead Generation Screen Component
 * Handles lead generation configuration and management
 */
export default function LeadGenerationScreen() {
  // Modal state
  const [modalOpen, setModalOpen] = useState(false)

  // Lead options configuration
  const [leadOptions, setLeadOptions] = useState([
    { id: 'name', text: 'Name', enabled: true, isDefault: true },
    { id: 'contact', text: 'Contact', enabled: true, isDefault: true },
    { id: 'email', text: 'Email', enabled: true, isDefault: true },
    { id: 'company', text: 'Company', enabled: true, isDefault: true },
    { id: 'product', text: 'Product/Service Interests', enabled: true, isDefault: true },
  ])

  // Form states
  const [activeTab, setActiveTab] = useState('email')
  const [emailInput, setEmailInput] = useState('test@example.com')
  const [whatsappInput, setWhatsappInput] = useState('011-11234515')
  const [timeOfDay, setTimeOfDay] = useState('8:00 AM')
  const [twiceDailyNotifications, setTwiceDailyNotifications] = useState(false)
  const [loading, setLoading] = useState(false)

  // Date range states
  const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedDateRange, setSelectedDateRange] = useState('select-date')
  const [customDateRangeText, setCustomDateRangeText] = useState('')

  // User input configuration state
  const [userInput, setUserInput] = useState({
    userId: '',
    botId: '',
    enableFields: {
      name: true,
      contact: true,
      email: true,
      company: true,
      product: true,
    },
    notificationChannel: 'both',
    emailAddresses: [],
    whatsappNumbers: [],
    notificationTime: '8am',
    upperLimit: '12:00',
    lowerLimit: '01:00'
  })

  // Chatbot states
  const [chatbots, setChatbots] = useState(null)
  const [selectedBot, setSelectedBot] = useState(null)

  // Custom TimePicker styles
  const timePickerProps = {
    suffixIcon: <Clock size={16} />,
    popupClassName: "custom-timepicker-dropdown",
    renderExtraFooter: () => null,
    style: { width: '100%', maxWidth: 120 }
  }

  /**
   * Fetch chatbots on component mount
   */
  useEffect(() => {
    const fetchBots = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        }

        const { data } = await axios.get(`${apiUrl}/chatbot`, { headers })
        
        if (data && data.length > 0) {
          setSelectedBot({
            value: {
              id: data[0]?._id,
              userId: data[0]?.userId,
              scrapIds: data[0]?.scrappedOrUpDataId?.toString(),
              configData: data[0]?.configData
            },
            label: data[0]?.name,
          })
        }
        
        setChatbots(data)
      } catch (error) {
        console.error('Error fetching chatbot list:', error)
        toast.error(`Error: ${error?.message || error?.response?.data}`)
      }
    }

    fetchBots()
  }, [])

  /**
   * Fetch lead generation configuration when selected bot changes
   */
  useEffect(() => {
    const fetchConfig = async () => {
      if (!selectedBot) return

      try {
        const { data } = await axios.get(`${apiUrl}/leadGenConfig/user/${selectedBot.value.userId}/bot/${selectedBot.value.id}`)
        setUserInput({...data})
      } catch (error) {
        console.error('Error fetching lead config:', error)
        // Reset to default config if none found
        setUserInput({
          userId: '',
          botId: '',
          enableFields: {
            name: true,
            contact: true,
            email: true,
            company: true,
            product: true
          },
          notificationChannel: 'both',
          emailAddresses: [],
          whatsappNumbers: [],
          notificationTime: '',
          upperLimit: '12:00',
          lowerLimit: '01:00'
        })
      }
    }

    fetchConfig()
  }, [selectedBot])

  // Transform chatbots data for select options
  const botOptions = (chatbots || []).map((item) => ({
    value: {
      id: item?._id,
      userId: item?.userId, 
      scrapIds: item?.scrappedOrUpDataId?.toString(),
      configData: item?.configData,
    },
    label: item.name,
  }))

  /**
   * Format date to readable string
   */
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  /**
   * Handle date range selection change
   */
  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value)
    if (value === 'custom-date') {
      setIsCustomDateModalVisible(true)
    } else {
      setCustomDateRangeText('')
    }
  }

  /**
   * Handle custom date range submission
   */
  const handleCustomDateSubmit = (e) => {
    e.preventDefault()
    if (startDate && endDate) {
      setIsCustomDateModalVisible(false)
      const formattedStartDate = formatDate(startDate)
      const formattedEndDate = formatDate(endDate)
      setCustomDateRangeText(`${formattedStartDate} to ${formattedEndDate}`)
      setSelectedDateRange('select-date')
    } else {
      toast.error('Please select both start and end dates')
    }
  }

  /**
   * Handle form submission
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const configData = {
        ...userInput,
        timeZone,
        ...(userInput._id ? {} : {
          userId: selectedBot.value.userId,
          botId: selectedBot.value.id,
        }),
      }
      
      if (userInput._id) {
        await axios.put(`${apiUrl}/leadGenConfig/${userInput._id}`, configData)
        toast.success('Configuration Updated!')
      } else {
        await axios.post(`${apiUrl}/leadGenConfig`, configData).then(res => {
          setUserInput({...res.data})
        })
        toast.success('Configuration Saved!')
      }
    } catch (error) {
      console.error('Error saving/updating configuration:', error)
      console.log(error)
      if (error.response.data.message[1] == 0)
      {
        toast.error(error.response.data.message[0])

      }
      toast.error(error.response.data.message[0])
    } finally {
      setLoading(false)
    }
  }

  /**
   * Handle configuration deletion
   */
  const handleDelete = async () => {
    const isConfirmed = window.confirm("Are you sure you want to delete this lead?")
    
    if (isConfirmed) {
      try {
        await axios.delete(`${apiUrl}/leadGenConfig/${userInput._id}`)
        toast.success('Lead Configuration deleted successfully.')
        
        // Reset form
        setUserInput({
          userId: '',
          botId: '',
          enableFields: {
            name: true,
            contact: true,
            email: true,
            company: true,
            product: true
          },
          notificationChannel: 'both',
          emailAddresses: [],
          whatsappNumbers: [],
          notificationTime: '8am',
          upperLimit: '12:00',
          lowerLimit: '01:00'
        })
      } catch (error) {
        console.error("Error deleting lead:", error)
        toast.error('Failed to delete lead configuration')
      }
    }
  }

  return (
    <Layout>
      {modalOpen && <LeadGenModal setModalOpen={setModalOpen} />}
      <div className="min-h-screen w-full">
        {/* AllChatbot Lists */}
        <div className="flex justify-start items-start sm:items-center gap-3 w-full text-left">
          {selectedBot?.label === 'All' ? (
            <div className="list-bot-logo no-bot">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
            </div>
          ) : selectedBot?.value?.configData?.logo ? (
            <div className="list-bot-logo">
              <img src={selectedBot.value.configData.logo} alt="bot icon" className="w-full h-auto" />
            </div>
          ) : (
            <div className="list-bot-logo no-bot">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
            </div>
          )}
          <Select
            onChange={setSelectedBot}
            value={selectedBot}
            options={botOptions}
            placeholder="Select a bot..."
            className='bot-dropdown font-poppins w-full sm:w-auto'
          />
        </div>

        {/* Lead Generation Form */}
        <div className="bg-white rounded-lg shadow overflow-hidden border border-gray-100 my-5">
          <div className="p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-start items-start sm:items-center gap-3 mb-6">
              <h1 className="text-xl sm:text-2xl font-semibold text-gray-800">Lead Generation</h1>
              <button
                type="button"
                style={{border: '1px solid lightgray', borderRadius: '4px'}}
                onClick={() => setModalOpen(true)}
                className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-800 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color"
              >
                Examples & Tips
              </button>
            </div>

            <p className="mb-6 text-gray-600 text-sm sm:text-base">
              <span className="font-semibold">📈 Our Lead Generation feature</span> allows you to seamlessly capture valuable customer insights and contact details directly through your AI chatbot.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Lead Generation Options */}
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                <div className="p-4 border-b border-gray-200">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">Lead Generation Options</h2>
                </div>
                <div className="p-4">
                  <ul className="space-y-4">
                    {Object.keys(userInput?.enableFields).map((option) => (
                      <li
                        key={option}
                        className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-gray-50 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                      >
                        <div className="flex items-center mb-2 sm:mb-0">
                          <span className="text-gray-700 font-medium text-sm sm:text-base capitalize">{option}</span>
                        </div>
                        <div className="flex items-center space-x-3">
                          <Switch
                            checked={userInput?.enableFields[option]}
                            onChange={(checked) => {
                              setUserInput({
                                ...userInput,
                                enableFields: {
                                  ...userInput.enableFields,
                                  [option]: checked
                                }
                              })
                            }}
                            className="bg-gray-300"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Settings Section */}
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                <div className="p-4 border-b border-gray-200">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">Settings</h2>
                </div>
                <div className="p-4 space-y-6">
                  {/* Notification Channel Selection */}
                  <div>
                    <div className='flex flex-col sm:flex-row justify-start gap-3 items-start sm:items-center mb-4'>
                      <h4 className="font-medium text-gray-700 text-sm sm:text-base">Preferred Channel for Lead Notifications:</h4>
                      <div className="flex flex-wrap gap-2">
                        {['email', 'whatsapp', 'both'].map((channel) => (
                          <button
                            type='button'
                            key={channel}
                            onClick={() => setUserInput({...userInput, notificationChannel: channel})}
                            className={`px-4 py-2 text-sm font-medium rounded-full ${
                              userInput?.notificationChannel === channel
                                ? 'bg-brand-color text-white'
                                : 'bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-50'
                            }`}
                          >
                            {channel.charAt(0).toUpperCase() + channel.slice(1)}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Email Input */}
                    {(userInput?.notificationChannel === 'email' || userInput?.notificationChannel === 'both') && (
                      <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-normal text-gray-500 mb-1">
                          Email address:
                        </label>
                        <input
                          type="email"
                          id="email"
                          value={userInput?.emailAddresses[0] || ''}
                          onChange={(e) => {
                            const newEmailAddresses = [...(userInput.emailAddresses || [])]
                            newEmailAddresses[0] = e.target.value
                            setUserInput({...userInput, emailAddresses: newEmailAddresses})
                          }}
                          className="mt-2 block w-full border border-gray-100 rounded-md shadow-sm py-2 px-3 focus:outline-0 bg-gray-50 sm:text-sm"
                          required={userInput?.notificationChannel === 'email' || userInput?.notificationChannel === 'both'}
                        />
                      </div>
                    )}

                    {/* WhatsApp Input */}
                    {(userInput?.notificationChannel === 'whatsapp' || userInput?.notificationChannel === 'both') && (
                      <div className="mb-4">
                        <label htmlFor="whatsapp" className="block text-sm font-normal text-gray-500 mb-1">
                          WhatsApp number:
                        </label>
                        <PhoneInput
                            // className="aceva-gpt-input" 
                            country={'my'}
                            value={userInput?.whatsappNumbers[0] || ''}
                            onChange={(phone) => {
                              const newWhatsappNumbers = [...(userInput.whatsappNumbers || [])]
                              newWhatsappNumbers[0] = phone
                              setUserInput({...userInput, whatsappNumbers: newWhatsappNumbers})
                            }}
                            className="mt-2 block w-full border border-gray-100 rounded-md shadow-sm focus:outline-0 bg-gray-50 sm:text-sm"
                            required={userInput?.notificationChannel === 'whatsapp' || userInput?.notificationChannel === 'both'}
                            style={{
                              // resize: 'none',
                              width: '100%',
                              height: '45px', // Increase height
                              fontSize: '16px' // Optional: make text larger
                            }}
                          />
                        {/* <input
                          type="tel"
                          id="whatsapp"
                          value={userInput?.whatsappNumbers[0] || ''}
                          onChange={(e) => {
                            const newWhatsappNumbers = [...(userInput.whatsappNumbers || [])]
                            newWhatsappNumbers[0] = e.target.value
                            setUserInput({...userInput, whatsappNumbers: newWhatsappNumbers})
                          }}
                          className="mt-2 block w-full border border-gray-100 rounded-md shadow-sm py-2 px-3 focus:outline-0 bg-gray-50 sm:text-sm"
                          required={userInput?.notificationChannel === 'whatsapp' || userInput?.notificationChannel === 'both'}
                        /> */}
                      </div>
                    )}
                  </div>

                  {/* Notification Time Selection */}
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-3 border-t-2 border-gray-100 pt-6">
                    <h4 className="font-medium text-gray-700 text-sm sm:text-base">Lead Notification Settings:</h4>
                    <div className="flex flex-wrap gap-2">
                      <button
                        type='button'
                        onClick={() => setUserInput({...userInput, notificationTime: 'Hourly'})}
                        className={`px-4 py-2 text-sm font-medium rounded-full ${
                          userInput?.notificationTime === 'Hourly'
                            ? 'bg-brand-color text-white'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                      >
                        Hourly
                      </button>
                      <button
                        type='button'
                        onClick={() => setUserInput({...userInput, notificationTime: 'BiDaily'})}
                        className={`px-4 py-2 text-sm font-medium rounded-full ${
                          userInput?.notificationTime === 'BiDaily'
                            ? 'bg-brand-color text-white'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        }`}
                      >
                        Bi-Daily
                      </button>
                    </div>
                  </div>

                  {/* Select start and end time */}
                  <div className="flex flex-col items-start gap-4 border-t-2 border-gray-100 pt-6">
                    <h4 className="font-medium text-gray-700 text-sm sm:text-base">Select start and end time:</h4>
                    <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-left sm:items-center w-full">
                      {/* start */}
                      <div className="flex items-center gap-3 w-full sm:w-auto">
                        <p className="text-sm font-medium text-gray-700 mb-1 min-w-10">Start</p>
                        <AntdSelect
                          value={userInput?.upperLimit || '12:00'}
                          onChange={(value) => setUserInput({...userInput, upperLimit: value})}
                          className=""
                          style={{ maxHeight: '100px', overflowY: 'auto' }}
                          suffixIcon={<Clock size={16} />}
                        >
                          {Array.from({ length: 24 }, (_, i) => (
                            <AntdSelect.Option key={i} value={`${String(i + 1).padStart(2, '0')}:00`}>
                              {`${String(i + 1).padStart(2, '0')}:00`}
                            </AntdSelect.Option>
                          ))}
                        </AntdSelect>
                      </div>

                      {/* end */}
                      <div className="flex items-center gap-3 w-full sm:w-auto">
                        <p className="text-sm font-medium text-gray-700 mb-1 min-w-10">End</p>
                        <AntdSelect
                          value={userInput?.lowerLimit || '01:00'}
                          onChange={(value) => setUserInput({...userInput, lowerLimit: value})}
                          className=""
                          style={{ maxHeight: '100px', overflowY: 'auto' }}
                          suffixIcon={<Clock size={16} />}
                        >
                          {Array.from({ length: 24 }, (_, i) => (
                            <AntdSelect.Option key={i} value={`${String(i + 1).padStart(2, '0')}:00`}>
                              {`${String(i + 1).padStart(2, '0')}:00`}
                            </AntdSelect.Option>
                          ))}
                        </AntdSelect>
                      </div>

                      {/* disclaimer icon */}
                      <div className="relative group">
                        <CircleHelp className='h-5 w-5 cursor-pointer -ml-0 sm:-ml-4' />
                        <div className="absolute z-[9999] bottom-full mb-2 hidden group-hover:block w-64 p-3 bg-gray-800 text-white text-xs rounded-xl shadow-lg sm:w-48 sm:text-sm">
                        Select your start and end time to receive hourly messages during this period.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Form Actions */}
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  type='submit'
                  disabled={loading}
                  className="w-full sm:w-auto px-4 py-3 border border-transparent text-sm font-medium rounded-lg text-white bg-brand-color hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color"
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
                <button
                  type='button'
                  onClick={handleDelete}
                  disabled={!userInput._id}
                  className="w-full sm:w-auto px-4 py-3 border border-transparent text-sm font-medium rounded-lg text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Custom Date Range Modal */}
      {isCustomDateModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Select Custom Date Range</h2>
            <form onSubmit={handleCustomDateSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-brand-color focus:border-brand-color"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-1 focus:ring-brand-color focus:border-brand-color"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                />
              </div>
              <div className="flex flex-col sm:flex-row justify-end gap-2 mt-4">
                <button
                  type="button"
                  onClick={() => setIsCustomDateModalVisible(false)}
                  className="w-full sm:w-auto px-4 py-3 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full sm:w-auto px-4 py-3 text-sm font-medium text-white bg-brand-color hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  )
}