'use client';

import axios from 'axios';
import { Check } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import Translations from '../../context/Translations';
import { apiUrl } from '../../Variables';
const customOrder = ["Freemium", "Basic", "Pro", "Pro+"];

const PricingTier = ({ title, price, period, description, features, advancedFeatures, buttonText, isEssential, featuresTitle, featureTitleClass, isCustom }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="min-h-[140px]">
      <h3 className="text-2xl font-semibold mb-2 text-gray-700">{title}</h3>
      <p className="text-gray-600 mb-4 min-h-[75px]">{description}</p>
    </div>
    {price !== undefined && <div className="text-3xl font-bold mb-4 min-h-[30px]">
      {isCustom ? (
        <span className="text-brand-color">{price}</span>
      ) : (
        <>${price}<span className="text-lg font-normal">/{period}</span></>
      )}
    </div>}
    <Link to={title === 'Pro+' ? "/contact-us" : "/register"}>
      <button className={`w-full py-2 px-4 rounded-lg mb-6 hover:shadow hover:bg-brand-color-bold hover:text-white transition-bg duration-200 ${isEssential ? 'bg-brand-color text-white' : 'bg-slate-200 text-gray-700'} ${title === 'Pro+' ? 'mt-[52px]' : ''}`}>
        {buttonText || (title === 'Pro+' ? "Contact us" : "Get Started")}
      </button>
    </Link>
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Check className="inline-block text-brand-color mr-1 w-5 h-5 mt-1 flex-shrink-0 cursor-default" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    {advancedFeatures && (
      <h3 className={`text-base font-semibold text-brand-color-bold my-4 border-t border-gray-100 pt-4 ${isCustom ? 'mt-12' : ''}`}>{featuresTitle}</h3>
    )}
    {advancedFeatures && (
      <ul className="space-y-2">
        {advancedFeatures.map((advancedFeature, index) => (
          <li key={index} className="flex items-start">
            <Check className="inline-block text-brand-color mr-1 w-5 h-5 mt-1 flex-shrink-0 cursor-default" />
            <span>{advancedFeature}</span>
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default function PricingSection({ h1 = true }) {
  const { language } = useLanguage();
  const t = Translations[language];
  const [isYearly, setIsYearly] = useState(false);

  const [pricingData, setPricingData] = useState([
    {
      title: "Freemium",
      description: "Experience it for 30 days and see the difference!",
      monthlyPrice: "0",
      yearlyPrice: "0",
      features: [
        "500 Mistral Responses/mo",
        "1 AI Chatbot",
        "Comprehensive Webpage Training – up to 25 pages",
        "Dashboard access for Insights & Analysis",
        "Real-time updates and customer interactions tracking"
      ],
      isEssential: false
    }, {
      title: "Basic",
      description: "Ideal for businesses looking to incorporate an AI Chatbot.",
      monthlyPrice: "15",
      yearlyPrice: "180",
      features: [
        "4,000 GPT Responses/mo",
        "1 AI Chatbot",
        "Comprehensive Webpage Training – up to 200 pages",
      ],
      advancedFeatures: [
        "Custom AI Chatbot profile",
        "Access to GPT-4o Mini for faster, smarter solutions",
        "Lead generations",
        "Integration with Messenger and Telegram",
        "Appointment scheduling with Calendly"
      ],
      featuresTitle: "All from Freemium Plan, plus:",
      isEssential: true
    }, {
      title: "Pro",
      description: "Designed for businesses that need advanced features to scale quickly.",
      monthlyPrice: "49",
      yearlyPrice: "588",
      features: [
        "10,000 GPT Responses/mo",
        "3 AI Chatbots",
        "Comprehensive Webpage Training – up to 500 pages",
      ],
      advancedFeatures: [
        "Removed watermark from AI Chatbot profile",
        "Data Exporting",
        // "Expanded scheduling - now includes WhatsApp",
        // "Expanded scheduling - now includes Google Calendar"
      ],
      featuresTitle: "All from Basic Plan, plus:",
      isEssential: false
    },
  ])

  useEffect(() => {
    const fetchApis = async () => {
      await axios.get(`${apiUrl}/plan`).then(res => {
        setPricingData(res.data)
      }).catch(err => {
        console.log(err)
      })
    }
    fetchApis()
  }, [])

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container">
        <div className="text-center mb-5">
          <p className='mb-4 text-brand-color-bold font-medium text-base'>{t?.pricing?.badge}</p>
          {h1 ? (
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-32 leading-tight sm:leading-tight max-w-5xl text-center mx-auto text-gray-800">
              {t?.pricing?.title}
            </h1>
          ) : (
            <h2 className="text-4xl md:text-5xl font-bold mb-16 leading-tight sm:leading-tight max-w-5xl text-center mx-auto text-gray-800">
              {t?.pricing?.title}
            </h2>
          )}
          <p className="text-xl text-gray-500 mb-8">
            {t?.pricing?.subTitle}
          </p>
        </div>

        <div className="grid gap-4 lg:grid-cols-4">
          {t?.pricing?.pricingPlans?.map((tier, index) => (
            <PricingTier
              key={index}
              title={tier.title}
              price={isYearly ? tier.price.isYearly : tier.price.isMonthly}
              period={isYearly ? "year" : "mo"}
              description={tier.description}
              features={tier.features}
              advancedFeatures={tier.advancedFeatures}
              buttonText={tier.buttonText}
              isEssential={tier.isEssential}
              featuresTitle={tier.featuresTitle}
              isCustom={tier.isCustom}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
