import axios from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsX } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { apiUrl } from '../../Variables';
import Layout from '../../components/Layout';
import MarketplaceBreadcrumb from '../../components/marketplace/MarketplaceBreadcrumb';
import mail from '../../images/icons/mail.png';
import marketplace from '../../images/icons/whatsapp.png';
import SelectPrice from './SelectPrice';
import WaUserTypeRadio from './WaUserTypeRadio';



function WhatsappDetailScreen({toggleLoggedInState}) {
  const navigate = useNavigate()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [value, setValue] = useState('price_1OmguPFBC3QMoaI4utnDFPip');
  const [newNumber, setNewNumber] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams();
  const queryFrom = searchParams.get('from'); // "testCode"

  const handleContactClick = () => {
    // Your logic for displaying the popup
    setShowPopup(true);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Clear email error when user starts typing
    setEmailError('');
  };

  const handleNotify = async () => {
    // Your logic for email validation
    // const isValid = /\S+@\S+\.\S+/.test(email);

    // if (!isValid) {
    //   setEmailError('Please enter a valid email address');
    // } else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        }
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
  
        await axios.post(`${apiUrl}/user/marketPlaceSendNotifyMail`, {email: userInfo.email}, {headers}).then(res => {
          console.log(res)
          setEmail('')
          setEmailError('');
          setIsValidEmail(true);
          const updatedUser = {...JSON.parse(localStorage.getItem('userInfo')), ...res.data}
          localStorage.setItem('userInfo', JSON.stringify(updatedUser));
          setShowPopup(false);
        }).catch(err => {
          setEmailError('Some thing went wrong! We did not reach your email address.');
          console.log(err)
        })
      } catch (error) {
        console.log(error)
      }
    // }
  };

  const subsCriptionPayNow = async () => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    try {
      const response = await axios({
        url: `${apiUrl}/stripe/subscriptionCheckout`,
        method: 'post',
        data: {
          items: [ { priceId: value, quantity: 1, newNumber } ],
          token: userInfo.token
        },
        headers
      });
      console.log(response)
      setLoading(false)
      // Clear the local storage
      window.location = response.data.url
      // localStorage.clear();
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>WhatsApp Integration | ACEVA Dashboard</title>
      </Helmet>

      <Layout toggleLoggedInState={toggleLoggedInState}>
        <div className="marketplace-container">
        <MarketplaceBreadcrumb title="WhatsApp Integration" />
          <div className="marketplace-title marketplace-details-title">
            <h2>WhatsApp Integration</h2>
          </div>
          {/* marketplace items */}
          {/* <div className="marketplace-item marketplace-details-item">
              <div className="marketplace-item-thumb">
                <img src={marketplace} alt="marketplace icon" />
              </div>
              <p>
                To integrate with WhatsApp, please subscribe to one of ourpackages below. Upon subscription, we will send you the relevant information via email to facilitate the activation of the chatbot. Before subscribing to WhatsApp Business, please ensure essential account details are available such as WhatsApp Business Number and Facebook Business Manager ID.
              </p>
  
              <SelectPrice value={value} setValue={setValue}/>
              <WaUserTypeRadio />
              
              <div className="wa-details-action">
                
                <button 
                  className="btn marketplace-btn subscribe-btn" 
                  onClick={subsCriptionPayNow} 
                  disabled={userInfo.whatsapp_subscription_id}>
                    {loading ? 'Loading...' : 'Subscribe'}
                </button> 
              </div>
            </div> */}
          {
            userInfo.subscription_id === '6511e925469414f7e4262d88' ? <div className="marketplace-item marketplace-details-item">
              {/* <div className="popover-wrap">
                <Popover content={content}>
                  <BsFillExclamationCircleFill />
                </Popover>
              </div> */}
  
              <div className="marketplace-item-thumb">
                <img src={marketplace} alt="marketplace icon" />
              </div>
              <p>
                To integrate with WhatsApp, please subscribe to one of our packages below. Upon subscription, we will send you the relevant information via email to facilitate the activation of the chatbot. Before subscribing to WhatsApp Business, please ensure essential account details are available such as WhatsApp Business account number, Messenger page name, and Messenger page ID.
              </p>
  
              <SelectPrice value={value} setValue={setValue}/>

              {/* checkbox */}
              <WaUserTypeRadio setNewNumber={setNewNumber} newNumber={newNumber}/>
              
              <div className="wa-details-action">
                
                <button 
                  className="btn marketplace-btn subscribe-btn" 
                  onClick={subsCriptionPayNow} 
                  disabled={userInfo.whatsapp_subscription_id}>
                    {loading ? 'Loading...' : 'Subscribe'}
                </button> 
              </div>
  
              {/* <a href="https://stripe.com/">
                <button className="btn marketplace-btn subscribe-btn unsubscribe">
                  Unsubscribe
                </button> 
              </a> */}
  
            </div> : <div className="marketplace-item marketplace-details-item">
            {/* <div className="popover-wrap">
              <Popover content={content}>
                <BsFillExclamationCircleFill />
              </Popover>
            </div> */}

            <div className="marketplace-item-thumb">
              <img src={marketplace} alt="marketplace icon" />
            </div>
            <p>
            This Add-On allows you to integrate our chatbot into your business’ WhatsApp or Messenger! Send us integration request to implement this feature for you!
            </p>

            <div className="wa-details-action">
              
              {/* <Link onClick={() => setShowPopup(!showPopup)} to="#">
                <div className='download-btn-wrap'>
                  <span>Contact Us</span>
                </div>
              </Link> */}
              <Link onClick={handleNotify} to="#">
                <div className='download-btn-wrap'>
                  <span>Contact Us</span>
                </div>
              </Link>
            </div>

            {/* <a href="https://stripe.com/">
              <button className="btn marketplace-btn subscribe-btn unsubscribe">
                Unsubscribe
              </button> 
            </a> */}

          </div>
          }
        </div>

        {showPopup && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap">
              <BsX onClick={() => setShowPopup(!showPopup)} className='cancel-icon' />
              <h3>WhatsApp Integration</h3>
              <div className="form-group">
                <input type="email" value={email} placeholder='Enter your email here...' onChange={handleEmailChange} />
                {emailError && <p className="error-message">{emailError}</p>}
              </div>
              <button className='btn marketplace-btn' onClick={handleNotify}>Contact Us</button>      
            </div>
          </div>
        )}

        {isValidEmail && (
          <div className="marketplace-popup-container">
            <div className="marketplace-popup-wrap">
              <BsX onClick={() => setIsValidEmail(false)} className='cancel-icon' />
              <div className="marketplace-item-thumb">
                <img src={mail} alt="marketplace icon" />
              </div>
              <h3>Successful, dont forget to check your email.</h3>
              <p>Our representative will contact you via email within 24 hours regarding the integration.</p>
              <button className='btn marketplace-btn' onClick={() => navigate('/marketplace')}>Ok</button>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default WhatsappDetailScreen;
