import React from 'react'

function TextSection({t}) {
  return (
    <>
      <section className='bg-white py-10 sm:py-20 xs:py-16'>
        <div className="container">
          <div className="max-w-[90%]">
            <p className='mb-4 text-base font-medium text-brand-color'>ACEVA</p>
            <p className='mb-4 text-2xl sm:text-3xl !leading-[37px] sm:leading-[48px] font-medium text-black'>{t?.textSection.description}</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default TextSection