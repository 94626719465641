import React from 'react'
import { Link } from 'react-router-dom'

function BusinessGoalModal({setModalOpen}) {
  return (
    <>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-[9999]">
            <div className="bg-white p-7 rounded-lg flex justify-start items-start flex-col max-w-[600px] gap-2">
              {/* <p className="absulate">X</p> */}
              <h2 className="text-2xl font-semibold mb-2">Examples & Tips</h2>
              <div className="space-y-4">

                <p className='text-left'>💡 Include CTAs in your chatbot to guide users toward actions that benefit your business. A well-placed CTA encourages engagement and drives results.</p>

                <p className='text-left text-gray-700 font-semiBold'>Here are some examples:</p>
                <div className="bg-gray-100 p-4 rounded-md flex flex-col gap-2 justify-start">
                  <p className='text-left'> <span className="text-gray-700 font-semiBold">Increase sales: </span> Ask customers for their name, email, and phone number before ending the chat.</p>

                  <p className='text-left'> <span className="text-gray-700 font-semiBold">Schedule appointments: </span>  Provide a direct link to your booking page, e.g., <Link to="www.calendly.com/mylink">Calendly</Link>.</p>

                  <p className='text-left'> <span className="text-gray-700 font-semiBold">Promote events: </span> Direct users to your latest events page, e.g., Latest Events.</p>
                </div>
                <p className='text-left '> <span className="font-medium text-brand-color-bold">💡 Pro Tip: </span> Make your CTAs clear and link to valuable resources to boost user engagement.</p>
              </div>
              <button
                onClick={() => setModalOpen(false)}
                className="mt-4 bg-brand-color text-white font-semiBold px-4 py-2 rounded-lg hover:bg-brand-color-dark"
              >
                Close
              </button>
            </div>
          </div>
    </>
  )
}

export default BusinessGoalModal