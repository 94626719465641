import { Trash2 } from 'lucide-react'
import axios from 'axios';
import { useRef, useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import Layout from '../../components/Layout'
import BusinessGoalModal from '../../components/create-bot-steps/BusinessGoalModal'
import { apiUrl } from '../../Variables';

export default function UpdateBusinessGoalScreen({toggleLoggedInState}) {
  const navigate = useNavigate()
  const [createLoading, setCreateLoading] = useState(false)
  const [goals, setGoals] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [chatbots, setChatbots] = useState(null);
  const [selectedBot, setSelectedBot] = useState(null);

  useEffect(() => {

    const featchBots = async () => {
      // Retrieve userInfo from localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      try {
        const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
        setSelectedBot({
            value: {
              id: data[0]?._id,
              scrapIds: data[0]?.scrappedOrUpDataId?.toString(),
              configData: data[0]?.configData
            },
            label: data[0]?.name,
          });
          setGoals(data[0]?.configData?.businessGoals || [])
        setChatbots(data);
      } catch (error) {
        console.log('scrap list fetching fail:', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
        return {};
      }
    };
      // setLoading(true);
      featchBots();
    }, []);
  
  // Reference to the last input for auto focus
  const inputRefs = useRef({});

  const botOptions = (chatbots || []).map((item) => ({
    value: {
      id: item?._id,
      scrapIds: item?.scrappedOrUpDataId?.toString(),
      configData: item?.configData,
    },
    label: item.name,
  }));

  const goalOptions = [
    {
      value: 'demo',
      label: 'Schedule a Demo Meeting',
      description: 'If the customer requests a product demo, send out demo link: Https://calendly.com',
    },
    {
      value: 'video',
      label: 'Watch a Demo Video',
      description: 'When the customer is exploring the services/products, send out the link to demo video page',
    },
    {
      value: 'offer',
      label: 'Give a Special Offer',
      description: 'When the customer is exploring the services/products, send out the offer for this service',
    },
    {
      value: 'page',
      label: 'Direct to a Page',
      description: 'If the customer is looking for detailed information about services or products, include the relevant page link.',
    },
    {
      value: 'form',
      label: 'Ask to Fill Out a Form',
      description: 'At the end of the chat, ask the customers to fill out the form, or click here to get started!',
    },
    {
      value: 'other',
      description: 'Other...',
      label: 'Other',
    },
  ];

  const addGoal = (goal) => {
    if (goals?.length < 3) {
      const newGoal = { id: Date.now(), example: goal.description, label: goal.label };
      setGoals([...goals, newGoal]);

      // Focus on the input after adding a goal
      setTimeout(() => {
        inputRefs.current[newGoal.id]?.focus();
      }, 0);
    } else {
      toast.error('Free users can only create 3 CTAs');
    }
  };

  const removeGoal = (id) => {
    setGoals(goals.filter(goal => goal.id !== id));
  };

  const submitUpdateHandler = async () => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    try {
      setCreateLoading(true)
      console.log(selectedBot, goals)
      const res = await axios.get(`${apiUrl}/botConfig/${selectedBot.value.id}`, { headers });
      const { data } = await axios.put(`${apiUrl}/botConfig/${res.data[0]?._id}`, {_id: res.data[0]?._id, configData: {...res.data[0]?.configData, businessGoals: goals}, chatbotId: selectedBot.value.id}, { headers });
      console.log('response data:', data)
      setCreateLoading(false)
      toast.success('Business Goal updated successfully!')
      // navigate('/chatbot-list')
    } catch (error) {
      setCreateLoading(false)
      console.error('Error to bot config update:', error);
      toast.error(`Failed: ${error?.message || error?.response?.data}`)
    }
  };

  return (
    <Layout toggleLoggedInState={toggleLoggedInState}>
        {/* AllChatbot Lists */}
        <div className="all-bot-list">
          {
            selectedBot?.label === 'All' ? (
            <div className="list-bot-logo no-bot">
              <svg className='w-10 h-10 p-[6px]' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
            </div>
            ) : selectedBot?.value?.configData?.logo ? (
              <div className="list-bot-logo">
                <img src={selectedBot?.value?.configData?.logo} alt="bot icon" />
              </div>
              ) : (
              <div className="list-bot-logo no-bot">
                  <svg className='w-10 h-10 p-[6px]'  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
              )
          }
          <Select
            onChange={(selected) => {
              setSelectedBot(selected);
              setGoals(selected?.value?.configData?.businessGoals || [])
            }}
            value={selectedBot}
            options={[...botOptions]}
            placeholder="Select a bot..."
            className='bot-dropdown'
          />
        </div>
      <div className="p-6 bg-white rounded my-10 border border-gray-200">
        <Toaster />
        <h2 className="text-2xl font-semiBold mb-6">Business Goal</h2>
        <p className="mb-4 text-gray-600">
          🎯 Set clear business goals with ACEVA GPT, and we’ll build a tailored chatbot to drive your success.
        </p>

        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start mb-4 gap-3">
          <Select
            options={goalOptions}
            placeholder="Add your business goal here"
            onChange={(selectedOption) => addGoal(selectedOption)}
            className="clear-default-format-selectbox"
          />
          <button
            style={{ border: '1px solid #e5e7eb' }}
            onClick={() => setModalOpen(true)}
            className="text-gray-700 font-normal px-4 py-[6px] rounded hover:bg-gray-100 border !border-gray-200 w-full sm:w-auto"
          >
            Examples & Tips
          </button>
        </div>

        {goals?.map((goal) => (
          <div key={goal.id} className="flex items-center space-x-2 mb-2">
            <input
              ref={(el) => (inputRefs.current[goal.id] = el)}
              type="text"
              placeholder={goal.example}
              value={goal.text}
              onChange={(e) => {
                const updatedGoals = goals.map(g =>
                  g.id === goal.id ? { ...g, text: e.target.value } : g
                );
                setGoals(updatedGoals);
              }}
              className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-brand-color focus:border-brand-color"
            />
            <button
              onClick={() => removeGoal(goal.id)}
              className="p-2 text-red-500 hover:text-red-700 focus:outline-none"
            >
              <Trash2 className="w-5 h-5 text-red-500 hover:text-red-700" />
            </button>
          </div>
        ))}

        {/* Modal for Examples & Tips */}
        {modalOpen && (
          <BusinessGoalModal setModalOpen={setModalOpen} />
        )}

        <button
          className="mt-6 px-6 py-3 bg-brand-color text-white hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-brand-color focus:ring-offset-2 w-auto m-auto font-semibold rounded-[10px]"
          onClick={submitUpdateHandler}
        >
          {createLoading? 'Updating...' : 'Save Changes'}
        </button>
      </div>
    </Layout>
  );
}
