import React from 'react'

function Loading() {
  return (
    <div className="loading-wrapper">
      {/* <div className='loading'></div> */}
      <div className="loader3">
          <span></span>
          <span></span>

      </div>
    </div>
  )
}

export default Loading