import React from 'react'
import "react-datepicker/dist/react-datepicker.css"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import textLogo from '../../images/aceva.png'

function ConfigBotBody({config, setConfig, selectedPrompt}) {

  // const chatRef = useRef(null)

  // useEffect(() => {
  //   chatRef.current?.scrollIntoView({ behavior: "smooth" })
  // },[])

  return (
    <ul>
      <li>
        <img className='agent-logo' src={config?.logo ? config?.logo : textLogo} alt="bot" />
        <div className="typing-wrap message-bubble-agent ">
          <p className='message-bubble-agent'>{config?.welcomeMessage || 'Hi, I am your assistant. How may I help you?'}</p>
        </div>
      </li> 

      {/* <li className="message-bubble-visitor date-bubble-item chatbot-config-bubble-user" style={{background: config?.brandColor}}>
        <span className=''>Hi there</span>
        <span className=''>What is chatbot?</span>
        <div className="messageTimestamp fade-enter-done">Just now</div>
        <div className="messageTimestamp fade-enter-done"></div>
      </li> */}
        
      {
        selectedPrompt && <li className="message-bubble-visitor date-bubble-item chatbot-config-bubble-user" style={{background: config?.brandColor}}>
          <span className=''>{selectedPrompt}</span>
          <div className="messageTimestamp fade-enter-done">Just now</div>
          <div className="messageTimestamp fade-enter-done"></div>
        </li>
      }

      {/* <li>
        <img className='agent-logo' src={config?.logo ? config?.logo : textLogo} alt="bot" />
        <div className="typing-wrap message-bubble-agent">
          <span className="tying">Typing</span>
          <span className="animate-typing">
            <span className="dot ms-1"></span>
            <span className="dot ms-1"></span>
            <span className="dot ms-1"></span>
          </span>
        </div>
      </li>  */}
      {/* <li className='ref-li' ref={chatRef}></li> */}
    </ul>
    )
  }

  export default ConfigBotBody
