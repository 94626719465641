import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsPlusSquareDotted, BsX } from 'react-icons/bs';
import { FiFile, FiTrash } from 'react-icons/fi';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// import {MdRestartAlt} from 'react-icons/gr'
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { apiUrl } from '../../Variables';
import CreateUserModel from '../../components/CreateUserModel';
import UpdateUserModel from '../../components/UpdateUserModel';
import TrainFiles from '../../components/steps/TrainFiles';
import TrainLinks from '../../components/steps/TrainLinks';

import { AlertCircle } from 'lucide-react';
import { BsPlusLg } from 'react-icons/bs';
import {
  PiCheckSquareThin,
  PiMinusSquareThin,
  PiSquareThin
} from 'react-icons/pi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
import Layout from '../../components/Layout';
import MiniLoading from '../../components/MiniLoading';
import ListSidebar from '../../components/steps/ListSidebar';
import logo from '../../images/error-img.png';

function ListOfFileScreen({ toggleLoggedInState }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ids, setIds] = useState(searchParams.get('ids') || "");
  const [botId, setBotId] = useState(searchParams.get('botId') || "");

  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo'))
  );
  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [showTrainAllBtn, setShowTrainAllBtn] = useState(true);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [scrapData, setScrapData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  });
  const [editUserInfo, setEditUserInfo] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [updateFileModalShow, setupdateFileModalShow] = useState(false);
  const [filterRoleValue, setFilterRoleValue] = useState('all');
  const [singleData, setSingleData] = useState({});
  const [sidebar, setSidebar] = useState(false);
  const [updateFileId, setUpdateFileId] = useState('');
  // const [chatbots, setChatbots] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);
  // const [selectedBot, setSelectedBot] = useState(JSON.parse(localStorage.getItem('selectedBot')) || null);
  // const [selectedBot, setSelectedBot] = useState(null);

  const [selectedBot, setSelectedBot] = useState(null);
  const [chatbots, setChatbots] = useState(null);
  const [botDetails, setBotDetails] = useState({});

  const [activeTab, setActiveTab] = useState('links');

  const navigate = useNavigate();

  useEffect(() => {

    const featchBots = async () => {
      // Retrieve userInfo from localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      try {
        const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
        if (JSON.parse(localStorage.getItem('selectedBot'))) {
          setSelectedBot(
            JSON.parse(localStorage.getItem('selectedBot'))
          );
        } else if (!ids) {
          setSelectedBot({
            value: {
              id: data[0]?._id,
              scrapIds: data[0]?.scrappedOrUpDataId?.toString(),
              configData: data[0]?.configData
            },
            label: data[0]?.name,
          });
        }
        if (JSON.parse(localStorage.getItem('selectedBot')) && !ids) {
          setBotId(
            JSON.parse(localStorage.getItem('selectedBot'))?.value?.id
          );
          setIds(
            data
              ?.find(
                (item) =>
                  item._id ===
                  JSON.parse(localStorage.getItem('selectedBot'))?.value?.id
              )
              ?.scrappedOrUpDataId?.toString()
          );
        } else if (!ids) {
          setBotId(
            data[0]?._id
          );
          setIds(
            data[0]?.scrappedOrUpDataId?.toString()
          );
        }
        setChatbots(data);
      } catch (error) {
        console.log('******************************scrap list fetching fail******************************\n', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
        return {};
      }
    };
    // setLoading(true);
    featchBots();
  }, [navigate]);

  useEffect(() => {

    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async (page = 1) => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/scrap/scrappedList`, {
          params: { page, limit: pagination.pageSize, ids },
          headers,
        });
        if (response.data == "") {
          throw "No data found for this chatbot!"
        }
        const { data, pagination: paginationData } = response.data;
        setPagination(paginationData);
        setScrapData(data);

        if (botId) {
          const selectedBot = chatbots.find((i) => i._id === botId);
          if (selectedBot) {
            setSelectedBot({
              value: {
                id: selectedBot._id,
                scrapIds: selectedBot.scrappedOrUpDataId?.toString(),
                configData: selectedBot.configData,
              },
              label: selectedBot.name,
            });
            console.log("================================\nScrapped IDS FROM BOT: ", selectedBot._id,)
            console.log(ids)
            console.log("================================")
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setScrapData([]);
        console.log('******************************scrap list fetching fail******************************\n', error);
        // toast.error(`Error: ${error?.message || error?.response?.data}`);
        // toast.error(`No Data Found!`);
      }
    };

    if (chatbots) {
      featchData(pagination.currentPage);
    }
  }, [deleteSuccess, navigate, chatbots, ids, botId, pagination.currentPage]);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }));
    }
  };

  //update bot link function
  const updateBotLink = async (ids, status) => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    await axios
      .get(`${apiUrl}/chatbot/${botId}`, { headers })
      .then(async (res1) => {
        console.log(res1);
        let newData = [];
        if (status === 'r') {
          newData = [
            ...res1.data[0].scrappedOrUpDataId?.filter(
              (item) => !ids?.includes(item)
            ),
          ];
        } else {
          newData = [...res1.data[0].scrappedOrUpDataId, ...ids];
        }
        await axios
          .put(
            `${apiUrl}/chatbot/${botId}`,
            {
              ...res1.data[0],
              scrappedOrUpDataId: newData,
            },
            { headers }
          )
          .then(async (res2) => {
            const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
            setIds(
              data?.find((i) => i._id === botId)?.scrappedOrUpDataId?.toString()
            );
          })
          .catch((error) => {
            console.error('Error to bot update:', error);
            toast.error(
              `Failed: ${error?.response?.message || error?.response?.data}`
            );
          });
      })
      .catch((error) => {
        console.error('Error to bot update:', error);
      });
  };

  const handleDeleteAll = async () => {
    // Show the confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this item!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      // User confirmed deletion
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
        setLoading(true);
        setDeleteSuccess(false);

        await axios.post(
          `${apiUrl}/scrap/deleteAll`,
          { selectedItems },
          { headers }
        );
        //unlinked links from bot
        await updateBotLink(selectedItems, 'r');
        toast.success('Deleted successfully!');
        setScrapData(
          scrapData?.filter((item) => !selectedItems?.includes(item._id))
        );
        setSelectedItems([]);
        setDeleteSuccess(!deleteSuccess);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error deleting:', error);
        toast.error(
          `Failed to delete: ${error?.response?.data.message || error?.message}`
        );
        setDeleteSuccess(false);
      }
    }
  };
  // user delete handler
  const deleteBotHandler = async (id) => {
    // Show the confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, the link will be dislinked from bot!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      // User confirmed deletion
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }
        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };
        console.log("HEADERS: ", headers)
        console.log("ID: ", id)
        setLoading(true);
        setDeleteSuccess(false);
        await axios.delete(
          `${apiUrl}/scrap/${id}`,
          { headers }
        );
        await updateBotLink([id], 'r');
        toast.success('Deleted successfully!');
        setDeleteSuccess(!deleteSuccess);
      } catch (error) {
        setLoading(false);
        console.error('Error deleting:', error);
        toast.error(
          `Failed to delete: ${error?.response?.data.message || error?.message}`
        );
        setDeleteSuccess(false);
      }
    }
  };

  const handleItemSelection = (item) => {
    console.log(selectedItems);
    if (selectedItems?.includes(item._id)) {
      setSelectedItems([...selectedItems?.filter((i) => i !== item._id)]);
    } else {
      // if (selectedItems?.length > 4) {
      //   toast.error('You can only select up to 5 items.');
      // } else {
      setSelectedItems([...selectedItems, item._id]);
      // }
    }
  };

  const handleLinkSelection = (itemName) => {
    if (selectedLinks?.includes(itemName)) {
      setSelectedLinks(selectedLinks?.filter((item) => item !== itemName));
    } else {
      setSelectedLinks([...selectedLinks, itemName]);
    }
  };

  const updateFileData = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    //send last item in array, since its new file
    await axios
      .post(
        `${apiUrl}/scrap/storePdfData`,
        { selectedLinks: selectedLinks[selectedLinks.length - 1], id: updateFileId, botId: botId },
        { headers }
      )
      .then(async (res) => {
        await updateBotLink([...res.data.scrappedIds], 'a');
        setLoading(false);
        setDeleteSuccess(!deleteSuccess);
        setupdateFileModalShow(!updateFileModalShow);
        setSelectedLinks([]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data);
      });
  };


  const addNewLink = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    if (selectedLinks[0].upFileName) {
      console.log(selectedLinks)
      await axios
        .post(`${apiUrl}/scrap/storePdfData`, { selectedLinks: selectedLinks, botId: botId }, { headers })
        .then(async (res) => {
          await updateBotLink([...res.data.scrappedIds], 'a');
          setLoading(false);
          setDeleteSuccess(!deleteSuccess);
          setCreateModalShow(!createModalShow);
          setSelectedLinks([]);
          // window.location.reload();
          setDeleteSuccess(!deleteSuccess);
        })
        .catch((err) => {
          setLoading(false);
          setCreateModalShow(!createModalShow);
          // toast.error(err.response.data);
          toast.error("New knowledge adding failed!");
        });
    } else {
      await axios
        .post(
          `${apiUrl}/scrap/scrapPages`,
          { page_urls: selectedLinks, botId },
          { headers }
        )
        .then(async (res) => {
          await updateBotLink([...res.data.scrappedIds], 'a');
          setLoading(false);
          setDeleteSuccess(!deleteSuccess);
          setCreateModalShow(!createModalShow);
          setSelectedLinks([]);
          // window.location.reload();
          setDeleteSuccess(!deleteSuccess);
        })
        .catch((error) => {
          console.error('Error:', error);
          // toast.error(`Failed: ${error?.response?.data?.message}`);
          setLoading(false);
          setCreateModalShow(!createModalShow);
          // toast.error(err.response.data);
          toast.error("New knowledge adding failed!");
        });
    }
  };
  const retrain = async (item) => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setLoading(true);
    await axios
      .post(
        `${apiUrl}/scrap/scrapPages`,
        { page_urls: [item.sourceURL], botId },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        toast.success('Retraining completed.');
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        // toast.error(`Failed: ${error?.response?.data?.message}`);
        toast.error(`Retraining failed due to server error. Try again later.`);
      });
  };

  // Retrain All Handler
  const retrainAll = async (itm) => {
    // Add headers to include JWT token
    if (selectedItems.length > 5) {
      toast.error('Batch retrain is limited to 5 items at a time. Try again.');
      return 0;
    }
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    setLoading(true);

    await axios
      .get(`${apiUrl}/scrap/scrappedList`, {
        params: { page: 1, limit: 1000, ids: selectedItems?.toString() },
        headers,
      })
      .then(async (res) => {
        const dataArr = [];
        for (const item of res.data.data) {
          dataArr.push(item.sourceURL);
        }
        await axios
          .post(
            `${apiUrl}/scrap/scrapPages`,
            { page_urls: dataArr, botId },
            { headers }
          )
          .then((res) => {
            setLoading(false);
            toast.success('Retraining completed');
          })
          .catch((error) => {
            setLoading(false);
            console.error('Error:', error);
            toast.error(`Failed: ${error?.response?.data?.message}`);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log('scrap list fetching fail:', error);
        // toast.error(`Error: ${error?.message || error?.response?.data}`)
        toast.error(`Retraining failed due to server error. Try again later.`);
      });
  };

  // Utility function to fetch user info and headers
  const fetchUserInfoAndHeaders = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return null;
    }

    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    return { userInfo, headers };
  };

  const botOptions = (chatbots || []).map((item) => ({
    value: {
      id: item?._id,
      scrapIds: item?.scrappedOrUpDataId?.toString(),
      configData: item?.configData,
    },
    label: item.name,
  }));


  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: '210px',
      height: '132px',
      overflowY: 'scroll',
      border: '1px solid rgb(147, 147, 147)',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'aliceblue',
      border: '1px solid rgb(147, 147, 147)',
      fontWeight: '700',
      boxShadow: 'none',
      height: '48px',
      width: '210px',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected || isFocused ? 'aliceblue' : 'white',
      color: 'black',
    }),
  };



  return (
    <>
      <Helmet>
        <title>Links | ACEVA Dashboard</title>
      </Helmet>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        {loading && <MiniLoading />}
        <CreateUserModel
          createUser={createUser}
          setResult={setResult}
          setCreateUser={setCreateUser}
        />
        <UpdateUserModel
          updateSuccess={updateSuccess}
          setUpdateSuccess={setUpdateSuccess}
          editUserInfo={editUserInfo}
          setEditUserInfo={setEditUserInfo}
          editUser={editUser}
          setEditUser={setEditUser}
        />

        <ListSidebar
          sidebar={sidebar}
          setSidebar={setSidebar}
          singleData={singleData}
        />
        {/* AllChatbot Lists */}
        <div className="all-bot-list">
          {
            selectedBot?.label === 'All' ? (
              <div className="list-bot-logo no-bot">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
            ) : selectedBot?.value?.configData?.logo ? (
              <div className="list-bot-logo">
                <img src={selectedBot?.value?.configData?.logo} alt="bot icon" />
              </div>
            ) : (
              <div className="list-bot-logo no-bot">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
              </div>
            )
          }
          <Select
            onChange={(selected) => {
              searchParams.delete('ids');
              searchParams.delete('botId');
              setSearchParams(searchParams);
              setIds(selected.value?.scrapIds);
              setSelectedBot(selected);
              // if (selected.label !== 'All') {
              //   localStorage.setItem('selectedBot', JSON.stringify(selected));
              // } else {
              localStorage.removeItem('selectedBot');
              // }
              setBotId(selected.value?.id);
            }}
            value={selectedBot}
            options={[...botOptions]}
            placeholder="Select a bot..."
            className='bot-dropdown'
          />
        </div>

        <div className="table-container mt-3">
          <div className="table-title table-title-wrap">
            <h3>Library</h3>
            <p>Retrain or update links and files here</p>
          </div>
          <Tabs style={{ marginTop: '30px' }}>
            <TabList className="create-chatbot-steps">
              <Tab
                className="create-chatbot-step-item"
                onClick={() => {
                  setSelectedItems([]);
                  setShowTrainAllBtn(true);
                }}
              >
                Links
              </Tab>
              <Tab
                className="create-chatbot-step-item"
                onClick={() => {
                  setSelectedItems([]);
                  setShowTrainAllBtn(false);
                }}
              >
                Files
              </Tab>
            </TabList>
            <div className="add-file-or-link-btn-wrap">
              {botId && (
                <button
                  className="btn form-button"
                  onClick={() => {
                    setCreateModalShow(!createModalShow);
                  }}
                >
                  <BsPlusLg style={{ color: 'white' }} />{' '}
                  <span>Add Files/Links</span>
                </button>
              )}
              {/* Retrain All Button */}
              {showTrainAllBtn &&
                scrapData?.length > 0 &&
                selectedItems.length > 0 && (
                  <button
                    style={{
                      background: 'none',
                      boxShadow: 'none',
                      color: 'var(--brand-color)',
                      border: '1px solid var(--brand-color)',
                      margin: '0 0 0 10px',
                    }}
                    className="btn form-button"
                    onClick={retrainAll}
                  >
                    Retrain All
                  </button>
                )}

              {/* <Link to='/create-chatbot-link'> */}
              {scrapData?.length > 0 && selectedItems.length > 0 && (
                <button
                  style={{
                    background: 'none',
                    boxShadow: 'none',
                    border: '1px solid var(--brand-color)',
                    margin: '0 0 0 10px',
                  }}
                  className="btn form-button"
                  onClick={handleDeleteAll}
                >
                  <RiDeleteBin6Line
                    style={{
                      color: 'var(--brand-color)',
                    }}
                  />
                </button>
              )}
            </div>

            <TabPanel className="create-chatbot-content">
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <div className="list-of-the-bot-item" key={index}>
                    <div className="list-bot-profile">
                      <Skeleton width={15} height={15} />
                      <div className="list-bot-content">
                        <Skeleton height={20} width={200} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={100} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={100} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={200} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-span-flex">
                        <Skeleton width={20} />
                        <Skeleton width={20} />
                        <Skeleton width={20} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="table-wrapper">
                  {scrapData?.length > 0 ? (
                    <table>
                      {/* header */}
                      <tr className="header-upper">
                        <th>
                          <div
                            onClick={() => {
                              if (
                                selectedItems.length ===
                                scrapData?.filter((item) => item?.sourceURL)
                                  .length ||
                                selectedItems.length > 0
                              ) {
                                setSelectedItems([]);
                              } else {
                                setSelectedItems(
                                  scrapData
                                    ?.filter((item) => item?.sourceURL)
                                    ?.map((item) => item._id)
                                );
                              }
                            }}
                          >
                            {selectedItems.length > 0 &&
                              selectedItems.length !==
                              scrapData?.filter((item) => item?.sourceURL)
                                .length ? (
                              <PiMinusSquareThin
                                style={{ color: 'var(--brand-color)' }}
                              />
                            ) : selectedItems.length ===
                              scrapData?.filter((item) => item?.sourceURL)
                                .length ? (
                              <PiCheckSquareThin
                                style={{ color: 'var(--brand-color)' }}
                              />
                            ) : (
                              <PiSquareThin />
                            )}
                          </div>
                        </th>
                        <th>Item</th>
                        <th>Modified On</th>
                        <th>Last Trained</th>
                        <th>Actions</th>
                      </tr>
                      <tbody>
                        {scrapData
                          ?.filter((item) => item.sourceURL)
                          ?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div onClick={() => handleItemSelection(item)}>
                                  {selectedItems?.includes(item._id) ? (
                                    <PiCheckSquareThin
                                      style={{ color: 'var(--brand-color)' }}
                                    />
                                  ) : (
                                    <PiSquareThin />
                                  )}
                                </div>
                              </td>

                              <td>
                                <span className="table-name">
                                  {item?.sourceURL}
                                </span>
                              </td>

                              <td>
                                {/* <span className='badge admin-badge'>Active</span> */}
                                {moment(item?.updatedAt).format(
                                  'MMMM Do, YYYY'
                                )}
                              </td>

                              <td>
                                {moment(item?.createdAt).format(
                                  'MMMM Do, YYYY'
                                )}
                              </td>
                              <td>
                                <div className="action-wrapper">
                                  {/* <BsEye 
                                          onClick={() => {
                                            singleDataFunc(item)
                                            setSidebar(!sidebar)
                                          }}
                                        /> */}
                                  <span
                                    onClick={() => retrain(item)}
                                    className="badge admin-badge"
                                    title="Retrain"
                                  >
                                    Retrain
                                    {/* <BiRefresh className='edit-icon'  /> */}
                                  </span>
                                  <div
                                    onClick={() =>
                                      deleteBotHandler(item?._id)
                                    }
                                  >
                                    <RiDeleteBin6Line className="delete-icon" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    scrapData?.length === 0 && (
                      <div className="flex items-center justify-center text-red-500 bg-red-50 py-6 mt-3 px-3 rounded-md animate-fade-in p-10">
                        <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0 text-red-500" />
                        <span className="text-sm font-medium">No links found, please add new links</span>
                      </div>
                    )
                  )}
                </div>
              )}
            </TabPanel>

            <TabPanel className="create-chatbot-content">
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <div className="list-of-the-bot-item" key={index}>
                    <div className="list-bot-profile">
                      <Skeleton width={15} height={15} />
                      <div className="list-bot-content">
                        <Skeleton height={20} width={200} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={100} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={100} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-bot-content">
                        <Skeleton height={20} width={200} />
                      </div>
                    </div>
                    <div className="list-bot-option-wrap">
                      <div className="list-span-flex">
                        <Skeleton width={20} />
                        <Skeleton width={20} />
                        <Skeleton width={20} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="table-wrapper">
                  {scrapData?.length > 0 ? (
                    <table>
                      {/* header */}
                      <tr className="header-upper">
                        <th>
                          <div
                            onClick={() => {
                              if (
                                selectedItems.length ===
                                scrapData?.filter((item) => item?.upFileName)
                                  .length ||
                                selectedItems.length > 0
                              ) {
                                setSelectedItems([]);
                              } else {
                                setSelectedItems(
                                  scrapData
                                    ?.filter((item) => item?.upFileName)
                                    ?.map((item) => item._id)
                                );
                              }
                            }}
                          >
                            {selectedItems.length > 0 &&
                              selectedItems.length !==
                              scrapData?.filter((item) => item?.upFileName)
                                .length ? (
                              <PiMinusSquareThin
                                style={{ color: 'var(--brand-color)' }}
                              />
                            ) : selectedItems.length ===
                              scrapData?.filter((item) => item?.upFileName)
                                .length ? (
                              <PiCheckSquareThin
                                style={{ color: 'var(--brand-color)' }}
                              />
                            ) : (
                              <PiSquareThin />
                            )}
                          </div>
                        </th>
                        <th>Item</th>
                        <th>Modified On</th>
                        <th>Last Trained</th>
                        <th>Actions</th>
                      </tr>
                      <tbody>
                        {scrapData
                          ?.filter((item) => !item.sourceURL)
                          ?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div onClick={() => handleItemSelection(item)}>
                                  {selectedItems?.includes(item._id) ? (
                                    <PiCheckSquareThin
                                      style={{ color: 'var(--brand-color)' }}
                                    />
                                  ) : (
                                    <PiSquareThin />
                                  )}
                                </div>
                              </td>

                              <td>
                                <span className="table-name">
                                  {item?.upFileName}
                                </span>
                              </td>

                              <td>
                                {/* <span className='badge admin-badge'>Active</span> */}
                                {moment(item?.updatedAt).format(
                                  'MMMM Do, YYYY'
                                )}
                              </td>

                              <td>
                                {moment(item?.createdAt).format(
                                  'MMMM Do, YYYY'
                                )}
                              </td>
                              <td>
                                <div className="action-wrapper">
                                  {/* <BsEye 
                                          onClick={() => {
                                            singleDataFunc(item)
                                            setSidebar(!sidebar)
                                          }}
                                        /> */}
                                  <span
                                    onClick={() => {
                                      setupdateFileModalShow(
                                        !updateFileModalShow
                                      );
                                      setSelectedLinks([
                                        {
                                          upFileName: item.upFileName,
                                          contentOfUp: item.contentOfUp,
                                        },
                                      ]);
                                      setUpdateFileId(item._id);
                                    }}
                                    className="badge admin-badge"
                                    title="Retrain"
                                  >
                                    Update
                                    {/* <BiRefresh className='edit-icon'  /> */}
                                  </span>

                                  <div
                                    onClick={() =>
                                      deleteBotHandler(item?._id)
                                    }
                                  >
                                    <RiDeleteBin6Line className="delete-icon" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    scrapData?.length === 0 && (
                      <div className="table-data-not-found">
                        No file found, Please add new file.
                      </div>
                    )
                  )}
                </div>
              )}
            </TabPanel>
          </Tabs>
          {/* {botUpgrade && <BotupgrateAlart toggle={botUpgradeToggle} />} */}
        </div>

        {/* Add new file or link modal */}
        <div
          className={`fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 ${createModalShow ? 'visible' : 'invisible'
            }`}
        >
          <div className="bg-white rounded-lg w-full max-w-6xl mx-4">
            <div className="flex flex-col sm:flex-row min-h-[580px]">
              <div className="flex flex-col w-full sm:w-1/2 p-6">
                <div className="mb-2">
                  <h2 className="text-xl sm:text-2xl font-semibold">Data Source</h2>
                </div>

                <div className="custom-tabs">
                  <div className="tab-list flex border-b mb-6">
                    <div
                      className={`tab-item px-4 py-2 font-medium cursor-pointer ${activeTab === 'links' ? 'border-b-2 border-brand-color text-brand-color' : 'text-gray-500 hover:text-gray-700'}`}
                      onClick={() => {
                        setActiveTab('links');
                        setSelectedLinks([]);
                      }}
                    >
                      Links
                    </div>
                    <div
                      className={`tab-item px-4 py-2 font-medium cursor-pointer ${activeTab === 'files' ? 'border-b-2 border-brand-color text-brand-color' : 'text-gray-500 hover:text-gray-700'}`}
                      onClick={() => {
                        setActiveTab('files');
                        setSelectedLinks([]);
                      }}
                    >
                      Files
                    </div>
                  </div>

                  {activeTab === 'links' ? (
                    <TrainLinks
                      selectedItems={selectedLinks}
                      setSelectedItems={setSelectedLinks}
                    />
                  ) : (
                    <TrainFiles
                      selectedItems={selectedLinks}
                      setSelectedItems={setSelectedLinks}
                      botId={selectedBot?.value?.id}
                    />
                  )}
                </div>

                <button
                  type="button"
                  onClick={async () => {
                    if (selectedLinks.length < 1) {
                      toast.error('Please select at least one link.');
                      return 0;
                    }
                    addNewLink();
                  }}
                  className="w-full px-4 py-2 text-white bg-brand-color rounded hover:bg-brand-color-bold transition-colors mt-6"
                  disabled={loading}
                >
                  {loading ? 'Adding Knowledges...' : 'Add Knowledge'}
                </button>
              </div>

              {/* Preview Panel */}
              <div className="w-full sm:w-1/2 p-6 border-t sm:border-t-0 sm:border-l border-gray-200">
                <div className="relative">
                  <button
                    onClick={() => setCreateModalShow(false)}
                    className="absolute right-0 top-0 text-gray-500 hover:text-gray-700"
                  >
                    <BsX className="w-6 h-6" />
                  </button>
                  <h2 className="text-xl sm:text-2xl font-semibold mb-6">Added Links and Files</h2>

                  {selectedLinks?.length > 0 ? (
                    <div className={`overflow-y-auto ${selectedLinks?.length > 6 ? 'max-h-[450px]' : ''}`}>
                      {selectedLinks?.map((item, index) => (
                        <div key={index} className="flex items-center justify-between p-3 mb-2 bg-gray-50 rounded">
                          <div className="flex items-center">
                            <span className="text-gray-700">{item.upFileName || item}</span>
                          </div>
                          <button
                            onClick={() => handleLinkSelection(item)}
                            className="text-gray-500 hover:text-red-500"
                          >
                            {selectedLinks?.includes(item) ? (
                              <FiTrash className="w-5 h-5" />
                            ) : (
                              <BsPlusSquareDotted className="w-5 h-5" />
                            )}
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center p-6 sm:p-10 w-full h-full">
                      <img src={logo} alt="No data" className="w-20 sm:w-32 h-20 sm:h-32 mb-4" />
                      <span className="text-gray-500">Beep.. beep.. no data found.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pagination Controls */}
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <button
            style={{ color: 'blue' }}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            disabled={pagination.currentPage === 1}
          >
            Previous
          </button>
          <span style={{ margin: "0 10px" }}>
            Page {pagination.currentPage} of {pagination.totalPages}
          </span>
          <button
            style={{ color: 'blue' }}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            disabled={pagination.currentPage === pagination.totalPages}
          >
            Next
          </button>
        </div>

        {/* update file modal */}
        <div
          className={
            updateFileModalShow
              ? 'date-filter-model-wrapper active'
              : 'date-filter-model-wrapper'
          }
        >
          <div style={{ position: 'relative' }}>
            <BsX
              className="cancel-icon"
              style={{ zIndex: '99', top: '10px', right: '15px', color: 'black' }}
              size="35px"
              onClick={() => setupdateFileModalShow(!updateFileModalShow)

              }
            />
            <div
              className="create-chatbot-container"
              style={{ borderRadius: '10px', height: '200px' }}
            >
              <div>
                <Tabs>
                  <TabList className="create-chatbot-steps">
                    {/* <Tab className="create-chatbot-step-item">Update File</Tab> */}
                  </TabList>

                  <TabPanel className="create-chatbot-content">
                    <TrainFiles
                      selectedItems={selectedLinks}
                      setSelectedItems={setSelectedLinks}
                      onClick={() => setSelectedLinks([])}
                      botId={selectedBot?.value?.id}
                    />
                    {selectedLinks.length > 0 && (
                      <h6
                        style={{
                          marginTop: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FiFile size="20px" />
                        {selectedLinks[0]?.upFileName}
                      </h6>
                    )}
                  </TabPanel>
                </Tabs>
                {selectedLinks.length > 0 && (
                  <button
                    type="button"
                    onClick={async () => {
                      updateFileData();
                    }}
                    className="form-button"
                    style={{ margin: '50px 0 0 0' }}
                  >
                    {' '}
                    <span>
                      {loading ? 'Updating File...' : 'Update File'}
                    </span>{' '}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ListOfFileScreen;
