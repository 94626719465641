import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from '../Variables';

const AppContext = createContext({})
const AppContextProvider = (props) => {

    const [userInfo, setUserInfo] = useState(null)
    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [language, setLanguage] = useState('english');


    const navigate = useNavigate()

    // Load userInfo from localStorage on the client side
    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
        }

        // Retrieve userInfo from localStorage
        const getUserDetails = JSON.parse(localStorage.getItem('userInfo'));

        if (!getUserDetails || !getUserDetails.token) {
        console.error('User token not found.');
        navigate('/login')
        }

    }, [navigate]);

    console.log('userInfo AppContext', userInfo)

    // const {userInfo} = useAppContext(); 
    const location = useLocation();

    const { children, values = {} } = props
    return (
        <AppContext.Provider
            value={{
                userInfo,
                setUserInfo,
                apiUrl,
                sidebarToggle,
                setSidebarToggle,
                language, 
                setLanguage,
                ...values
            }}
        >
            {children}
        </AppContext.Provider>

    )
}
const useAppContext = () => useContext(AppContext)
export { AppContextProvider, useAppContext };

