import React from 'react';
import { Helmet } from 'react-helmet';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import CTASection from '../../components/landing-page/CTASection';
import FooterMenu from '../../components/landing-page/FooterMenu';
import HeaderMenu from '../../components/landing-page/HeaderMenu';
import { useLanguage } from '../../context/LanguageContext';
import translations from '../../context/Translations';
import FooterV2 from '../../components/landing-page/FooterV2';

function PrivacyPolicyScreen() {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Aceva GPT</title>
      </Helmet>
      
      <HeaderMenu t={t} />

      <div className="container mx-auto py-12">
        <div className="text-center mb-12 bg-gray-50 p-6 sm:p-8 rounded-lg">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-base sm:text-lg text-gray-600">Please review this Privacy Policy (‘Policy’) before using our Service, including the Website, Widget, and API (collectively referred to as ‘Service’). This Policy governs the information and data we collect, how we use it, and how we share it. Your access to and use of the Service are conditioned on your agreement with the Terms and Conditions (‘Terms’), which includes the terms of this Policy. ACEVA (‘Company’) operates the Service. We use your data to improve the Service. By using the Service, you agree to the collection and use of information as described in this Policy. Unless otherwise defined in this Policy, the terms used here have the same meanings as in our Terms and Conditions.</p>
        </div>

        <div className="space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Definitions</h2>
          <div className="space-y-4 text-gray-600">
            <p className='text-base text-gray-600'><strong>API:</strong> Refers to ACEVA’s application programming interface integrated with the User's software.</p>
            <p className='text-base text-gray-600'><strong>Cookies:</strong> Small files stored on your device.</p>
            <p className='text-base text-gray-600'><strong>Device:</strong> Means a computer or a mobile device.</p>
            <p className='text-base text-gray-600'><strong>Data Controller:</strong> Refers to ACEVA as the entity responsible for your data.</p>
            <p className='text-base text-gray-600'><strong>Processors:</strong> Those who process data on behalf of ACEVA.</p>
            <p className='text-base text-gray-600'><strong>Data Subject:</strong> Any living individual who is the subject of Personal Data.</p>
            <p className='text-base text-gray-600'><strong>Personal Data:</strong> Data about an identifiable living individual.</p>
            <p className='text-base text-gray-600'><strong>Service:</strong> The Website, Widget, and/or API.</p>
            <p className='text-base text-gray-600'><strong>Usage Data:</strong> Includes data collected automatically during your use of the Service.</p>
            <p className='text-base text-gray-600'><strong>Company:</strong> The entity that operates the Service, also referred to as ACEVA.</p>
            <p className='text-base text-gray-600'><strong>User:</strong> Any individual or entity who accesses or uses the Service.</p>
          </div>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Data Controller</h2>
          <p className='text-base text-gray-600'>ACEVA is the Data Controller of your personal data. We are responsible for handling your data and ensuring its privacy in line with applicable laws, including the Personal Data Protection Act 2010 (PDPA) in Malaysia, the General Data Protection Regulation (GDPR) in the EU, and relevant US data protection regulations such as the California Consumer Privacy Act (CCPA).</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Information Collection and Use</h2>
          <p className='text-base text-gray-600'>We collect various information to improve our Service.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Types of Data Collected</h2>
          <p className='text-base text-gray-600'><strong>Personal Data:</strong></p>
          <ul>
            <li className='text-base text-gray-600'>Email address</li>
            <li className='text-base text-gray-600'>First and last name</li>
            <li className='text-base text-gray-600'>Cookies and Usage Data</li>
          </ul>
          <p className='text-base text-gray-600'><strong>Usage Data:</strong> Includes browser information, IP address, and other technical data.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Use of Data</h2>
          <p className='text-base text-gray-600'>ACEVA uses collected Personal Data for various purposes, including providing the Service, customer support, and improving it.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Analytics</h2>
          <p className='text-base text-gray-600'>We employ an in-house analytics system designed to monitor and improve our Service while prioritizing user data privacy.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Third-Party Platform Integrations</h2>
          <p className='text-base text-gray-600'>ACEVA integrates with several third-party platforms to enhance functionality. These integrations are API-based, and we ensure that they do not share personal data beyond what is required for their operation. The platforms integrated with ACEVA include:</p>
          <ul>
            <li className='text-base text-gray-600'><strong>Google:</strong> For authentication (via Google sign-in) and analytics, we use Google’s APIs. Data shared with Google is subjected to Google’s privacy policy and terms.</li>
            <li className='text-base text-gray-600'><strong>Meta (WhatsApp, Facebook Messenger):</strong> For communication integrations, we use Meta’s APIs, which are governed by Meta's privacy policy.</li>
            <li className='text-base text-gray-600'><strong>Calendly and Google Calendar:</strong> For scheduling functionality, we integrate with Calendly and Google Calendar, where user data is processed in accordance with the respective platforms' privacy policies.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Ethical Approach to Integration</h2>
          <p className='text-base text-gray-600'>ACEVA adopts an ethical, and transparent approach to integrating with third-party services, including Google, Meta (WhatsApp/Facebook Messenger), Calendly, and any other platforms we may incorporate in the future. Our integrations follow these key principles:</p>
          <ul>
            <li className='text-base text-gray-600'><strong>Minimal Data Sharing:</strong> We ensure that only the necessary data required for functionality is shared with third-party platforms.</li>
            <li className='text-base text-gray-600'><strong>User Consent:</strong> Before sharing data with any third-party platform, we request clear user consent. In cases where consent is not explicitly provided, we do not share data.</li>
            <li className='text-base text-gray-600'><strong>Data Transparency:</strong> We are committed to ensuring that users are fully aware of what data is being shared with third parties, why it is shared, and how it will be used.</li>
            <li className='text-base text-gray-600'><strong>No Data Retention Beyond Purpose:</strong> We retain only the data necessary for providing the Service and will not store data beyond its functional use for the integrations.</li>
            <li className='text-base text-gray-600'><strong>Right to Opt-Out:</strong> Users can opt-out of using integrated third-party services at any time, in which case ACEVA will stop sharing their data with those services.</li>
            <li className='text-base text-gray-600'><strong>Third-Party Compliance:</strong> We ensure that all third-party platforms we integrate with comply with industry-standard data protection regulations and that they have implemented appropriate security measures to safeguard user data.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Retention of Data</h2>
          <p className='text-base text-gray-600'>We retain your Personal Data as necessary to meet legal obligations and for the purposes described in this Policy. Retention periods may vary depending on the jurisdiction and the specific use of the data.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Transfer of Data</h2>
          <p className='text-base text-gray-600'>Your data may be transferred to, and maintained on, servers located outside your country of residence. By using our Service, you consent to the transfer of your data to countries that may not have the same data protection laws as your jurisdiction. We ensure that data transferred to other jurisdictions will be protected as described in this Policy and in accordance with applicable legal standards.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Disclosure of Data</h2>
          <p className='text-base text-gray-600'>We may share Personal Data in specific circumstances as described in this Policy. This includes:</p>
          <ul>
            <li className='text-base text-gray-600'>Legal obligations and requests from government authorities.</li>
            <li className='text-base text-gray-600'>With service providers and third-party processors in line with the terms of this Policy.</li>
            <li className='text-base text-gray-600'>If we are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred as part of the transaction, with notice provided to you.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Security of Data</h2>
          <p className='text-base text-gray-600'>The security of your data is important to us. We use appropriate administrative, technical, and physical safeguards to protect your Personal Data from unauthorized access, disclosure, or misuse. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Your Data Protection Rights</h2>
          <p className='text-base text-gray-600'>As a global service, ACEVA is committed to ensuring that users' privacy rights are respected according to the laws of various jurisdictions.</p>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Rights Under the General Data Protection Regulation (GDPR)</h3>
          <p className='text-base text-gray-600'>For users located in the European Union (EU) or European Economic Area (EEA), ACEVA adheres to the principles outlined in the General Data Protection Regulation (GDPR). Under the GDPR, users have the following rights:</p>
          <ul>
            <li className='text-base text-gray-600'>Right to Access: You can request access to your Personal Data.</li>
            <li className='text-base text-gray-600'>Right to Rectification: You can request corrections to any inaccurate or incomplete data.</li>
            <li className='text-base text-gray-600'>Right to Erasure ("Right to be Forgotten"): You can request deletion of your data in certain circumstances.</li>
            <li className='text-base text-gray-600'>Right to Restrict Processing: You can request restrictions on the processing of your Personal Data.</li>
            <li className='text-base text-gray-600'>Right to Data Portability: You can request that we transfer your data to another service provider.</li>
            <li className='text-base text-gray-600'>Right to Object: You can object to the processing of your Personal Data under certain conditions.</li>
            <li className='text-base text-gray-600'>Right to Withdraw Consent: If you have given consent for the processing of your data, you can withdraw that consent at any time.</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-800 mb-2">Rights Under the Personal Data Protection Act (PDPA)</h3>
          <p className='text-base text-gray-600'>For users located in Malaysia, ACEVA ensures compliance with the Personal Data Protection Act 2010 (PDPA). Under the PDPA, users have the following rights:</p>
          <ul>
            <li className='text-base text-gray-600'>Right to Access: You can request access to your Personal Data held by us.</li>
            <li className='text-base text-gray-600'>Right to Correct: You can request corrections to any inaccurate, incomplete, or outdated data.</li>
            <li className='text-base text-gray-600'>Right to Withdraw Consent: You can withdraw your consent for processing your Personal Data at any time, subject to certain limitations and legal obligations.</li>
            <li className='text-base text-gray-600'>Right to Object: You have the right to object to the processing of your Personal Data under certain circumstances.</li>
            <li className='text-base text-gray-600'>Right to Erasure: In specific cases, you can request the deletion of your Personal Data, subject to legal or contractual obligations.</li>
            <li className='text-base text-gray-600'>Right to Data Portability: You may request the transfer of your Personal Data to another data controller in a structured, commonly used, and machine-readable format.</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-800 mb-2">Rights Under the California Consumer Privacy Act (CCPA)</h3>
          <p className='text-base text-gray-600'>For users located in California, USA, ACEVA ensures compliance with the California Consumer Privacy Act (CCPA). Under the CCPA, California residents have the following rights:</p>
          <ul>
            <li className='text-base text-gray-600'>Right to Know: You can request information about the categories of Personal Data we collect and the purposes for which we use it.</li>
            <li className='text-base text-gray-600'>Right to Delete: You can request the deletion of your Personal Data, subject to certain exceptions.</li>
            <li className='text-base text-gray-600'>Right to Opt-Out: You can opt out of the sale of your Personal Data.</li>
            <li className='text-base text-gray-600'>Right to Non-Discrimination: You will not be discriminated against for exercising any of your CCPA rights.</li>
          </ul>

          <h3 className="text-lg font-semibold text-gray-800 mb-2">Other International Data Protection Laws</h3>
          <p className='text-base text-gray-600'>In addition to the GDPR, PDPA, and CCPA, ACEVA will comply with other applicable data protection laws depending on the user's jurisdiction and any other relevant global regulations.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Service Providers</h2>
          <p className='text-base text-gray-600'>We may share data with third-party companies who assist in providing the Service. These third-party providers are obligated to maintain the confidentiality of your data and use it only to perform services on our behalf.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Payments</h2>
          <p className='text-base text-gray-600'>Third-party payment processors handle payments securely. We do not store or process payment data directly.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Links to Other Sites</h2>
          <p className='text-base text-gray-600'>Our Service may contain links to other websites that are not operated by us. We are not responsible for the privacy practices or content of these third-party sites.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Children's Privacy</h2>
          <p className='text-base text-gray-600'>Our Service is not intended for children under 16, and we do not knowingly collect Personal Data from children under 16. If we learn that we have collected Personal Data from a child under 16, we will take steps to delete such data.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Limited Use Requirements</h2>
          <p className='text-base text-gray-600'>ACEVA's use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.</p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Changes to This Privacy Policy</h2>
          <p className='text-base text-gray-600'>We may update this Policy from time to time. Any changes will be posted on this page, and you are encouraged to review it periodically. Continued use of the Service after any changes to the Policy will constitute your acknowledgment of the changes.</p>
        </div>
      </div>

      <CTASection t={t} />
      <FooterV2 t={t} />
    </>
  );
}

export default PrivacyPolicyScreen;
