import React from 'react'
import { Helmet } from 'react-helmet'
import CTASection from '../../components/landing-page/CTASection'
import FooterMenu from '../../components/landing-page/FooterMenu'
import HeaderMenu from '../../components/landing-page/HeaderMenu'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'
import FooterV2 from '../../components/landing-page/FooterV2'
import { Mail, MapPin } from 'lucide-react'

function ContactUsScreen() {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <Helmet>
        <title>Contact Us | Aceva GPT</title>
      </Helmet>
      
      <HeaderMenu t={t} />

      <section className='py-12'>
        <div className="container mx-auto">
          <div className="text-center mb-12 bg-gray-50 p-8 rounded-lg">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">{t?.contactUs?.title}</h1>
            <p className="text-lg text-gray-600">{t?.contactUs?.subTitle}</p>
          </div>

          <div className="max-w-lg bg-white p-8 rounded-lg">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Need More Info on Aceva?</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" placeholder="Enter your full name" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-color focus:border-brand-color sm:text-sm" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input type="email" placeholder="example@email.com" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-color focus:border-brand-color sm:text-sm" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Your Message</label>
                <textarea placeholder="Example Text" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-color focus:border-brand-color sm:text-sm"></textarea>
              </div>
              <button type="submit" className="w-full py-2 px-4 bg-brand-color text-white font-semibold rounded-md shadow-sm hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color">Send Message</button>
            </form>
          </div>

          <div className="space-y-6 mt-12">
            <div className="">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Information</h2>
              <div className="space-y-4 text-gray-600">
                <p className="text-base text-gray-600 leading-7">
                  <a href="mailto:info@wipdata.com" className="text-brand-color hover:text-brand-color-bold flex items-center">
                    <Mail className="w-4 h-4 mr-2" />
                    info@wipdata.com
                  </a>
                </p>
                <p className="text-base text-gray-600 leading-7 flex items-center">
                  <MapPin className="w-4 h-4 mr-2" />
                  Kelana Square, B607, 47301 Petaling Jaya, Selangor, Malaysia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTASection t={t} />
      <FooterV2 t={t} />
    </>
  )
}

export default ContactUsScreen