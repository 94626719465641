
import React from 'react'
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTiktok, IoMail } from "react-icons/io5"
import { Link } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'
import translations from '../../context/Translations'
import logo from '../../images/aceva.png'

export default function FooterV2({ bg = "transparent" }) {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <footer className={`py-12 pb-8 ${bg === "gray" ? "bg-gray-50" : "bg-transparent"}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:gap-12 md:grid-cols-12 lg:gap-16">
          {/* Brand Column */}
          <div className="md:col-span-4 space-y-4 sm:space-y-6">
            <Link to="/" className="inline-block">
              <img src={logo} alt="ACEVA" className="h-8 sm:h-10 w-auto" />
            </Link>
            <p className="text-sm text-gray-600 leading-relaxed max-w-sm">
              {t?.footer?.bio}
            </p>
          </div>

          {/* Navigation Columns */}
          <div className="md:col-span-5">
            <div className="grid grid-cols-2 gap-4 sm:gap-8">
              <div className="space-y-3 sm:space-y-4">
                <h3 className="text-sm sm:text-base font-semibold text-gray-900">{t?.footer?.product?.title}</h3>
                <nav className="flex flex-col space-y-2 sm:space-y-3">
                  {[
                    { to: "/pricing", label: t?.footer?.product?.menus[0] },
                    { to: "/blog", label: t?.footer?.product?.menus[1] },
                    { to: "/contact-us", label: t?.footer?.product?.menus[2] },
                  ].map((item) => (
                    <Link
                      key={item.to}
                      to={item.to}
                      className="text-xs sm:text-sm text-gray-500 hover:text-brand-color transition-colors duration-300"
                    >
                      {item.label}
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="space-y-3 sm:space-y-4">
                <h3 className="text-sm sm:text-base font-semibold text-gray-900">{t?.footer?.legal?.title}</h3>
                <nav className="flex flex-col space-y-2 sm:space-y-3">
                  {[
                    { to: "/terms-and-conditions", label: t?.footer?.legal?.menus[0] },
                    { to: "/privacy-policy", label: t?.footer?.legal?.menus[1] },
                  ].map((item) => (
                    <Link
                      key={item.to}
                      to={item.to}
                      className="text-xs sm:text-sm text-gray-500 hover:text-brand-color transition-colors duration-300"
                    >
                      {item.label}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* Contact Column */}
          <div className="md:col-span-3">
            <div className="space-y-4">
              <h3 className="text-sm sm:text-base font-semibold text-gray-900">{t?.footer?.lastColumn?.getInTouch}</h3>
              <a
                href={`mailto:info@wipdata.com`}
                className="flex items-center text-xs sm:text-sm text-gray-500 hover:text-brand-color transition-colors duration-300"
              >
                <IoMail size={16} className="mr-1 sm:mr-3" />
                info@wipdata.com
              </a>

              <h3 className="text-base font-medium text-gray-700">{t?.footer?.lastColumn?.connectWithUs}</h3>
              <div className="flex items-center space-x-2 sm:space-x-3">
                {[
                  { Icon: IoLogoFacebook, href: "https://www.facebook.com/wipdata.chatbots/" },
                  { Icon: IoLogoInstagram, href: "https://www.instagram.com/wipdata_my/" },
                  { Icon: IoLogoLinkedin, href: "https://www.linkedin.com/company/wipdata/" },
                  { Icon: IoLogoTiktok, href: "https://www.tiktok.com/@wipdata_my/" },
                ].map((item, index) => (
                  <a
                    key={index}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-color transform hover:scale-110 transition-all duration-300"
                  >
                    <item.Icon size={16} strokeWidth={1.5} className="sm:w-5 sm:h-5" />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-8 sm:mt-12 lg:mt-16 pt-6 sm:pt-8 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
            <p className="text-xs sm:text-sm text-gray-500">
              © {new Date().getFullYear()} ACEVA. All rights reserved.
            </p>
            <p className="text-xs sm:text-sm text-gray-500">
              Product by <Link className='text-brand-color hover:text-brand-color-bold' to="https://wipdata.com" target="_blank" rel="noopener noreferrer">WIPDATA</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}