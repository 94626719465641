'use client';

import { Check } from 'lucide-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import Translations from '../../context/Translations';

const userInfo = JSON.parse(localStorage.getItem('userInfo'))

const PricingTier = ({ isYearly, payNow, id, title, price, period, description, features, advancedFeatures, buttonText, isEssential, stripePriceId, pid, loading, isCustom }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="min-h-[100px]">
      <h3 className="text-2xl font-semibold mb-2 text-gray-700">{title}</h3>
      <p className="text-gray-600 mb-4 min-h-[75px]">{description}</p>
    </div>
    {price !== undefined && <div className="text-3xl font-bold mb-4 min-h-[30px]">
      {isCustom ? (
        <span className="text-brand-color">{price}</span>
      ) : (
        <>${price}<span className="text-lg font-normal">/{period}</span></>
      )}
    </div>}
    <Link>
      {
        userInfo?.subscription_id === id ? <button className={`w-full py-2 px-4 rounded-lg mb-6 hover:shadow hover:bg-brand-color-bold hover:text-white transition-bg duration-200 bg-brand-color text-white}`}>
        {buttonText || "Get Started"}
      </button> : <button onClick={() => payNow(id, isYearly)} className={`w-full py-2 px-4 rounded-lg mb-6 hover:shadow hover:bg-brand-color-bold hover:text-white transition-bg duration-200 bg-slate-200 text-gray-700`}>
        {(loading && pid === id) ? 'Loading...' : (buttonText || "Get Started")}</button> 
      }
    </Link>
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Check className="inline-block text-brand-color mr-1 w-5 h-5 mt-1 flex-shrink-0 cursor-default" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    {advancedFeatures && (
      <h3 className={`text-base font-semibold text-brand-color-bold my-4 border-t border-gray-100 pt-4 ${isCustom ? 'mt-12' : ''}`}>All from {title === 'Basic' ? 'Freemium' : title === 'Pro' ? 'Basic' : title === 'Pro+' ? 'Pro' : ''} Plan, plus:</h3>
    )}
    {advancedFeatures && (
      <ul className="space-y-2">
        {advancedFeatures.map((advancedFeature, index) => (
          <li key={index} className="flex items-start">
            <Check className="inline-block text-brand-color mr-1 w-5 h-5 mt-1 flex-shrink-0 cursor-default" />
            <span>{advancedFeature}</span>
          </li>
        ))}
      </ul>
    )}
  </div>
);

export default function PricingSection({ h1 = true, payNow, planLists, pid, loading }) {
  const { language } = useLanguage();
  const t = Translations[language];
  const [isYearly, setIsYearly] = useState(false);

  return (
    <div className="bg-white py-12">
        {/* Toggle */}
        <div className="flex justify-center mb-8">
          <div className="bg-brand-color p-1 rounded-full flex items-center">
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                !isYearly ? "bg-white text-brand-color-bold font-medium" : "text-gray-100"
              }`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                isYearly ? "bg-white text-brand-color-bold font-medium" : "text-gray-100"
              }`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
            </button>
          </div>
        </div>
        <div className="grid gap-4 lg:grid-cols-4">
          {planLists?.map((tier, index) => (
            <PricingTier
            key={index}
            id={tier._id}
            title={tier.name}
            price={isYearly ? tier.price_yearly : tier.price}
            period={isYearly ? "year" : "mo"}
            description={tier.description}
            features={tier.features}
            advancedFeatures={tier.extraFeat}
            buttonText={tier.buttonText}
            isEssential={tier.name === 'Basic'}
            stripePriceId={isYearly ? tier.yearlyStripePriceId : tier.stripePriceId}
            isYearly={isYearly}
            isCustom={tier.isCustom}
            payNow={payNow} 
            pid={pid} 
            loading={loading}
          />
          ))}
        </div>
    </div>
  );
}
