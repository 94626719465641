import React from 'react';
import { BsChevronLeft, BsChevronRight, BsX } from 'react-icons/bs';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import guideFive from '../../images/bg/111-1.png';
import guideFour from '../../images/bg/222.png';
import guideThree from '../../images/bg/333.png';
import guideTwo from '../../images/bg/444.png';
import guideOne from '../../images/bg/555.png';
import guideSix from '../../images/bg/666.png';
import guideSeven from '../../images/bg/777.png';

function BotGuideCarousel({ tutModel, setTutModel }) {
  return (
    <>
      <div
        className={
          tutModel
            ? 'tut-demo-model-container active'
            : 'tut-demo-model-container'
        }
      >
        <div className="demo-video-modal-wrapper">
          <BsX className="cancel" onClick={() => setTutModel(!tutModel)} />

          <Slider
            prevArrow={<BsChevronLeft className="slick-prev" />}
            nextArrow={<BsChevronRight className="slick-next" />}
          >
            <div className="guide-content-wrap">
              <h3>Don't know how to create your bot? We'll guide you!</h3>
            </div>
            <div className="guide-slider">
              <h3>1. Inserting your link or files</h3>
              <img className="img" src={guideOne} alt="Slide 1" />
            </div>
            <div className="guide-slider">
              <h3>2. Customizing your chatbot</h3>
              <img className="img" src={guideTwo} alt="Slide 2" />
            </div>
            <div className="guide-slider">
              <h3>3. Adding prompts for your chatbot</h3>
              <img className="img" src={guideThree} alt="Slide 3" />
            </div>
            <div className="guide-slider">
              <h3>4. Selecting your bot’s language</h3>
              <img className="img" src={guideFour} alt="Slide 4" />
            </div>
            <div className="guide-slider">
              <h3>5. Previewing your bot</h3>
              <img className="img" src={guideFive} alt="Slide 5" />
            </div>
            <div className="guide-slider">
              <h3>6. Copy & Paste the code snippet</h3>
              <img className="img" src={guideSix} alt="Slide 6" />
            </div>
            <div className="guide-slider">
              <h3>7. Or download the plugin (Wordpress)</h3>
              <img className="img" src={guideSeven} alt="Slide 7" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default BotGuideCarousel;
