
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import HeaderMenu from '../../components/landing-page/HeaderMenu'
import FooterV2 from '../../components/landing-page/FooterV2'
import blogThumb from '../../images/support/calendar.png'

/**
 * CategoryScreen Component
 * Displays a list of blog posts filtered by category
 * Uses Next.js components for optimized routing and image loading
 * Fetches posts by category slug from API
 */
function CategoryScreen() {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoryPosts = async () => {
      try {
        const { data } = await axios.get(
          `https://gptsaas.aceva.com.my/api/blogCategories/slug/${slug}`
        );
        setCategory(data);
        setPosts(data?.blogs || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching category posts:', error);
        setLoading(false);
      }
    };

    if (slug) {
      fetchCategoryPosts();
    }
  }, [slug]);

  console.log('category', category)

  if (loading) {
    return (
      <>
        <HeaderMenu />
        <section className='py-12 bg-gray-50'>
          <div className="container mx-auto px-4">
            <div className="mb-8">
              <div className="w-64 h-8 bg-gray-200 animate-pulse rounded" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <div key={item} className="bg-white rounded-lg overflow-hidden shadow-sm">
                  <div className="h-[240px] bg-gray-200 animate-pulse" />
                  <div className="p-6 space-y-4">
                    <div className="w-24 h-6 bg-gray-200 animate-pulse rounded-full" />
                    <div className="w-3/4 h-6 bg-gray-200 animate-pulse rounded" />
                    <div className="space-y-2">
                      <div className="w-full h-4 bg-gray-200 animate-pulse rounded" />
                      <div className="w-full h-4 bg-gray-200 animate-pulse rounded" />
                    </div>
                    <div className="flex justify-between">
                      <div className="w-32 h-4 bg-gray-200 animate-pulse rounded" />
                      <div className="w-24 h-4 bg-gray-200 animate-pulse rounded" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <FooterV2 />
      </>
    );
  }

  return (
    <>
      <HeaderMenu />
      <section className='py-12 bg-gray-50'>
        <div className="container mx-auto px-4">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">{category?.name || 'Category'}</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {posts.map((post) => (
              <div key={post.id} className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                <div className="relative h-[240px]">
                  <img
                    src={post.featuredImage || blogThumb}
                    alt={post.title}
                    fill
                    className="object-cover"
                  />
                </div>
                <div className="p-6">
                  <Link 
                    to={`/category/${category?.slug}`}
                    className="inline-block px-3 py-1 text-sm font-semibold text-brand-color bg-green-50 rounded-full mb-4"
                  >
                    {category?.name}
                  </Link>
                  <h2 className="text-xl font-bold mb-3 hover:text-brand-color">
                    <Link to={`/blog/${post.slug}`}>
                      {post.title}
                    </Link>
                  </h2>
                  <div 
                    className="text-gray-600 mb-4 line-clamp-3"
                    dangerouslySetInnerHTML={{__html: post.content?.substring(0, 150) + '...'}}
                  />
                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <Link 
                      to={`/author/${post.author?.slug}`}
                      className="font-medium hover:text-brand-color"
                    >
                      {post.author?.name}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FooterV2 />
    </>
  )
}

export default CategoryScreen