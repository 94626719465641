import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { BiSolidPencil } from "react-icons/bi";
import { FiChevronUp } from 'react-icons/fi';
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

import logo from '../../images/error-img.png';

function UpdatePromptSteps({promptLists, setPromptLists, config}) {

  // const [searchParams, setSearchParams] = useSearchParams()
  // const botId = searchParams.get('botId');
  // console.log(botId)
  const [prompt, setPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [editPrompt, setEditPrompt] = useState({
    promptLabel: '',
    promptLink: '',
  });
  const [link, setlink] = useState({
    linkLabel: '',
    linkLink: '',
  });
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [promptObj, setPromptObj] = useState({});
  const [refresh, setRefresh] = useState(false);
  
  const [filterShow, setFilterShow] = useState(false)
  const [editIndex, setEditIndex] = useState(null); // Track the index of the prompt being edited
  const [loading, setLoading] = useState(false); // Track the index of the prompt being edited
  const [promptToggle, setPromptToggle] = useState(false);
  const navigate = useNavigate();

    console.log('promptLists', promptLists)
  
  // Function to handle toggling of individual prompt items
  const handlePromptToggle = (index) => {
    const updatedPrompts = [...promptLists];
    updatedPrompts[index].toggle = !updatedPrompts[index].toggle;
    setPromptLists(updatedPrompts);
  };
  

  const handleLinkInputChange = (e) => {
    const { name, value } = e.target;
    setlink({
      ...link,
      [name]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrompt({
      ...prompt,
      [name]: value,
    });
  };

  const handleLinkSubmit = (e) => {
    e.preventDefault();
    if (config.type === 'whatsapp-bot') {
      if (promptLists.length === 3) {
        toast.error('The max number of menu must be 3!');
        return 0
      }
    } else if (promptLists.length === 5) {
      toast.error('The max number of menu must be 5!');
      return 0
    }
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(link.linkLink)) {
      toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
      return 0
    }
    setLoading(true)
    if (link.linkLabel && link.linkLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: link.linkLabel,
          content: link.linkLink
        }
      ])
      setLoading(false)
      setlink({ linkLabel: '', linkLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (config.type === 'whatsapp-bot') {
      if (promptLists.length === 3) {
        toast.error('The max number of menu must be 3!');
        return 0
      }
    } else if (promptLists.length === 5) {
      toast.error('The max number of menu must be 5!');
      return 0
    }
    setLoading(true)
    if (prompt.promptLabel && prompt.promptLink) {
      setPromptLists([
        ...promptLists,
        { 
          title: prompt.promptLabel,
          content: prompt.promptLink
        }
      ])
      setLoading(false)
      setPrompt({ promptLabel: '', promptLink: '' });
      setRefresh(!refresh)
    } else {
      setLoading(false)
      toast.error('Please fill in all fields');
    }
  };
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditPrompt({
      ...editPrompt,
      [name]: value,
    });
  };
  const handleEditClick = () => {
    if (editPrompt.promptLink.startsWith('http')) {
      if (!/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink)) {
        toast.error('URL is invalid! Please use URLs in the following format: https://www.example.com or http://www.example.com.')
        return 0
      }
    }
    if (editPrompt.promptLabel && editPrompt.promptLink) {
      promptLists[editIndex] = { 
        title: editPrompt.promptLabel,
        content: editPrompt.promptLink
      }
      setPromptLists([...promptLists])
      setEditPrompt({ promptLabel: '', promptLink: '' });
      setFilterShow(!filterShow)
    }
  };
  console.log('filterShow', filterShow)

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'));
    if (sourceIndex !== targetIndex) {
      const newItems = [...promptLists];
      const [movedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(targetIndex, 0, movedItem);
      setPromptLists(newItems);
    }
  };

  return (
    <div className="bg-gray-50 p-6 sm:p-10">
      {/* date filter */}
      <div className="chat-date-filter">
        {/* filter model */}
        <div className={filterShow ? "date-filter-model-wrapper update-grid-gap active" : "date-filter-model-wrapper update-grid-gap"}>
          <div className='date-filter-model prompt-edit-model'>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="create-title">
                <h2 className="text-lg font-semibold">{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Update Link' : 'Update Prompt'}</h2>
              </div>
              <div className="chatbot-config-wrapper prompt-body-wrapper">
                <div className="chatbot-config-fields">
                  <div>
                    <div className="mb-3">
                      <label htmlFor="promptLabel" className="block text-sm font-normal font-poppins text-gray-600 mb-1">{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'Link Button title' : 'Prompt title (e.g., About Aceva GPT)'}</label>
                      <input
                        name="promptLabel"
                        type="text"
                        value={editPrompt.promptLabel}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                        placeholder="Menu label"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="promptLink" className="block text-sm font-normal font-poppins text-gray-600 mb-1">{/^(ftp|http|https):\/\/[^ "]+$/.test(editPrompt.promptLink) ? 'External link' : 'Queries (e.g., Hi, what is Aceva GPT?)'}</label>
                      <input
                        name="promptLink"
                        type="text"
                        value={editPrompt.promptLink}
                        onChange={handleEditInputChange}
                        className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                        placeholder="Menu link"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flex gap-3 mt-6">
              <button type='button' className='flex-1 bg-brand-color text-white px-4 py-2 rounded-md hover:bg-brand-color-bold transition-colors' onClick={handleEditClick}> Update </button>
              <button onClick={() => {
                setFilterShow(!filterShow)
                setEditPrompt({ promptLabel: '', promptLink: '' });
              }} className="flex-1 bg-gray-100 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-200 transition-colors">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-5 flex-col sm:flex-row">
        <div className="remove-scroll w-full">
          <div className="create-title">
            <h2 className="text-lg font-semibold">Create Prompt</h2>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="chatbot-config-wrapper prompt-body-wrapper">
              <div className="chatbot-config-fields">
                <div>
                  <div className="mb-3">
                    <label htmlFor="promptLabel" className="block text-sm font-normal font-poppins text-gray-600 mb-1">Prompt title (e.g., About Aceva GPT)</label>
                    <input
                      name="promptLabel"
                      type="text"
                      value={prompt.promptLabel}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                      placeholder="Menu label"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="promptLink" className="block text-sm font-normal font-poppins text-gray-600 mb-1">Queries (e.g., Hi, what is Aceva GPT?)</label>
                    <input
                      name="promptLink"
                      type="text"
                      value={prompt.promptLink}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                      placeholder="Menu link"
                    />
                  </div>
                  <div className="prompt-action-wrap">
                    <button type="submit" className="w-fit bg-brand-color text-white px-4 py-2 rounded-lg hover:bg-brand-color-bold transition-colors">
                      <span>{loading ? 'Loading...' : 'Add Prompt'}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <form onSubmit={handleLinkSubmit} className="space-y-4">
            <div className="chatbot-config-wrapper prompt-body-wrapper">
              <div className="mb-2 mt-2 sm:mt-4">
                <h2 className="text-lg font-semibold">Create Link Button</h2>
              </div>
              <div className="chatbot-config-fields">
                <div>
                  <div className="mb-3">
                    <label htmlFor="linkLabel" className="block text-sm font-normal font-poppins text-gray-600 mb-1">Link Button title (e.g Book Appointment)</label>
                    <input
                      name="linkLabel"
                      type="text"
                      value={link.linkLabel}
                      onChange={handleLinkInputChange}
                      className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                      placeholder="Book Appointment"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="link" className="block text-sm font-normal font-poppins text-gray-600 mb-1">Add external link</label>
                    <input
                      name="linkLink"
                      type="text"
                      value={link.linkLink}
                      onChange={handleLinkInputChange}
                      className="w-full px-3 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-brand-color"
                      placeholder="https://calendly.com/wipdata/30min"
                    />
                  </div>
                  <div className="prompt-action-wrap">
                    <button type="submit" className="w-fit bg-brand-color text-white px-4 py-2 rounded-lg hover:bg-brand-color-bold transition-colors">
                      <span>{loading ? 'Loading...' : 'Add Button'}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* chatbot selected item preview */}
        <div className="create-chatbot-preview-wrapper w-full">
          <div className="create-title">
            <h2 className="text-lg font-semibold">All Prompts</h2>
            <p className="text-gray-600">Your created prompts.</p>
          </div>
          <div className="prompt-wrap-container">
            <ul className={promptLists?.length > 1 ? "p-4 space-y-4 max-h-96 overflow-y-auto pl-0 sm:pl-4" : "p-4 space-y-4 max-h-96 overflow-y-auto scrollbar-none"}>
              {
                promptLists?.length > 0 ? (
                  promptLists.map((prompt, index) => (
                    <li 
                      key={index}
                      draggable
                      onDragStart={e => handleDragStart(e, index)}
                      onDragOver={handleDragOver}
                      onDrop={e => handleDrop(e, index)}
                      onClick={() => setPromptToggle(!promptToggle)}
                      className={promptToggle ? "promp-item" : "prompt-item active"} 
                    >
                      <div className="uploaded-file-icon-and-name">
                        <div className="prompt-title">
                          <div className='prompt-icon-text-wrap' onClick={() => handlePromptToggle(index)}>
                            <FiChevronUp className={prompt.toggle ? 'chevron' : 'chevron active'} />
                            <span className="font-normal font-poppins">{prompt.title}</span>
                          </div>
                          <div className="uploaded-file-action created-prompt-item">
                            <BiSolidPencil
                              className="selected-icon cursor-pointer text-brand-color hover:text-brand-color-bold"
                              onClick={() => {
                                setFilterShow(!filterShow)
                                setEditPrompt({promptLabel: prompt.title, promptLink: prompt.content,})
                                setEditIndex(index)
                              }}
                            />
                            <RiDeleteBinLine
                              className="created-prompt-delete-icon delete-icon cursor-pointer text-red-600 hover:text-red-700"
                              onClick={() => {
                                const updatedPrompts = [...promptLists];
                                updatedPrompts.splice(index, 1);
                                setPromptLists(updatedPrompts);
                              }}
                            />
                          </div>
                        </div>
                        {prompt.toggle && (
                          <div className="prompt-content">
                            <div className="prompt-item-group">
                              <h3 className="text-sm font-normal font-poppins text-gray-600">Status</h3>
                              <p className="prompt-badge inline-block mt-1 px-2 py-1 text-xs font-normal font-poppins rounded-full bg-brand-color text-white">{/^(ftp|http|https):\/\/[^ "]+$/.test(prompt.content) ? 'Link' : 'Prompt'}</p>
                            </div>
                            <div className="prompt-item-group">
                              <h3 className="text-sm font-normal font-poppins text-gray-600">Prompt title</h3>
                              <p className="mt-1 text-gray-600">{prompt.title}</p>
                            </div>
                            <div className="prompt-item-group">
                              <h3 className="text-sm font-normal font-poppins text-gray-600">Queries</h3>
                              <p className="mt-1 text-gray-600">{prompt.content}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="not-link-found text-center py-4">
                    <div className="not-found-image">
                      <img className='error-img' src={logo} alt="error image" />
                      <span className="text-gray-500">Nothing here beep beep... <br /> Create your prompt now!</span>
                    </div>
                  </div> 
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePromptSteps;
