import { Dropdown, Space } from 'antd';
import React from 'react';
import { BsChatDots, BsChevronDown, BsCreditCard } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdLogout } from 'react-icons/md';
import { Link } from 'react-router-dom';

const HeaderDropdown = ({ handleLogout }) => {
  const items = [
    {
      key: '1',
      icon: <IoSettingsOutline />,
      label: (
        <Link to="/settings" className={({ isActive }) => isActive ? "active-link" : ""}> Settings </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to="/billing" className={({ isActive }) => isActive ? "active-link" : ""}> Account & Billing</Link>
      ),
      icon: <BsCreditCard />,
    },
    {
      key: '3',
      icon: <MdLogout />,
      label: (
        <Link onClick={handleLogout} to="/logout"> Logout</Link>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomRight"
      arrow
    >
      <a onClick={(e) => e.preventDefault()}>
        <div className='dropdown-wrapper'>
          <p>My Account</p>
          <BsChevronDown />
        </div>
      </a>
    </Dropdown>
  );
};

export default HeaderDropdown;
