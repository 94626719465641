import moment from 'moment';
import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarDetailSidebar from './CalendarDetailSidebar';

const localizer = momentLocalizer(moment);



const MyCalendar = ({googleEvents, setGoogleEvents}) => {
  
  const [selectedEvent, setSelectedEvent] = useState(null);

  const CustomEvent = ({ event }) => (
    <div className="meeting-active-block">
      <p className="meeting-active-title">{event.title}</p>
      <div className="meeting-time-wrap">
        <p>Start: {moment(event.start).format('h:mm a')}</p>
        <p>End: {moment(event.end).format('h:mm a')}</p>
      </div>
    </div>
  );

  const handleSelectEvent = (event) => {
    console.log('Selected event:', event);
    setSelectedEvent(event);
  };

  const handleDrawerClose = () => {
    setSelectedEvent(null);
  };

  console.log('googleEvents', googleEvents)

  return (
    <div className='calendar-container'>
      <div className="calendar-btn-wrap">
        {
          // googleEvents?.length === 0 && (
          //   <LoginWithGoogleCalendar />
          // )
        }

      </div>

            <CalendarDetailSidebar
              event={selectedEvent}
              onClose={handleDrawerClose}
            />
            {/* <CalendarEvents /> */}
            <Calendar
              localizer={localizer}
              events={googleEvents}
              startAccessor="start"
              endAccessor="end"
              components={{ event: CustomEvent }}
              onSelectEvent={handleSelectEvent} // Handle event click
            />
    </div>
  );
};

export default MyCalendar;
