import React from 'react'
import { FiX } from 'react-icons/fi'
import wipdata from "../../images/aceva.png"
import moment from 'moment'

function ListSidebar({sidebar, setSidebar, singleData}) {
  return (
    <>
      <div onClick={() => setSidebar(false)} className={sidebar ? "details-sidebar-container active" : "details-sidebar-container"}>

        {/* notification details sidebar */}
        <div onClick={e => e.stopPropagation()} className={sidebar ? "details-sidebar active" : "details-sidebar"}>
          {/* header */}
          <div className="details-sidebar-header">
            <div className="left-sidebar-details">
              
              <article className='sidebar-article'>
                <h3>{singleData?.sourceURL || singleData?.upFileName}</h3>
              </article>
            </div>
            <div className="right-sidebar-icons noti-filter">
              <div className="close-icon" onClick={() => setSidebar(!sidebar)}>
                <FiX />
              </div>
            </div>
          </div>

          {/* body */}
          <div className="details-sidebar-body">

            <div className="details-sidebar-wrap">
              <h2>{singleData?.title}</h2>

              <p dangerouslySetInnerHTML={{ __html: singleData?.contentOfUrl || singleData?.contentOfUp}} >{}</p>
              
            </div>
          </div>
          
            {/* agent */}
            <div className="sidebar-agent">

              <div className="agent-details">
                <p>Last train: {moment(singleData?.createdAt).format('lll')}</p>
              </div>
            </div>
        </div>

        </div>
    </>
  )
}

export default ListSidebar