
import Accordion from '../Accordion';

import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import ConfigBot from '../../components/ConfigBot';

import UploadAvatarComponent from '../antd/UploadAvatarComponent';
import ConfigColor from '../botConfig/ConfigColor';
import ApearanceLogo from '../widget/ApearanceLogo';

function ChangeApearanceStep({config, setConfig}) {
 
  const [uploadLoading, setUploadLoading] = useState(false)
  const [loading, setLoadLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLinkIds, setGetLinkIds] = useState([])
  const [botId, setBotId] = useState('')

  const navigate = useNavigate(); 

  const tempImg = "https://res.cloudinary.com/dsvt1ftd0/image/upload/v1702616802/1_gth7c6.png";

  // useEffect(() => {
  //   // Reset the scroll position to the top when the component mounts
  //   window.scrollTo(0, 0);
  // }, []);
  
  // file upload handler
  const uploadAvatarHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "tpy8hir8")
    formData.append("cloud_name", "dsvt1ftd0")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
      setConfig({ ...config, botAvatar: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "tpy8hir8")
    formData.append("cloud_name", "dsvt1ftd0")

    try {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, headers)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dsvt1ftd0/image/upload', formData, headers)
      setConfig({ ...config, logo: data?.url });
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // config handler
  const botConfigHandler = async () => {
    try {
      const {data} = await axios.post(`${apiUrl}/botConfig/`)
    } catch (error) {
      console.log('Error:', error?.message || error?.response?.data)
    }
  }

  return (
    <>
      <div className="create-chatbot-container">
        
        <div className="create-chatbot-file-wrapper">
          <div className="create-chatbot-file-body overflow-y-scroll">
            <Accordion 
              classContent="ap-content" 
              classItem="ap-item" 
              classWrapper="ap-wrapper"
              classTitle="ap-title"
              title="Name and Placeholder"
            >
              <form>
                {/* chatbot name */}
                <div className="form-group">
                  <label htmlFor="">Chatbot name <span className="req">*</span> </label>
                  <input 
                    onChange={(e) => {
                      config.botName = e.target.value
                      setConfig({...config})
                    }} 
                    value={config?.botName}
                    type="text" 
                    placeholder='Chatbot name' 
                  />
                </div>
                {/* welcome message */}
                <div className="form-group">
                  <label htmlFor="">Welcome message <span className="req">*</span></label>
                  <textarea
                    onChange={(e) => {
                      config.welcomeMessage = e.target.value
                      setConfig({...config})
                    }} 
                    value={config?.welcomeMessage}
                  placeholder='Hi, How can I help you?' />
                </div>
                {/* placeholder name */}
                <div className="form-group">
                  <label htmlFor="">Input placeholder name</label>
                  <input 
                    onChange={(e) => {
                      config.placeholder = e.target.value
                      setConfig({...config})
                    }} 
                    value={config?.placeholder}
                    type="text" placeholder='Ask me anything' />
                </div>
              </form>
            </Accordion>
            <Accordion 
              title="Logo and Brand Color"
              classContent="ap-content" 
              classItem="ap-item" 
              classWrapper="ap-wrapper"
              classTitle="ap-title"
            >
              <ApearanceLogo config={config} setConfig={setConfig} uploadFileHandler={uploadFileHandler}/>
              <ConfigColor config={config} setConfig={setConfig} />
            </Accordion>
            <Accordion 
              title="Chatbot Avatar"
              classContent="ap-content" 
              classItem="ap-item" 
              classWrapper="ap-wrapper"
              classTitle="ap-title"
            >
                <UploadAvatarComponent 
                  uploadLoading={uploadLoading}
                  config={config}
                  setConfig={setConfig}
                  uploadFileHandler={uploadAvatarHandler}
                  tempImg={tempImg}
                />
                
            </Accordion>
          </div>
          {/* chatbot selected item preview */}
          <div className="create-chatbot-preview-wrapper">
            <ConfigBot config={config} setConfig={setConfig} />
          </div>
        </div>
      </div> 
    </>
  )
}

export default ChangeApearanceStep