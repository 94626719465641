import { Popover, Radio, Space } from 'antd';
import React, { useState } from 'react';
import { IoMdInformationCircle } from "react-icons/io";

function WaUserTypeRadio({setNewNumber, newNumber}) {

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setNewNumber(e.target.value);
  };

  const essentialContent = (
    <>
      <p>Please ensure that the number has not been registered with any WhatsApp Business.</p>
    </>
  );

  return (
    <>
      <div className="wa-check-wrap">
        <p>Do you need a new number or do you have an existing WhatsApp business number?</p>
        <div className="wa-check-box">
          <Radio.Group onChange={onChange} value={newNumber}>
            <Space direction="vertical">
              <Radio value={true}>New Number (Additional RM 15)</Radio>

              <Radio value={false}>
                <div className="inline-radio-wrap">
                  <span>Existing Number </span>
                  <div className="">
                    <Popover content={essentialContent}>
                      <IoMdInformationCircle />
                    </Popover>
                  </div>
                </div>
              </Radio>

            </Space>
          </Radio.Group>
        </div>
      </div>
    </>
  )
}

export default WaUserTypeRadio