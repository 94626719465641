import { useGoogleLogin } from '@react-oauth/google';
import { Checkbox } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiUrl } from '../Variables';
import ErrorText from '../components/ErrorText';
import MiniLoading from '../components/MiniLoading';
import FooterV2 from '../components/landing-page/FooterV2';
import { useAppContext } from '../context/AppContext';
import Logo from '../images/aceva.png';
import loginThumb from '../images/bg/login-thumb-2.png';
import GoogleIcon from '../images/icons/google.png';


function LoginScreen({ toggleLoggedInState }) {
  const navigate = useNavigate();
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [privacyRequired, setPrivacyRequired] = useState(false);

  const onChange = (e) => {
    setPrivacyCheck(e.target.checked); // Toggle the privacyCheck state based on the checkbox
    setPrivacyRequired(!e.target.checked); // Set privacyRequired to false if the checkbox is checked, true otherwise
  };
  

  console.log('privacy check:', privacyCheck);
  console.log('privacyRequired', privacyRequired)

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userInfo, refresh, setRefresh } = useAppContext();
  const [chatbots, setChatbots] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(
    () => {
      // Retrieve userInfo from localStorage
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (!userInfo || !userInfo.token) {
        console.error('User token not found.');
        return;
      }

      // Add headers to include JWT token
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };

      const featchData = async () => {
        try {
          const { data } = await axios.get(`${apiUrl}/chatbot`, { headers });
          console.log('chatbot', data);
          console.log('chatbots?.length', data.length);
          setChatbots(data);
        } catch (error) {
          console.log('scrap list fetching fail:', error);
          toast.error(`Error: ${error?.message || error?.response?.data}`);
        }
      };
      featchData();
    },
    [/*errorMessage*/]
  );
  // const handleGoogleLogin = async googleData => {
  //   console.log('googleData', googleData)
  // }
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then(async (res) => {
          console.log('gprof', res);
          // header supports
          const config = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          };
          setLoading(true);
          await axios
            .post(`${apiUrl}/user/loginWithGoogle`, res.data, config)
            .then(async (res) => {
              localStorage.setItem('userInfo', JSON.stringify(res.data));
              localStorage.setItem('isAuthenticated', JSON.stringify(true));
              const headers = {
                Authorization: `Bearer ${res.data?.token}`,
              };
              await axios
                .get(`${apiUrl}/chatbot`, { headers })
                .then((res) => {
                  if (res.data?.length > 0) {
                    localStorage.setItem('isHaveBot', JSON.stringify(true));
                  } else {
                    localStorage.setItem('isHaveBot', JSON.stringify(false));
                  }
                  setLoading(false);
                  toggleLoggedInState();
                })
                .catch((err) => {
                  console.log('chatbot list fetching fail:', err);
                });
            })
            .catch((error) => {
              setLoading(false);
              console.log(error.response ? error.response : error.message); // this is the main part. Use the response property from the error object
              setErrorMessage(
                error.response ? error.response.data.message : error.message
              );
              if (error.response) {
                return Swal.fire({
                  icon: 'error',
                  title: 'Oops! Invalid username or password.',
                  text: error.response.data.message,
                });
              }
            });
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const onSubmit = async (data) => {

    if (!privacyCheck) {
      setPrivacyRequired(true);
      return; // Prevent login if privacy checkbox is not checked
    }

    // header supports
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    await axios
      .post(`${apiUrl}/user/login`, data, config)
      .then(async (res) => {
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        const headers = {
          Authorization: `Bearer ${res.data?.token}`,
        };
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        await axios
          .get(`${apiUrl}/chatbot`, { headers })
          .then((res) => {
            if (res.data?.length > 0) {
              localStorage.setItem('isHaveBot', JSON.stringify(true));
            } else {
              localStorage.setItem('isHaveBot', JSON.stringify(false));
            }
            setLoading(false);
            toggleLoggedInState();
          })
          .catch((err) => {
            console.log('chatbot list fetching fail:', err);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error); // this is the main part. Use the response property from the error object
        setErrorMessage(error.response?.data && 'Email or password is wrong!');
      });
  };

  const handleInputFocus = () => {
    // Clear the error message when the input field is focused
    setErrorMessage('');
  };

  console.log('error message', errorMessage);

  return (
    <>
      <Helmet>
        <title>Sign In | Aceva GPT</title>
      </Helmet>

      {/* <HeaderMenu /> */}
      <section className="!bg-gray-50 py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7 bg-white shadow-md rounded-lg overflow-hidden">
            {/* wrapper */}
            <div className="register-wrapper">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-header-wrap">
                  <div className="form-title">
                    <h2>Sign In</h2>
                    <p>Please fill the fields to login.</p>
                  </div>

                  <div className="single-logo-wrapper">
                    <Link to="/">
                      <div className="single-logo">
                        <img src={Logo} alt="logo" />
                      </div>
                    </Link>
                  </div>
                </div>

                {errorMessage && (
                  <p className="error-text error-box">{errorMessage}</p>
                )}

                {/* email */}
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    onFocus={handleInputFocus}
                    className={errors.email?.message && 'error-input'}
                    {...register('email', { required: 'Email is required!' })}
                    type="email"
                    placeholder="Email address"
                    id="email"
                  />
                  <ErrorText error={errors.email?.message} />
                </div>

                <div className="form-row">
                  {/* Password */}
                  <div className="form-group">
                    <label htmlFor="Password" className="flex items-center justify-start gap-2">
                      <span>Password</span>
                      <span className="tooltip !mt-0">
                        <AiOutlineExclamationCircle className="excl-icon" />
                        <span className="tooltip-text">
                          Password must be at least 8 characters long and it must
                          contain at least one uppercase letter, one lowercase
                          letter, one number, and one special character.
                        </span>
                      </span>
                    </label>
                    <div className="form-group-input-icon">
                      <input
                        onFocus={handleInputFocus}
                        className={errors.password?.message && 'error-input'}
                        {...register('password', {
                          required: 'Password is required!',
                        })}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="********"
                        id="Password"
                      />
                      <span onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                      </span>
                      <ErrorText error={errors.password?.message} />
                    </div>
                  </div>
                </div>

                <div className="already-have-account">
                  <p>
                    Don't have an account?{' '}
                    <span className="link">
                      <Link to="/register">Register Here</Link>
                    </span>
                  </p>

                  <p className="forgot-password-label">
                    <span className="link">
                      <Link to="/forgot-password">Forget Password?</Link>
                    </span>
                  </p>
                </div>


                <div className="privacy-check">
                  <Checkbox 
                    className={`privacy-checkbox ${privacyRequired ? 'active' : ''}`} 
                    onChange={onChange}
                  >
                    Kindly review Aceva GPT’s   
                    <Link to="/privacy-policy"> Privacy Policy</Link> and 
                    <Link to="/terms-and-conditions"> Terms of Service</Link> before using this app.
                  </Checkbox>
                </div>

                <button type="submit" className="form-button full-width">
                  {loading ? (
                    <>
                      Loading <MiniLoading />
                    </>
                  ) : (
                    'Login'
                  )}
                </button>
                <p
                  style={{
                    fontSize: '11px',
                    color: 'grey',
                    textAlign: 'center',
                    margin: '30px 0 15px 0',
                  }}
                >
                  --------------- OR ---------------
                </p>
                <div onClick={ () => {
                  if (!privacyCheck) {
                    toast.error('Please Check the Privacy Policy.')
                    return; // Prevent login if privacy checkbox is not checked
                  }
                  googleLogin()
                }} className="login-with-google-icon">
                  <img src={GoogleIcon} alt="google_icon" />
                  <span>Login with google</span>
                </div>
                {/* <div style={{display:'flex', justifyContent:'center', width:'100%', marginBottom:'30px'}}>
                  <GoogleLogin
                      onSuccess={handleGoogleLogin}
                      onError={handleGoogleLogin}
                  />
                </div> */}
              </form>
            </div>

            {/* thumb */}
            <div className="register-thumb">
              <img src={loginThumb} alt="login thumb" />
            </div>
          </div>
        </div>
      </section>
      <FooterV2 />
    </>
  );
}

export default LoginScreen;
