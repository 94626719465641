import { MessageSquare } from "lucide-react";
import React from 'react';
import { Link } from "react-router-dom";
import video from '../../videos/new.mp4';

function LeftSideFlexSection({ t }) {
  return (
    <>
      <section className="py-32">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 sm:gap-32 items-center">
            <div className="flex justify-center items-start gap-0 rotate-[270deg]">
              {/* Video added here */}
              <video width="100%" height="100%" className="max-w-full sm:max-w-[65%] rounded-xl overflow-hidden" autoPlay loop muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="left-side-content flex flex-col !gap-4 text-left !items-start justify-start">
              <p className="mt-2 text-base text-brand-color font-semibold pb-3">
                {t?.leftSideFlexSection?.badge || 'Lead generation'}
              </p>
              <h2 className="text-4xl md:text-5xl font-bold mb-4 !leading-[50px] sm:leading-[80px] max-w-5xl text-left mx-auto text-black">
                {t?.leftSideFlexSection?.title || 'Transform your website into an intelligent sales agent'}
              </h2>
              <p className="text-base text-black">
                {t?.leftSideFlexSection?.subTitle || "See our chatbot in action"}
              </p>
              <div className="mt-4text-center">
                <Link
                  to="/register"
                  className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-brand-color rounded-full hover:bg-brand-color-bold transition-colors duration-300 hover:text-white"
                >
                  <MessageSquare className="text-white mr-2" />
                  <span>{t?.leftSideFlexSection?.button || 'Create New Chatbot'}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LeftSideFlexSection;
