import { Trash2 } from 'lucide-react'
import { useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Layout from '../Layout'
import Breadcrumb from '../globals/Breadcrumb'
import BusinessGoalTips from './BusinessGoalTips'

export default function BusinessGoalScreen({toggleLoggedInState}) {
  const [businessName, setBusinessName] = useState('ACEVA GPT')
  const [editBusinessName, setEditBusinessName] = useState(false)
  const [goals, setGoals] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const inputRefs = useRef({}) // Store refs for each input field

  const goalOptions = [
    {
      value: 'demo',
      label: 'Schedule a Demo Meeting',
      description: 'If the customer requests a product demo, send out demo link: https://calendly.com',
    },
    {
      value: 'video',
      label: 'Watch a Demo Video',
      description: 'When the customer is exploring the services/products, send out the link to demo video page',
    },
    {
      value: 'offer',
      label: 'Give a Special Offer',
      description: 'When the customer is exploring the services/products, send out the offer for this service',
    },
    {
      value: 'page',
      label: 'Direct to a Page',
      description: 'If the customer is looking for detailed information about services or products, include the relevant page link.',
    },
    {
      value: 'form',
      label: 'Ask to Fill Out a Form',
      description: 'At the end of the chat, ask the customers to fill out the form, or click here to get started!',
    },
    {
      value: 'other',
      description: 'Other...',
      label: 'Other',
    },
  ];
  

  const addGoal = (goal) => {
    if (goals?.length < 3) {
      const newGoal = { id: Date.now(), text: goal.description, label: goal.label }
      setGoals([...goals, newGoal])

      // Set focus on the newly added input field
      setTimeout(() => {
        if (inputRefs.current[newGoal.id]) {
          inputRefs.current[newGoal.id].focus();
        }
      }, 100)
    } else {
      toast.error('Free users can only create 3 CTAs')
    }
  }

  const removeGoal = (id) => {
    setGoals(goals.filter(goal => goal.id !== id))
  }

  return (
    <Layout toggleLoggedInState={toggleLoggedInState}>
        <Breadcrumb title="Setup Business Goal" />
      <div className="flex flex-col sm:flex-row gap-5 sm:justify-between border border-gray-200 rounded my-10 mt-5">
        <div className="max-w-4xl p-6 bg-white border-r border-gray-200">
          <Toaster />
          <h2 className="text-2xl font-semibold mb-6 flex justify-start gap-2">
           <span>Welcome to ACEVA GPT</span></h2>

          <p className="mb-6 text-gray-600">
            🚀 At <span className="font-semiBold">ACEVA GPT</span>, we empower you to transform your unique business vision into reality effortlessly by simplifying the complex process of creating custom AI chatbots that deliver real value to your customers.
          </p>
          <p className="font-semibold text-lg mb-4 text-gray-700">Let's Get Started!</p>
          <p className="font-semibold text-gray-700 mb-2">What's your business goal for creating this chatbot?</p>
          <p className="mb-6 text-gray-600">
            <span className="font-semibold">💡 Tip:</span> When adding your business goal, be specific and include a clear call-to-action (CTA) such as instructions or link. This will help us design a more effective chatbot. For example and tips, refer to the guide below.
          </p>

          <div className="flex items-center justify-start mb-4 gap-3">
            <Select
              options={goalOptions}
              placeholder="Add your business goal here"
              onChange={(selectedOption) => addGoal(selectedOption)}
              className="clear-default-format-selectbox"
            />
          </div>

          {goals.map((goal) => (
            <div key={goal.id} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                ref={el => inputRefs.current[goal.id] = el} // Assign ref to each input
                placeholder={goal.text}
                onChange={(e) => {
                  const updatedGoals = goals.map(g =>
                    g.id === goal.id ? { ...g, text: e.target.value } : g
                  )
                  setGoals(updatedGoals)
                }}
                className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-0 focus:ring-1 focus:ring-brand-color focus:border-brand-color"
              />
              <button
                onClick={() => removeGoal(goal.id)}
                className="p-2 text-red-500 hover:text-red-700 focus:outline-none"
              >
                <Trash2 className="w-5 h-5 text-red-500 hover:text-red-700" />
              </button>
            </div>
          ))}

          <Link to="/chatbot-type">
            <button
              className="mt-6 px-6 py-3 bg-brand-color text-white hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-brand-color focus:ring-offset-2 m-auto font-semibold rounded-[10px] w-full"
              onClick={() => {
                const config = {...JSON.parse(localStorage.getItem('botConfig')), businessGoal: goals}
                localStorage.setItem('botConfig', JSON.stringify(config))
              }}
            >
              🚀 Let's Create Your Chatbot!
            </button>
          </Link>
        </div>
        <BusinessGoalTips />
      </div>
    </Layout>
  )
}
