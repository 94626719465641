'use client';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PostSkeleton = () => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="h-48 sm:h-56 md:h-64 bg-gray-200 animate-pulse" />
    <div className="p-4 sm:p-6 space-y-4">
      <div className="w-3/4 h-6 bg-gray-200 animate-pulse rounded" />
      <div className="space-y-2">
        <div className="w-full h-4 bg-gray-200 animate-pulse rounded" />
        <div className="w-full h-4 bg-gray-200 animate-pulse rounded" />
      </div>
      <div className="flex justify-between">
        <div className="w-24 h-6 bg-gray-200 animate-pulse rounded-full" />
        <div className="w-32 h-4 bg-gray-200 animate-pulse rounded" />
      </div>
    </div>
  </div>
);

const BlogPostCard = ({ post }) => (
  <article className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="relative h-48 sm:h-56 md:h-64 overflow-hidden">
      <img 
        src={post.featuredImage || '/images/support/calendar.png'}
        alt={post.title}
        className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
        loading="lazy"
      />
    </div>
    <div className="p-4 sm:p-6">
      {/* {post.category?.slug && (
        <Link 
          to={`/category/${post.category.slug}`}
          className="inline-block px-3 py-1 mb-4 text-sm font-semibold text-brand-color bg-green-50 rounded-full hover:bg-green-100 transition-colors"
        >
          {post.category.name}
        </Link>
      )} */}
      <h3 className="mb-2 sm:mb-4">
        <Link 
          to={`/blog/${post.slug}`}
          className="text-lg sm:text-xl font-bold text-gray-900 hover:text-brand-color transition-colors duration-200 line-clamp-2"
        >
          {post.title}
        </Link>
      </h3>
      {/* <div 
        className="mb-4 text-gray-600 text-sm sm:text-base line-clamp-3 prose prose-sm"
        dangerouslySetInnerHTML={{__html: post.content?.substring(0, 150) + '...'}}
      /> */}
      {post.content?.replace(/<[^>]*>/g, '').substring(0, 150) + '...'}
      {/* <div className="flex flex-wrap items-center gap-3 text-sm">
        {post.author?.slug && (
          <Link 
            to={`/author/${post.author.slug}`}
            className="font-medium text-brand-color hover:text-brand-color-bold transition-colors"
          >
            {post.author.name}
          </Link>
        )}
        {post.author?.name && <div className="w-1.5 h-1.5 rounded-full bg-gray-300" />}
        <span className="text-gray-500 capitalize">
          {new Date(post.createdAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          }).toLowerCase()}
        </span>
      </div> */}
    </div>
  </article>
);

BlogPostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    content: PropTypes.string,
    featuredImage: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    category: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  }).isRequired
};

function RelatedPostSection() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://gptsaas.aceva.com.my/api/blogs');
        setPosts((response.data?.data || []).slice(0, 6)); // Only take first 6 posts
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load articles');
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <section className="py-8 sm:py-12">
        <div className="container">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 sm:mb-8">Latest Articles</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {[...Array(6)].map((_, index) => (
              <PostSkeleton key={index} />
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-8 sm:py-12">
        <div className="container">
          <p className="text-red-500">{error}</p>
        </div>
      </section>
    );
  }

  if (!posts.length) {
    return null;
  }

  return (
    <section className="pb-2 sm:pb-8 py-8 sm:py-12 bg-gray-50">
      <div className="container">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 sm:mb-8">Latest Articles</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {posts.map((post) => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default RelatedPostSection;