import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiUrl } from '../../Variables';
import Layout from '../../components/Layout';
import Loading from '../../components/MiniLoading';
import GCConnect from '../../components/marketplace/GCConnect';
import GoogleCalendarBot from '../../components/marketplace/GoogleCalendarBot';
import MarketplaceBreadcrumb from '../../components/marketplace/MarketplaceBreadcrumb';
import GoogleCalendarLogo from '../../images/logos/calendar.webp';
import LoginWithGoogleCalendar from '../dashboard/LoginWithGoogleCalendar';
import toast from 'react-hot-toast';
import { FiDelete } from 'react-icons/fi';
import { BiTrash } from 'react-icons/bi';

function GCDetailScreen({toggleLoggedInState}) {

  const [googleEvents, setGoogleEvents] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)
  const googleAccessToken = localStorage.getItem('googleAccessToken')
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')))
  const [chatbots, setChatbots] = useState(null);
  const [chatbotFetchLoading, setChatbotFetchLoading] = useState(false)
  const [calendars, setCalendars] = useState(null);

  
  // fetch chatbot list
  useEffect(() => {
    const fetchChatbots = async () => {
      try {
        // Retrieve userInfo from localStorage
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (!userInfo || !userInfo.token) {
          console.error('User token not found.');
          return;
        }

        // Add headers to include JWT token
        const headers = {
          Authorization: `Bearer ${userInfo.token}`,
        };

        setChatbotFetchLoading(true);

        const res = await axios.get(`${apiUrl}/chatbot`, { headers });
        setChatbots(res.data);

        console.log(res);

        try {
          const gcResponse = await axios.post(`${apiUrl}/gCalender/getCalendars`, {googleRefreshToken: userInfo?.utilityData?.grt});
          // console.log(gcResponse)
          setCalendars(gcResponse.data.calendars)
        } catch (error) {
          console.error('Error fetching Google events:', error);
        }
        
        setChatbotFetchLoading(false);
      } catch (error) {
        setChatbotFetchLoading(false);
        console.log('Chats fetch error:', error);
      }
    };
    fetchChatbots();
  }, [refresh]);

  
  // convert google calendar default to readable
  const convertGoogleEventsToBigCalendarFormat = (googleEvents) => {
    return googleEvents.map((event) => ({
      id: event.id,
      title: event.summary,
      start: new Date(event.start.dateTime),
      end: new Date(event.end.dateTime),
      allDay: false,
      description: event.description,
      location: event.location,
      link: event.htmlLink,
      // You can include additional properties if needed
    }));
  };

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      try {
        const response = await axios.post(`${apiUrl}/gCalender/events`, {googleRefreshToken: userInfo?.utilityData?.grt});
        const convertedEvents = convertGoogleEventsToBigCalendarFormat(
          response.data.events
        );
        setGoogleEvents(convertedEvents);
      } catch (error) {
        console.error('Error fetching Google events:', error);
      }
    };
    fetchGoogleEvents();
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
  }, [refresh]);

  console.log('googleEvents', googleEvents)

  const logoutGCcalendar = async () => {
    userInfo.utilityData = {...userInfo.utilityData, grt: null}
    const updatedUser = {...userInfo}
    localStorage.setItem('userInfo', JSON.stringify(updatedUser))
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
    const {data} = await axios.put(`${apiUrl}/user/${userInfo?._id}`, updatedUser, {headers})
    toggleRefresh()
  }
  
  const handleDeleteCalender = async (selectedBot) => {
    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };
   setLoading(true)
   await axios.get(`${apiUrl}/botConfig/${selectedBot}`, {headers}).then(async res => {
     const btc = res.data[0]
     await axios.put(`${apiUrl}/botConfig/${btc?._id}`, {_id: btc?._id, configData: {...btc.configData, gcCalendar: null}, chatbotId: btc.chatbotId}, { headers }).then(async res => {
       setLoading(false)
       setRefresh(!refresh)
       toast.success('Successfully deleted.')
     }).catch((error) => {
       setLoading(false)
       console.log(error)
     })
   }).catch((error) => {
     setLoading(false)
     console.log('scrap list fetching fail:', error)
   })
 }

  console.log('chatbots', chatbots)

  return (
    <>
      <Helmet>
        <title>Google Calendar Integration | ACEVA Dashboard</title>
      </Helmet>

      <Layout toggleLoggedInState={toggleLoggedInState}>
        {/* <GoogleCalendarBot /> */}
        
        <div className="marketplace-container">
          <MarketplaceBreadcrumb title="Google Calendar Integration" />
          <div className="marketplace-title marketplace-details-title">
            <h2>Google Calendar Integration</h2>
          </div>

          {/* marketplace items */}
          <div className="marketplace-item marketplace-details-item">
            <div className="marketplace-item-thumb">
              <img src={GoogleCalendarLogo} alt="calendly icon" />
            </div>
            <p>Please access your Google Calendar settings and proceed to the 'Integrations' section. Then, select 'Add Calendar' and opt for 'From URL.' Insert your unique Google Calendar URL into the designated field. Once saved, your Google Calendar will be successfully linked with the chatbot, enabling seamless scheduling and enhanced organization.</p>
            <div className="">
            {/* <Link to="https://calendly.com/">
              <button className="btn marketplace-btn">
                Sign in calendly
              </button>
            </Link> */}
            {/* <CalendlyApiResponse /> */}
            
            {/* <button className="btn marketplace-btn">
              Login with Google Calendar
            </button> */}
            <div className="gc-btn-wrap">
              {
                userInfo?.utilityData?.grt ? (
                  <button className="btn marketplace-btn disabled">
                    Connected with google calendar
                  </button>
                ) : (
                  <LoginWithGoogleCalendar toggleRefresh={toggleRefresh}/>
                )
              }
              {userInfo?.utilityData?.grt && <button className="form-button disconnect-btn" onClick={logoutGCcalendar}>Disconnect</button>}
            </div>
            </div>
            {/* connect */}
            {
              userInfo?.utilityData?.grt && <GCConnect /> 
            }
          </div>
          {
             userInfo?.utilityData?.grt && <div className="addon-billing-table-wrap marketplace-details-item connected-gc-chatbots">
                  <h3>Connected Calendars</h3>
                  <table>
                    { chatbotFetchLoading && <Loading /> }
                    <thead>
                      <tr>
                        <th>Calendar</th>
                        <th>Chatbot</th>
                        <th></th>
                      </tr>
                    </thead>
                      <tbody>
                        {
                          chatbots?.map((item, index) =>  (
                            // Check if both gcCalendar and botName exist in configData
                            item?.configData?.gcCalendar && item?.configData?.botName && (
                              <tr key={index}>
                                <td>{calendars?.find(cal => cal.id === item.configData.gcCalendar)?.summary}</td>
                                <td>{item.configData.botName}</td>
                                <td><BiTrash style={{cursor:'pointer'}} onClick={() => handleDeleteCalender(item._id)} color='red'/></td>
                              </tr>
                            )
                          ))
                        }
                      </tbody>
    
                  </table>
                </div>
          }
        </div>

      </Layout>
    </>
  );
}

export default GCDetailScreen;
