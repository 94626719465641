import React from 'react';
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import 'react-loading-skeleton/dist/skeleton.css';

function PricingCardSkeleton() {
  const skeletonItems = [1, 2, 3]; // Array to loop through

  return (
    <>
      <div className="pricing-wrapper">
        {skeletonItems.map((item, index) => (
          <div className="pricing-item" key={index}>
            <div className="pricing-title">
              <Skeleton width={300} height={20} />
              <Skeleton width={200} height={10} />
            </div>
            <ul className="pricing-lists">
              <Skeleton count={10} width={300} height={10} />
            </ul>
            <div className="pricing-action">
              <div className="pricing-price">
                <span className="price-amount-badge">
                  <Skeleton width={200} height={20} />
                </span>
              </div>
              <Skeleton width={100} height={20} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PricingCardSkeleton;
