import { Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiUrl } from '../Variables';
import ErrorText from '../components/ErrorText';
import { useAppContext } from '../context/AppContext';
import Logo from '../images/aceva.png';

function ResetPasswordScreen() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const {register, handleSubmit, formState: {errors}} = useForm();
  const {userInfo} = useAppContext();

  const {id, token} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestPass = async () => {
      if (!id || !token) return;
      
      try {
        const {data} = await axios.get(`${apiUrl}/user/reset_password/${id}/${token}`);
        setUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        Modal.error({
          title: 'Error',
          content: 'Invalid or expired reset link',
          onOk: () => navigate('/login')
        });
      }
    };
    fetchRestPass();
  }, [id, token, navigate]);

  const onSubmit = async (data) => {
    const {password, confirmPassword} = data;
    
    if(password !== confirmPassword) {
      return Modal.error({
        title: 'Error',
        content: "Passwords don't match!"
      });
    }

    try {
      setLoading(true);
      const res = await axios.post(
        `${apiUrl}/user/reset_password/${id}/${token}`,
        { password },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if(res.data) {
        Modal.success({
          title: 'Success',
          content: 'Your password has been successfully changed!',
          onOk: () => navigate('/login')
        });
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      Modal.error({
        title: 'Error',
        content: error?.response?.data?.message || "Failed to reset password"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[100vh] bg-gray-50 flex flex-col items-center justify-center py-10 sm:px-6 lg:px-8 rounded-md">
      <div className="mb-4">
        <Link to="/" className="flex justify-center">
          <div className="flex items-center flex-col gap-4">
            <img src={Logo} alt="logo" className="w-16 h-16 object-contain" />
            {/* <div className="mt-2">
              <h2 className="text-xl font-semibold text-gray-800">ACEVA</h2>
            </div> */}
          </div>
        </Link>
      </div>

      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="text-center">
              <h2 className="text-xl font-semibold">Reset password for <span className="text-brand-color">{user.email}</span></h2>
              <p className="mt-2 text-sm text-gray-600">Set your new password. Keep in mind password and confirm must be same.</p>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  {...register('password', {
                    required: "Password is required!",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long."
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
                    }
                  })}
                  type="password"
                  placeholder="Password"
                  id="password"
                  className={`appearance-none block w-full px-3 py-2.5 border ${errors.password?.message ? 'border-red-300' : 'border-gray-300'} rounded-md placeholder-gray-400 focus:outline-none focus:ring-brand-color focus:border-brand-color sm:text-sm`}
                />
                <ErrorText error={errors.password?.message} />
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  {...register('confirmPassword', {
                    required: "Confirm password is required!"
                  })}
                  type="password"
                  placeholder="Confirm password"
                  id="confirmPassword"
                  className={`appearance-none block w-full px-3 py-2.5 border ${errors.confirmPassword?.message ? 'border-red-300' : 'border-gray-300'} rounded-md placeholder-gray-400 focus:outline-none focus:ring-brand-color focus:border-brand-color sm:text-sm`}
                />
                <ErrorText error={errors.confirmPassword?.message} />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-3 px-4 border border-transparent !rounded-md text-sm font-medium text-white bg-brand-color hover:bg-brand-color-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-color disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
            >
              {loading ? 'Processing...' : 'Reset Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;