import React, { useEffect, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTelegramPlane, FaTimes } from 'react-icons/fa'
import textLogo from '../images/aceva.png'

// product images

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ConfigBotBody from './botConfig/ConfigBotBody'
const MySwal = withReactContent(Swal)


function ConfigBot({config, setConfig, promptLists, mask, isFinal}) {
  const [toggle, setToggle] = useState(false); 

  const [ip, setIp] = useState(localStorage.getItem('ip'))
  const [error, setError] = useState('')
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [chat, setChat] = useState([])
  const [message, setMessage] = useState('')
  const [selectedPrompt, setSelectedPrompt] = useState('')

  const handleToggle = (e) => {
    setToggle(!toggle);
  }

  useEffect(() => {
    console.log('Config has changed!', config);
    // Additional logic related to 'config' can be placed here
  }, [config]);
  
  return (
    <>
      <section className='chatbot-section'>
      <div className="chatbot-config-widget-wrapper">

          {/* chatbot widget wrapper */}
          <div className="chatbot-widget active config-bot-widget">
            
            {/* chat header */}
            <div className="chatbot-widget-header bot-config-header" style={{background: config?.brandColor}}>

              {/* agent presence */}
              <div className="chatbot-widget-agent-precence">

                {/* agent left side */}
                <div className="chatbot-widget-header-left">
                  {/* agent avatar */}
                  <div className="chatbot-recipient-avatar">
                      <img src={config?.logo ? config?.logo : textLogo} alt="agent avatar" />
                      {/* https://play-lh.googleusercontent.com/ZG6-bDrdxvKV9lwg_aQ0H90VBHdYApCBaOn4VL33fSnKYKigGsgK5xe7E_rCYra1fDag */}
                      <div className="online-status"></div>
                  </div>

                  {/* agent details */}
                  <div className="chatbot-widget-recipient-details">
                    <p>Chat with</p>
                    <h3>{config?.botName || 'ACEVA GPT'}</h3>
                  </div>
                </div>

                {/* agent right side options */}
                <div className="chatbot-widget-header-right">

                  <div className='chatbot-widget-header-right-options'>

                    {/* minimize */}
                    <div className="chatbot-widget-minimize refresh-icon" onClick={async () => {      
                      if (language === null) {
                        setLanguage('en')
                      } else {
                        setLanguage(null)
                      }             
                      }}>
                      <BsThreeDotsVertical />
                    </div>

                    {/* cross icon for mobile */}
                    <div className="chatbot-widget-minimize refresh-icon desktop-hide" onClick={handleToggle}>
                      <FaTimes/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* chat conversation group body */}
            <div className={isFinal ? "chat-conversation-wrapper bot-message-wrapper bot-final-msg-body" : "chat-conversation-wrapper bot-message-wrapper"}>

              {/* language mask */}
              {
                mask && (
                  <div className="language-mask">
                    <div className="language-mask-wrap">
                      <p>Select a language :</p>
                      <li>{config.language[0]?.label}</li>
                      {config.language[1]?.value && <li>{config.language[1]?.label}</li>}
                      {config.language[2]?.value && <li>{config.language[2]?.label}</li>}
                    </div>
                  </div>
                )
              }
              {/* message body */}
              <div className={mask ? "message-body chatbot-message-body mask-blur" : "message-body chatbot-message-body"}>
                <ConfigBotBody textLogo={textLogo} config={config} setConfig={setConfig} selectedPrompt={selectedPrompt}/>
              </div>
            </div>
            {/* promptLists */}
            <div className="chatbot-footer-container">
                {/* footer menu options */}
                <div className='message-auto-suggest-buttons'>
                  {
                    promptLists?.map(i =>  <div onClick={() => {
                      if (/^(ftp|http|https):\/\/[^ "]+$/.test(i.content)) {
                        window.open(i.content, '_blank');
                      } else {
                        setSelectedPrompt(i.content) 
                      }
                    }} className='botdemo-menu-option-item' style={{backgroundColor: config?.brandColor}}>
                        {i.title}
                      </div>)
                  }
                  {
                    config?.calendarPrompt && (
                      <div className='botdemo-menu-option-item' style={{backgroundColor: config?.brandColor}}>
                        {config?.calendarPrompt}
                      </div>
                    )
                  }
                </div>
                {/* chat footer */}
                <div className="chatbot-footer">
                  <div className="chatbot-footer-input-box mini-bot-footer">
                    <form onSubmit={(e) => {e.preventDefault()}}> 
                      <input value={message} type="text" placeholder={config?.placeholder || 'Start conversation...'} />
                      {/* <button className='chatbot-send-message-button chatbot-config-footer-btn chatbot-iframe-send-message-btn'>Prompt button</button> */}
                      <button type='submit' className='chatbot-send-message-button chatbot-config-footer-btn' style={{background: config?.brandColor}}>
                        {loading ? <h6 style={{color:'white', fontSize:'10px'}}>sending...</h6> : <FaTelegramPlane />}
                      </button>
                    </form>
                  </div>
                </div>
            </div>      
          </div>
      </div>
      </section>
    </>
  )
}

export default ConfigBot