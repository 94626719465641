import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import GoogleAnalytics from './components/GoogleAnalytics.js';
import GoogleTagManager from './components/GoogleTagManager.js';
import ScrollToTop from './components/ScrollToTop.js';
import BusinessGoalScreen from './components/create-bot-steps/BusinessGoalScreen.js';
import './index.css'; // Ensure this is at the top of your file
import AppearanceScreen from './screens/AppearanceScreen.js';
import BillingScreen from './screens/BillingScreen.js';
import BotDemoScreen from './screens/BotDemoScreen.js';
import ChatbotTypeScreen from './screens/ChatbotTypeScreen.js';
import ChatLogsScreen from './screens/ChatlogsScreen.js';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import HowIsWorkScreen from './screens/HowIsWorkScreen.js';
import LandingPageScreen from './screens/LandingPageScreen.js';
import LinkScreen from './screens/LinkScreen.js';
import ListOfBotScreen from './screens/ListOfBotScreen.js';
import LiveDemoScreen from './screens/LiveDemoScreen.js';
import LoginScreen from './screens/LoginScreen';
import NewChatlogScreen from './screens/NewChatlogScreen.js';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import PaymentStatusScreen from './screens/PaymentStatusScreen.js';
import PricingScreen from './screens/PricingScreen';
import PromptScreen from './screens/PromptScreen.js';
import RegisterScreen from './screens/RegisterScreen';
import Repayment from './screens/Repayment.js';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SettingsScreen from './screens/SettingsScreen.js';
import StepScreen from './screens/StepScreen.js';
import UpdateAppearanceScreen from './screens/UpdateAppearanceScreen.js';
import UpdateChatbotScreen from './screens/UpdateChatbotScreen.js';
import VerifySuccessScreen from './screens/VerifySuccessScreen.js';
import VerifyUserScreen from './screens/VerifyUserScreen.js';
import AuthorScreen from './screens/blog/AuthorScreen.js';
import BlogDetailScreen from './screens/blog/BlogDetailScreen.js';
import BlogLandingScreen from './screens/blog/BlogLandingScreen.js';
import CategoryScreen from './screens/blog/CategoryScreen.js';
import BotIframe from './screens/botIframe.js';
import BotIframeTest from './screens/botIframeTemp.js';
import Dashboard from './screens/dashboard/Dashboard.js';
import LeadGenerationScreen from './screens/lead-generations/index.js';
import CalendlyDetailsScreen from './screens/marketplace/CalendlyDetailsScreen.js';
import GCDetailScreen from './screens/marketplace/GCDetailScreen.js';
import MarketplaceScreen from './screens/marketplace/MarketplaceScreen.js';
import MessengerDetailScreen from './screens/marketplace/MessengerDetailScreen.js';
import WhatsappDetailScreen from './screens/marketplace/WhatsappDetailScreen.js';
import ContactUsScreen from './screens/pages/ContactUsScreen.js';
import PrivacyPolicyScreen from './screens/pages/PrivacyPolicyScreen.js';
import TermsAndConditionScreen from './screens/pages/TermsAndConditionScreen.js';
import ChatbotConfigScreen from './screens/steps/ChatbotConfigScreen.js';
import CreateChatbotFileScreen from './screens/steps/CreateChatbotFileScreen.js';
import CreateNewLinkScreen from './screens/steps/CreateNewLinkScreen.js';
import CreatePromptScreen from './screens/steps/CreatePromptScreen.js';
import ListOfFileScreen from './screens/steps/ListOfFileScreen.js';
import UpdateBusinessGoalScreen from './screens/steps/UpdateBusinessGoalScreen.js';
import UpdateChatbotFileScreen from './screens/steps/UpdateChatbotFileScreen.js';
import CreateWAChatbotScreen from './screens/whatsapp-screen/CreateWAChatbotScreen.js';
import IntegrationScreen from './screens/whatsapp-screen/IntegrationScreen.js';
import {
  ProtectedRoute,
  PublicRoute,
  checkIsAuthenticate,
} from "./utils/routeAuthenticate.js";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated'))
  );
  const [isHaveBot, setIsHaveBot] = useState(
    JSON.parse(localStorage.getItem('isHaveBot'))
  );
  const [loggedInState, setLoggedInState] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const toggleLoggedInState = () => {
    setLoggedInState(!loggedInState);
  };

  useEffect(() => {
    setIsHaveBot(JSON.parse(localStorage.getItem('isHaveBot')));
    setIsAuthenticated(
      checkIsAuthenticate(JSON.parse(localStorage.getItem('userInfo')))
    );
  }, [loggedInState, isHaveBot]);
  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  }, [loggedInState]);

  return (
    <Router>
      <GoogleTagManager />
      <GoogleAnalytics />
      <ScrollToTop />
      <Routes>
        <Route
          path="/login"
          // element={<LoginScreen />}
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <LoginScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-chatbot-file"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateChatbotFileScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/create-new-link"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateNewLinkScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/edit-chatbot-file/:id"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <UpdateChatbotFileScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/file-list"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfFileScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/link-list"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <LinkScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/chatbot-config"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ChatbotConfigScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/chatbot-prompt"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreatePromptScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/chatbot-list"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ListOfBotScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/setup-business-goal"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <BusinessGoalScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/business-goals"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <UpdateBusinessGoalScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/lead-generations"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <LeadGenerationScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/billing"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <BillingScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/demo"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <BotDemoScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-chatbot-steps"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <StepScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/chatbot-type"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ChatbotTypeScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/whatsapp-integration"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <IntegrationScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/create-whatsapp-chatbot"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CreateWAChatbotScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        {/* <Route
          path="/chatlogs"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <ChatLogsScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        /> */}
        <Route
          path="/appearance"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <AppearanceScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/appearance/:id"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <UpdateAppearanceScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/edit-chatbot/:id"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <UpdateChatbotScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/prompt"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <PromptScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <SettingsScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <RegisterScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <ForgotPasswordScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/verify-user"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <VerifyUserScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/verify-success/:id/:token"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <VerifySuccessScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/:lanCode"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <LandingPageScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <LandingPageScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/how-it-works"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <HowIsWorkScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/live-demo"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <LiveDemoScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/pricing"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              isHaveBot={isHaveBot}
              element={
                <PricingScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/reset-password/:id/:token"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <ResetPasswordScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <TermsAndConditionScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <PrivacyPolicyScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/rbilling/:userId"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <Repayment
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />

        <Route
          path="/contact-us"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <ContactUsScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />

        <Route
          path="/blog"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <BlogLandingScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />

        <Route
          path="/category"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <CategoryScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />

        <Route
          path="/chatlogs"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <NewChatlogScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />

        {/* <Route
          path="/help-center"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <HelpCenterScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        /> */}

        <Route
          path="/author"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <AuthorScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />

        <Route
          path="/blog/:slug"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <BlogDetailScreen toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <Dashboard toggleLoggedInState={toggleLoggedInState} />
              }
            />
          }
        />
        <Route
          path="/marketplace"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <MarketplaceScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/whatsapp-details"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <WhatsappDetailScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/messenger-details"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <MessengerDetailScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/calendly-details"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <CalendlyDetailsScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="/google-calendar-details"
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              element={
                <GCDetailScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <PageNotFoundScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        />   
        {/* <Route
          path="/payment-status/:userId/:planId/:status/:token"
          element={
            <PublicRoute
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
              element={
                <PaymentStatusScreen
                  toggleLoggedInState={toggleLoggedInState}
                />
              }
            />
          }
        /> */}
        {/* <Route
          path="/botIframe/:userId/:botId"
          element={<PublicRoute userInfo={userInfo} isAuthenticated={isAuthenticated} element={<BotIframe />} />}
        /> */}
        <Route path="/payment-status/:userId/:planId/:isYearly/:status/:token" element={<PaymentStatusScreen />} />
        <Route path="/botIframe/:userId/:botId" element={<BotIframe />} />
        <Route
          path="/botIframeTest/:userId/:botId"
          element={<BotIframeTest />}
        />
      </Routes>
    </Router>
  );
}

export default App;
