import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { GoDotFill } from "react-icons/go";
import { MdOutlineCalendarToday, MdSpaceDashboard } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as XLSX from 'xlsx';
import { apiUrl } from '../../Variables';
import Layout from '../../components/Layout';
import BotCopyModel from '../../components/bot-screen/BotCopyModel';


import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


// import BotCopyModel from '../../components/bot-screen/BotCopyModel';
// import { BiSmile } from 'react-icons/bi';
import MyCalendar from './Calendar';
// import KpiDropdown from './KpiDropdown';
import Skeleton from 'react-loading-skeleton';
import DownloadLeadSection from './DownloadLeadSection';
import KPIChartSection from './KPIChartSection';
const moment = require('moment');

const Dashboard = ({ toggleLoggedInState }) => {
  
  const googleAccessToken = localStorage.getItem('googleAccessToken')
  //   const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [kpis, setKpis] = useState([]);
  const [botCount, setBotCount] = useState(null);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo'))
  );
  //   const [ids, setIds] = useState(searchParams.get('ids'));
  //   const [botId, setBotId] = useState(useSearchParams.get('botId'));
  const [chatbots, setChatbots] = useState(null);
  const [selectedBot, setSelectedBot] = useState({ value: null, label: 'All' });
  const [billingData, setBillingData] = useState({ subscriptionBilling: [] });
  const [showResOrSession, setShowResOrSession] = useState('tab1');

  const [googleEvents, setGoogleEvents] = useState([]);

  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
    otherDate: 'today',
  });


  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/chatbot`, { headers });
        setChatbots(res.data);
        setSelectedBot({
          value: {
            id: res.data[0]?._id,
            userId: res.data[0]?.userId, 
            embeddedLink: res.data[0]?.embeddedLink,
            configData: res.data[0]?.configData,
            scrappedOrUpDataId: res.data[0]?.scrappedOrUpDataId
          },
          label: res.data[0]?.name,
        });
        const res1 = await axios.get(`${apiUrl}/chatbot/checkBotLimit`, { headers })
        setBotCount(res1.data)
        // setLoading(false)
      } catch (error) {
        // setLoading(false);
        console.log('Kpis fetching fail:', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
      setLoading(true);
      await axios
        .get(`${apiUrl}/billing/addonBillingList`, { headers })
        .then((res) => {
          console.log('addonBillingList', res);
          setBillingData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log('addonBillingList', err.response);
          setLoading(false);
        });
    };
    featchData();
  }, [navigate]);

  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      // setLoading(true);
      try {
        if (!selectedBot?.value?.id)
        {
          //Dont call kpi api call
        }
        else
        {
          const { data } = await axios.get(
            `${apiUrl}/chats/kpis?bot_id=${selectedBot?.value?.id}&&otherDate=${filterDate?.otherDate}&&startDate=${filterDate?.startDate}&&endDate=${filterDate?.endDate}`,
            { headers }
          );
          setKpis(data);
          console.log(data);
          // setLoading(false)
        }
      } catch (error) {
        // setLoading(false);
        console.log('***********************Kpis fetching fail:*************************\n', error);
        // toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
    };
    if (selectedBot) {
      featchData();
    }
  }, [selectedBot, filterDate]);

  const botOptions =
    chatbots?.map((item, index) => {
      return {
        value: { id: item?._id, userId: item?.userId, embeddedLink: item?.embeddedLink, configData: item?.configData , scrappedOrUpDataId: item?.scrappedOrUpDataId },
        label: item.name,
      };
    }) || [];

  function exportToExcel() {
    const wb = XLSX.utils.book_new();   
    
    const eList= []
    // .format('h:mm A')
    for (const it of kpis.exportData) {
      eList.push({...it, Time: moment(it.Time).format('h:mm A')})
    }
    // Convert each list to a worksheet
    const ws1 = XLSX.utils.json_to_sheet(eList);
    // Add each worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws1, 'Sheet 1');
    // Save the workbook to a file
    XLSX.writeFile(wb, 'exported_kpis.xlsx');
  }

  // convert google calendar default to readable
  const convertGoogleEventsToBigCalendarFormat = (googleEvents) => {
    return googleEvents.map((event) => ({
      id: event.id,
      title: event.summary,
      start: new Date(event.start.dateTime),
      end: new Date(event.end.dateTime),
      allDay: false,
      description: event.description,
      location: event.location,
      link: event.htmlLink,
      // You can include additional properties if needed
    }));
  };

  useEffect(() => {
    const fetchGoogleEvents = async () => {
      try {
        if (!selectedBot?.value?.configData?.gcCalendar)
        {
          console.log("This bot does not have a gcCalendar Value!")
        }
        else
        {
          const response = await axios.post(`${apiUrl}/gCalender/events`, {gcCalendar: selectedBot?.value?.configData?.gcCalendar,googleRefreshToken: userInfo?.utilityData?.grt});

          const convertedEvents = convertGoogleEventsToBigCalendarFormat(
            response.data.events
          );
          setGoogleEvents(convertedEvents);
        }

      } catch (error) {
        setGoogleEvents([]);
        console.error('Error fetching Google events:', error);
      }
    };
    fetchGoogleEvents();
  }, [selectedBot]);

  if (googleEvents.length > 0)
  {
    console.log('googleEvents', googleEvents);
  }
  return (
    <>
      <Helmet>
        <title>Dashboard | ACEVA Dashboard</title>
      </Helmet>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        
        {/* Switch dashboard and calendar */}
        <Tabs className="dashboard-parent-tab-wrapper">

            {/* dashboard common header */}
            <div className="dashboard-common-header">

                {loading ? (
                  ''
                ) : !billingData?.subscriptionBilling[0]?.validityHas ||
                  (parseInt(billingData?.subscriptionBilling[0]?.restChatVolume) <
                    1 &&
                    parseInt(
                      billingData?.addOnBilling?.find(
                        (item) => item.addOn.name === 'Extra-message-credits'
                      )?.addOn.extraMsg
                    ) < 1) ? (
                  <div className="alart-error">
                    <div className="alart-icon">
                      <BsExclamationCircle />
                    </div>
                    {parseInt(
                      billingData?.addOnBilling?.find(
                        (item) => item.addOn.name === 'Extra-message-credits'
                      )?.addOn.extraMsg
                    ) < 1 ? (
                      <div className="alart-content">
                        <h2>Your message balance is empty!</h2>
                        <p>
                          Your message balance has been depleted. To continue using
                          our service, please purchase additional credits.
                        </p>
                      </div>
                    ) : !billingData?.subscriptionBilling[0]?.validityHas ? (
                      <div className="alart-content">
                        <div>
                          <h2>Your {userInfo?.meta_data?.planData?.name} plan has expired!</h2>
                          <p>
                            Your {userInfo?.meta_data?.planData?.name} plan has expired. Subscribe to our subscription plan to continue using our service.
                          </p>
                        </div>
                        <a href="/billing" style={{fontWeight:'bold', textDecoration: "underline"}}>Click here to subscribe</a>
                      </div>
                    ) : parseInt(
                        billingData?.subscriptionBilling[0]?.restChatVolume
                      ) < 1 ? (
                      <div className="alart-content">
                        <h2>Your message balance is empty!</h2>
                        <p>
                          Your message balance has been depleted. To continue using
                          our service, please purchase additional credits.
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {/* phone number update status alert */}
                {!userInfo?.phone && (
                  <div className="alert-content">
                    <p>
                      Please update your phone number to continue using your
                      chatbot.
                    </p>
                    <a href="/settings">Update your phone number</a>
                  </div>
                )}
                
            </div>

            {/* header */}
            <div className="dashboard-new-design-header">
                    
              {/* dashboard tab header */}
              <TabList className="dashboard-parent-tab-header second-edition third-edition">
                <>
                  <Tab className="dashboard-parent-header-tab second-edition third-edition">
                    <MdSpaceDashboard />
                    Dashboard
                  </Tab>
                  {
                    userInfo?.utilityData?.grt && selectedBot?.value?.configData?.gcCalendar && <Tab className="dashboard-parent-header-tab second-edition third-edition">
                      <MdOutlineCalendarToday />
                      Calendar
                    </Tab>
                  }
              
                </>
              </TabList>

              {/* right - bot select and create new chatbot button */}
              <div className="dashboard-header-group"> 
                <div className="dashboard all-bot-list">
                  {
                    selectedBot?.label === 'All' ? (
                    <div className="list-bot-logo no-bot">
                      <svg className='w-10 h-10' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                    </div>
                    ) : selectedBot?.value.configData?.logo ? (
                      <div className="list-bot-logo">
                        <img src={selectedBot?.value.configData?.logo} alt="bot icon" />
                      </div>
                      ) : (
                      <div className="list-bot-logo no-bot">
                          <svg className='w-10 h-10 p-[6px]'  stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                      </div>
                      )
                  }
                  <Select
                    className='bot-dropdown'
                    onChange={selected => setSelectedBot(selected)}
                    value={selectedBot}
                    options={[{ value: null, label: 'All' }, ...botOptions]}
                    placeholder="Select a bot..."
                  />
                </div>
                
                {
                  loading ? (
                    Array.from({ length: 1 }).map((_, index) => (
                      <div key={index} className="dashboard-header-right" style={{marginTop: '-7px'}}>
                        <Skeleton width={185} height={42} />
                      </div>
                    ))
                  ) : (
                    <div className="dashboard-header-right">
                      {/* <Link to="create-chatbot-file"> */}
                      <div
                        className={
                          chatbots?.length >= botCount?.totalBotLimit
                            ? 'download-btn-wrap disabled'
                            : 'download-btn-wrap'
                        }
                        onClick={() => {
                          if (
                            kpis?.no_of_bot >= botCount?.totalBotLimit
                          ) {
                            // setBotUpgrade(true)
                            toast.error(
                              `You are currently limited to only ${botCount?.totalBotLimit} bot. Upgrade your plan now for more bots!`
                            );
                          } else {
                            // Cleaning up local storage after successful updates
                              localStorage.removeItem("botConfig");
                              localStorage.removeItem("botInfo");
                              localStorage.removeItem("menuList");
                              localStorage.removeItem("selectedLinks");
                            navigate('/chatbot-type');
                          }
                        }}
                      >
                        <FiPlus />
                        {/* <BsAndroid2 /> */}
                        <span>Create chatbot</span>
                        {/* <FiDownload className="dwn-btn" /> */}
                      </div>
                      {/* </Link> */}
                    </div>
                  )
                }
              </div>

            </div>

          {/* dashboard tab */}
          <TabPanel>
            {/* second version dashboard */}
            <div className="new-dashboard-design-wrapper">
              
              {/* single bot */}
              <div className="list-of-the-bot-item">
                {/* profile */}
                <div className="list-bot-profile">
                  {
                    selectedBot?.label === 'All' ? (
                    <div className="list-bot-logo no-bot">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                    </div>
                    ) : selectedBot?.value.configData?.logo ? (
                      <div className="list-bot-logo">
                        <img src={selectedBot?.value.configData?.logo} alt="bot icon" />
                      </div>
                    ) : (
                      <div className="list-bot-logo no-bot">
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
                      </div>
                      )
                  }
                  <div className="list-bot-content dashboard-bot-content">
                    <Link
                      to={`/file-list?ids=${selectedBot?.value?.scrappedOrUpDataId?.toString()}&botId=${
                        selectedBot?.value?.id
                      }`}
                    >
                      <h3>{selectedBot?.label}</h3>
                      
                    </Link>
                    <div className="bot-content-details">
                      <Link to={`/edit-chatbot/${selectedBot?.value?.id}`}>Edit chatbot</Link>
                      <GoDotFill />
                      <Link to={`/file-list?ids=${selectedBot?.value?.scrappedOrUpDataId?.toString()}&botId=${
                        selectedBot?.value?.id
                      }`}>Library</Link>
                      <GoDotFill />
                      <Link className='inline-flex' to="/" onClick={() => window.open(`https://aceva.com.my/botIframe/${selectedBot?.value?.userId}/${selectedBot?.value?.id}`, '_blank')}>Preview bot <FaExternalLinkAlt /></Link>
                    </div>
                  </div>
                </div>

                {/* right options */}
                <div className="list-bot-option-wrap">
                  {/* iframe copy popup model */}
                  <div className="bot-copy-wrap">
                    <BotCopyModel uid={selectedBot?.value?.userId} bid={selectedBot?.value?.id} customClass="dashboard-bot-item" link={selectedBot?.value?.embeddedLink} link1={selectedBot?.value?.whatsappCallbackLink} type={selectedBot?.value?.configData?.type} fbVarifyToken={selectedBot?.value?.configData?.fbVarifyToken} fbCallbackUrl={selectedBot?.value?.configData?.fbCallbackUrl}/>
                  </div>
                </div>
              </div>

              {/* chart and KPI Section */}
              <KPIChartSection 
                loading={loading}
                kpis={kpis}
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                exportToExcel={exportToExcel}
                selectedBot={selectedBot}
                billingData={billingData}
              />

              {/* download leads section */}
              <DownloadLeadSection selectedBot={selectedBot}/>
              
              {/* documentation help center */}
              {/* <div className="ctr-box">
                  <div className="ctr-content">
                  <AiOutlineQuestionCircle />
                  <Link to="/help-center"><h3>Help Center</h3></Link>
                  <p>Have you hit a roadblock or need guidance? Here's our comprehensive guide to assist you.</p>
                  </div>
              </div> */}

            </div>
          </TabPanel>

          {/* calendar tab */}
          <TabPanel>
            <MyCalendar googleEvents={googleEvents} setGoogleEvents={setGoogleEvents} />
          </TabPanel>
        </Tabs>
      </Layout>
    </>
  );
};

export default Dashboard;
