import { Select, Modal, DatePicker } from 'antd'
import { ChevronDown } from 'lucide-react'
import React, { useState } from 'react'
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import { apiUrl } from '../../Variables'

const DownloadLeadSection = ({selectedBot}) => {
  const [selectedDateRange, setSelectedDateRange] = useState('select-date')
  const [customDateRangeText, setCustomDateRangeText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false)

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  const handleDateRangeChange = (value) => {
    setSelectedDateRange(value);
    if (value === 'custom-date') {
      setIsCustomDateModalVisible(true);
    } else {
      setCustomDateRangeText('');
      switch(value) {
        case 'today':
          setCustomDateRangeText(`${formatDate(moment())} - ${formatDate(moment())}`);
          break;
        case 'yesterday':
          setCustomDateRangeText(`${formatDate(moment().subtract(1, 'days'))} - ${formatDate(moment().subtract(1, 'days'))}`);
          break;
        case 'last-7-days':
          setCustomDateRangeText(`${formatDate(moment().subtract(6, 'days'))} - ${formatDate(moment())}`);
          break;
        case 'last-30-days':
          setCustomDateRangeText(`${formatDate(moment().subtract(29, 'days'))} - ${formatDate(moment())}`);
          break;
        default:
          setCustomDateRangeText('');
      }
    }
  };

  const handleCustomDateSubmit = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      setIsCustomDateModalVisible(false);
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      setCustomDateRangeText(`${formattedStartDate} - ${formattedEndDate}`);
      setSelectedDateRange('select-date');
      console.log('Custom date range:', formattedStartDate, 'to', formattedEndDate);
    } else {
      toast.error('Please select both start and end dates');
    }
  };

  const handleExport = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/leadGenConfig/leadCsv?botId=${selectedBot?.value?.id}&dateRange=${customDateRangeText}`,
        {
          method: 'GET',
        }
      );
      console.log("RESPONSE: ", response)
  
      if (!response.ok) {
        if (response.status == 404)
        {
          alert(`Error: No leadgens have been made today!`);
        }
        else
        {
          const errorData = await response.json();
          alert(`Error: ${errorData.error || response.statusText}`);
        }
        return;

      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // Set the file name
      link.download = `leads_${selectedBot?.label}_${selectedBot?.value?.id}.csv`;
  
      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting CSV:', error);
      alert('No lead found. Please try again.');
    }
  };

  return (
    <>
        <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm mt-6">
          <div className="p-4 sm:p-6 border-b border-gray-200">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
              <h2 className="text-xl font-semibold text-gray-800">Download Leads</h2>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                <Select
                  className='font-poppins !text-sm w-full sm:w-[150px]'
                  value={selectedDateRange}
                  onChange={handleDateRangeChange}
                  suffixIcon={<ChevronDown size={16} className='text-gray-500' />}
                >
                  <Select.Option className="font-poppins !text-sm" value="select-date">Select Date</Select.Option>
                  <Select.Option className="font-poppins !text-sm" value="today">Today</Select.Option>
                  <Select.Option className="font-poppins !text-sm" value="yesterday">Yesterday</Select.Option>
                  <Select.Option className="font-poppins !text-sm" value="last-7-days">Last 7 days</Select.Option>
                  <Select.Option className="font-poppins !text-sm" value="last-30-days">Last 30 days</Select.Option>
                  <Select.Option className="font-poppins !text-sm" value="custom-date">Custom Date Range</Select.Option>
                </Select>
                {customDateRangeText && (
                  <div className="flex flex-wrap items-center gap-2">
                    <span className="text-sm text-gray-500 font-medium">Filtered date: </span>
                    <span className="text-sm font-medium text-brand-color bg-brand-color bg-opacity-10 px-3 py-1 rounded-full">
                      {customDateRangeText}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 sm:p-6">
            <p className="text-gray-600 mb-4">
              📊 Download valuable customer insights in CSV format to capture essential data and contact information. 
              Get your insights here.
            </p>
            <div className="flex justify-start items-center">
              <Link to="/" className="w-full sm:w-auto">
                <button className='w-full sm:w-auto flex justify-center items-center gap-2 bg-brand-color hover:bg-brand-color-bold text-white px-5 py-2 font-medium rounded-lg transition-colors' onClick={handleExport}>
                  <BsFileEarmarkSpreadsheet className="text-white" size={16} />
                  <span>Download Leads</span>
                </button>
              </Link>
            </div>
          </div>
        </div>

      <Modal
        className='font-poppins'
        title={<h2 className="text-xl font-semibold text-gray-800 mb-2">Select Custom Date Range</h2>}
        visible={isCustomDateModalVisible}
        onCancel={() => setIsCustomDateModalVisible(false)}
        footer={null}
        width={400}
      >
        <form onSubmit={handleCustomDateSubmit} className="space-y-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-brand-color focus:border-brand-color"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-brand-color focus:border-brand-color"
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-brand-color hover:bg-brand-color-bold text-white px-4 py-2 rounded-md font-medium transition-colors"
          >
            Submit
          </button>
        </form>
      </Modal>
    </>
  )
}

export default DownloadLeadSection
