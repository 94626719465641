import { Checkbox } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AiOutlineLink } from 'react-icons/ai';
import { BiLinkAlt } from 'react-icons/bi';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../Variables';
import MiniLoading from '../../components/MiniLoading';

function TrainLinks({ selectedItems, setSelectedItems }) {
  const navigate = useNavigate();
  const [webUrl, setWebUrl] = useState('');
  const [webUrlLoading, setWebUrlLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [linkSubmitSuccess, setLinkSubmitSuccess] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  function isValidURL(url) {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?(\?.*)?$/i;
    return urlPattern.test(url);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    if (!isValidURL(webUrl)) {
      setUrlError('Please enter a valid URL');
      return;
    }

    setUrlError('');
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    try {
      setWebUrlLoading(true);
      const { data } = await axios.post(`${apiUrl}/scrap/getUrls`, { web_url: webUrl }, { headers });
      setWebUrlLoading(false);
      setLinks(data);
      console.log('data', data);
      toast.success('Data scrapped successfully!');
    } catch (error) {
      setWebUrlLoading(false);
      console.error('Error fetching URL:', error);
      toast.error(`Failed: ${error?.response?.data?.message}`);
    }
  };

  const handleItemSelection = (itemName) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter(item => item !== itemName));
      localStorage.setItem('selectedLinks', JSON.stringify(selectedItems.filter(item => item !== itemName)))
    } else {
      setSelectedItems([...selectedItems, itemName]);
      localStorage.setItem('selectedLinks', JSON.stringify([...selectedItems, itemName]))
    }
    setSelectAll(selectedItems.length + 1 === links.length);
  };

  // Select all toggle handler
  const selectAllCheckboxHandler = (e) => {
    if (e.target.checked) {
      setSelectedItems(links);
      localStorage.setItem('selectedLinks', JSON.stringify(links))
      setSelectAll(true);
    } else {
      setSelectedItems([]);
      localStorage.setItem('selectedLinks', JSON.stringify([]))
      setSelectAll(false);
    }
  };

  useEffect(() => {
    // Automatically adjust select all state when links or selectedItems change
    setSelectAll(links.length > 0 && selectedItems.length === links.length);
  }, [links, selectedItems]);

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="embadded-code create-chatbot-input-wrap">
          <div className="dashboard-header-left embadded-title">
            <h3 className='title-label'>Website URL</h3>
            <p>Insert your website’s URL here. If you have multiple websites, replace the link and fetch it again.</p>
          </div>
          <div className="embadded-form">
            <input
              type="text"
              placeholder="https://www.example.com/"
              onChange={(e) => {
                setWebUrl(e.target.value);
                setUrlError('');
              }}
              className={`${urlError ? "required" : ""} w-full`}
            />
            {urlError && <span className="error-message">{urlError}</span>}
            <button
              type="submit"
              className={`embadded-btn ${webUrl === "" || urlError ? "disabled" : ""}`}
              disabled={webUrl === "" || urlError}
            >
              {webUrlLoading ? (
                <div className="loading-wrap">
                  <MiniLoading />
                </div>
              ) : (
                <>
                  <AiOutlineLink />
                  <span>Fetch URL</span>
                </>
              )}
            </button>
          </div>
        </div>
      </form>
      <div className="suggest-links">
        {links.length > 0 && (
          <>
            <div className="select-all-links">
            <p className='uploaded-label'>All scrapped links ({links.length}) </p>
              <Checkbox checked={selectAll} onChange={selectAllCheckboxHandler}>Select all</Checkbox>
            </div>
            <div className="uploaded-file-wrap custom-scrollbar">
              {links.map((item, index) => (
                <div key={index} className={`uploaded-file-item ${selectedItems.includes(item) ? 'selected-item' : ''}`}>
                  <div className="uploaded-file-icon-and-name fetched-link-file-icon-wrap">
                    <BiLinkAlt />
                    <span>{item}</span>
                  </div>
                  <div onClick={() => handleItemSelection(item)} className="uploaded-file-action">
                    {selectedItems.includes(item) && <MdCheckBox className="selected-icon" />}
                    {!selectedItems.includes(item) && <MdCheckBoxOutlineBlank />}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TrainLinks;
