import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import marketplace from '../../../images/icons/messenger.png'

function MessengerIntegration() {
  const {disabled, setDisabled} = useState(false);
  return (
    <div className="relative p-6 bg-[#d8dee8] rounded-xl">
      <div className="absolute top-4 right-4 px-3 py-1 text-sm bg-slate-100 rounded-full text-brand-color">Coming Soon</div>
      <div className="w-16 h-16 mx-auto mb-4">
        <img src={marketplace} alt="marketplace icon" className="w-full h-full object-contain" />
      </div>
      <h2 className="text-xl font-semibold text-center mb-4">Messenger Integration</h2>
      <div className="w-full flex justify-center">
      
      <button disabled className="w-fit mx-auto py-2 px-4 bg-gray-500 text-white rounded-lg hover:cursor-not-allowed transition-colors font-medium">
        View More
      </button>

      {/* <Link to="/messenger-details">
            <button className="w-fit mx-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-brand-color-bold transition-colors font-medium">
              View More
            </button>
          </Link> */}
      </div>
    </div>
  )
}

export default MessengerIntegration